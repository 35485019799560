import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalPhotos = ({ show, photos, onHide }) => {
    return (
        <Modal show={show} size={"lg"} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Fotos Registradas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='d-flex justify-content-center'>
                    {photos.map(d => <Col key={d.id} lg={4}><img src={d.full_path} className="img-fluid" /></Col>)}

                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPhotos;