import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createParameter } from '../../Redux/parameterDuck'

const ModalParameter = ({ showModal, onHide, errors, selected, fetching, createParameter }) => {
    const initState = { title: '', description: '', priority: '' };

    const [form, setForm] = useState({ title: '' })

    useEffect(() => {
        console.log("selected", selected)
        console.log("fetching", fetching)

        if (fetching === 3) {
            onHide()
        }


        if (Object.keys(selected).length > 0) {
            setForm(selected);
        }
        else {
            if (fetching !== 3 && fetching !== 2) {
                setForm(initState)
            }
        }
    }, [fetching, selected])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        createParameter(form)
    }


    return (
        <Modal show={showModal} onHide={closeModalTmp}>
            <div className="modal-header border-bottom-custom">
                <h5 className="modal-title text-center">Parametros</h5>
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    {/* <img src={close} className="size-icon-35" /> */}
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Titulo Parametro</label>
                                <InputField
                                    placeholder="Titulo de la Parametro"
                                    value={form.title || ''}
                                    name="title"
                                    onChange={updateInput}
                                    error={errors.title}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Descripción Parametro</label>
                                <InputField
                                    placeholder="Descripcion de la Parametro"
                                    value={form.description || ''}
                                    name="description"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Segmento</label>
                                <InputField
                                    placeholder="Segmento"
                                    value={form.segment || ''}
                                    name="segment"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status_id || false}
                                    name="status_id"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={closeModalTmp}>Cerrar</Button>
                <Button variant="primary" onClick={onSubmit}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    console.log("props", props.parameter)
    return {
        errors: props.parameter.errors,
        selected: props.parameter.selected,
        fetching: props.parameter.fetching
    }
}

export default connect(mapsState, { createParameter })(ModalParameter);