import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getListBrand } from '../../Redux/brandDuck';
import { createBrandModel, getListBrandModel, createBrandModelCylinder, getBrandModelCylinder, createReference, getCylinderReference } from '../../Redux/brandModelDuck';
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';
import moment from 'moment';

const initState = { year: parseInt(moment().format("YYYY")) + 1 }
const initStateModel = { capacity: '', brand_model_id: 0 }
const initStateReference = { name: '', reference: '', type_reference: 'AIRE', price: 0 }

const BrandPage = ({ getListBrand, fetching_list, data, createBrandModel, fetching_create, getListBrandModel, data_brand_model,
    fetching_list_brand_model, createBrandModelCylinder, fetching_create_cylinder, data_cylinder, fetching_list_cylinder,
    createReference, getBrandModelCylinder, fetching_create_reference, getCylinderReference, fetching_list_reference, data_reference }) => {
    const [list, setList] = useState([])
    const [listModel, setListModel] = useState([])
    const [listCylinder, setListCylider] = useState([])
    const [listReferences, setListReferences] = useState([])
    const [selected, setSelected] = useState({ id: '' })
    const [selectedModel, setSelectedModel] = useState({ id: '' })
    const [selectedCylinder, setSelectedCylinder] = useState({ id: '' })
    const [selectedReference, setSelectedReference] = useState({ id: '' })
    const [form, setForm] = useState(initState)
    const [formModel, setFormModel] = useState(initStateModel)
    const [formReference, setFormReference] = useState(initStateReference)
    const [references, setReferences] = useState([])

    const years = [];

    const list_references = [
        {
            text: "AIRE", references: [
                { text: "AIP-997" },
                { text: "AIP-961" },
                { text: "AIP-1040" },
                { text: "AIP-1123" },
                { text: "AIP-960" },
            ],
        },
        {
            text: "COMBUSTIBLE", references: [
                { text: "FLP-302" },
                { text: "FLP-472" },
            ],
        },
        {
            text: "ACEITE EXTRA", references: [
                { text: "OLP-XT-019" },
                { text: "OLP-XT-081" },
                { text: "OLP-XT-077" },
                { text: "OLP-XT-010" }
            ],

        },
        {
            text: "ACEITE", references: [

            ],

        },
        {
            text: "AIRE ACONDICIONADO", references: [
                { text: "ACP-116" },
                { text: "ACP-091" },
                { text: "ACP-153K" },
                { text: "ACP-153" },
                { text: "ACP-123K" },
                { text: "ACP-123" },
                { text: "ACP-014" },

            ],
        }
    ]

    // Generar la lista de años desde 1990 hasta 2020
    for (let year = (parseInt(moment().format("YYYY"))) + 1; year >= 1980; year--) {
        years.push(year);
    }

    useEffect(() => {
        getListBrand()
        setReferences(list_references[0].references)
        // setFormReference({
        //     ...formReference,
        //     ["type_reference"]: list_references[0].id
        // })
    }, [])

    useEffect(() => {
        if (fetching_list_brand_model === 2) {
            setListModel(data_brand_model);
        }
    }, [fetching_list_brand_model])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
            setSelected(data[0])

            setListModel(data[0].brand_models)
            if (data[0].brand_models.length > 0) {
                setSelectedModel(data[0].brand_models[0])
                if (data[0].brand_models[0].cylinders.length > 0) {
                    setListCylider(data[0].brand_models[0].cylinders)
                    setSelectedCylinder(data[0].brand_models[0].cylinders[0])
                    if (data[0].brand_models[0].cylinders[0].references.length) {
                        setListReferences(data[0].brand_models[0].cylinders[0].references)
                    }
                }
            }

        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            setForm({
                ...form,
                ["description"]: ''
            })
            getListBrandModel(selected.id)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list_cylinder === 2) {
            setFormModel(initStateModel)
            setListCylider(data_cylinder)
        }
    }, [fetching_list_cylinder])

    useEffect(() => {
        if (fetching_list_reference === 2) {
            setListReferences(data_reference)
        }
    }, [fetching_list_reference])

    useEffect(() => {

        if (fetching_create_reference === 2) {
            setFormReference(initStateReference)
            getCylinderReference(selectedCylinder.id)
        }
    }, [fetching_create_reference])

    useEffect(() => {
        if (fetching_create_cylinder === 2) {
            getListBrandModel(selected.id)
            getBrandModelCylinder(selectedModel.id)
        }
    }, [fetching_create_cylinder])


    const onSelectBrand = (row) => {
        setSelected(row)
        setListModel(row.brand_models)
        setSelectedModel(initStateModel)
        setSelectedCylinder({ id: '' })
    }

    const onSelectBrandModel = (row) => {
        setSelectedModel(row)
        getBrandModelCylinder(row.id)
        setSelectedCylinder({ id: '' })
    }

    const onSelectCylinder = (row) => {
        setSelectedCylinder(row)
        getCylinderReference(row.id)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.value === 'model') {
            console.log('e.target.value', e.target.value);

        }
    }

    const updateInputModel = (e) => {
        setFormModel({
            ...formModel,
            [e.target.name]: e.target.value
        })
    }

    const updateInputReference = (e) => {
        if (e.target.name === 'type_reference') {
            setReferences(list_references.filter(d => d.text == e.target.value)[0].references)
        }

        setFormReference({
            ...formReference,
            [e.target.name]: e.target.value
        })
    }

    const onSaveModel = () => {
        let _form = form
        _form.brand_id = selected.id
        createBrandModel(_form)
    }
    const onSaveCylinder = () => {
        let _form = formModel
        _form.brand_model_id = selectedModel.id
        createBrandModelCylinder(_form)
    }

    const onSaveReference = () => {
        console.log('formReference', formReference);

        let _form = formReference
        _form.cylinder_id = selectedCylinder.id
        createReference(_form)
    }

    console.log('listReferences', listReferences);



    return (
        <TemplateMenu>
            <Row>
                <Col lg={5}>Listado de Marcas</Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Categoria</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <tr key={row.id}
                                className={`cursor-pointer ${row.id === selected.id ? 'table-primary' : ''}`} onClick={() => onSelectBrand(row)}>
                                <td>{row.id}</td>
                                <td>{row.name} ({row.brand_models.length})</td>
                                <td>{row.category.description}</td>
                            </tr>) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>
                    </table>
                </Col>
                <Col lg={3}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Crear Modelo ({selected.name})</Card.Title>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Modelo</label>
                                                <select className='form-control' name="model" value={form.model} onChange={updateInput}>
                                                    <option value={0}>Seleccione</option>
                                                    {years.map((a, b) => <option key={b} value={a}>{a}</option>)}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col>

                                            <div className="form-group">
                                                <label>Linea</label>
                                                <InputField
                                                    placeholder="Linea"
                                                    value={form.line || ''}
                                                    name="line"
                                                    onChange={updateInput}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col>
                                            <button className='btn btn-sm btn-info' onClick={onSaveModel}>Guardar</button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className="table table-sm table-bordered">
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Modelo</th>
                                        <th>Linea</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(listModel.length > 0) ? listModel.filter(d => parseInt(d.model) === parseInt(form.model)).map(row => <tr key={row.id}
                                        className={`cursor-pointer ${row.id === selectedModel.id ? 'table-primary' : ''}`}
                                        onClick={() => onSelectBrandModel(row)}>
                                        <td>{row.model}</td>
                                        <td>{row.line}</td>
                                    </tr>) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </Col>
                {selectedModel.id !== '' &&
                    <Col lg={3}>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Crear Cilindrajes [Modelo:{selectedModel.model}, Linea{selectedModel.line}]</Card.Title>
                                        <Row>
                                            <Col>

                                                <div className="form-group">
                                                    <label>Capacidad</label>
                                                    <InputField
                                                        type={"number"}
                                                        placeholder="Capacidad"
                                                        value={formModel.capacity || ''}
                                                        name="capacity"
                                                        onChange={updateInputModel}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row>
                                            <Col>
                                                <button className='btn btn-sm btn-info' onClick={onSaveCylinder}>Guardar</button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="table table-sm table-bordered">
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th>Capacidad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listCylinder.length > 0) ? listCylinder.map(row => <tr key={row.id}
                                            className={`cursor-pointer ${row.id === selectedCylinder.id ? 'table-primary' : ''}`}
                                            onClick={() => onSelectCylinder(row)}>
                                            <td>{row.capacity}</td>
                                        </tr>) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                    </Col>}
                {selectedCylinder.id !== '' &&
                    <Col lg={3}>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Crear Referencias [Cilindraje: {selectedCylinder.capacity}]</Card.Title>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Descripción</label>
                                                    <select className='form-control' name="type_reference"
                                                        value={formReference.type_reference}
                                                        onChange={updateInputReference}>
                                                        {list_references.map((a, k) => <option value={a.id} key={k}>{a.text}</option>)}
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col>
                                                {/* <div className="form-group">
                                                    <label>Referencia</label>
                                                    <select className='form-control' name="reference"
                                                        value={formReference.reference}
                                                        onChange={updateInputReference}>
                                                        <option value={0}>Seleccione</option>
                                                        {references.map((a, k) => <option value={a.text} key={k}>{a.text}</option>)}
                                                    </select>
                                                </div>
                                                 */}
                                                <div className="form-group">
                                                    <label>Referencia</label>
                                                    <InputField
                                                        placeholder="Referencia"
                                                        value={formReference.reference || ''}
                                                        name="reference"
                                                        onChange={updateInputReference}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Precio</label>
                                                    <InputField
                                                        placeholder="Precio"
                                                        value={formReference.price}
                                                        name="price"
                                                        onChange={updateInputReference}
                                                    />
                                                </div>
                                            </Col>
                                        </Row> */}
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row>
                                            <Col>
                                                <button className='btn btn-sm btn-info' onClick={onSaveReference}>Guardar</button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="table table-sm table-bordered">
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th>Referencia</th>
                                            <th>Tipo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listReferences.length > 0) ? listReferences.map(row => <tr key={row.id}>
                                            <td>{row.reference}</td>
                                            <td>{row.type_reference}</td>
                                        </tr>) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                    </Col>}

            </Row>
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { brand, brandModel } = props

    return {
        fetching_list: brand.fetching_list,
        data: brand.data,
        fetching_create: brandModel.fetching_create,
        fetching_list_brand_model: brandModel.fetching_list,
        data_brand_model: brandModel.data,
        fetching_create_cylinder: brandModel.fetching_create_cylinder,
        data_cylinder: brandModel.data_cylinder,
        fetching_list_cylinder: brandModel.fetching_list_cylinder,
        fetching_create_reference: brandModel.fetching_create_reference,
        fetching_list_reference: brandModel.fetching_list_reference,
        data_reference: brandModel.data_reference,
    }
}

export default connect(mapsState, {
    getListBrand, createBrandModel, getListBrandModel, createBrandModelCylinder,
    getBrandModelCylinder, createReference, getCylinderReference
})(BrandPage);