import moment from 'moment';
import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalYear = ({ show, onHide, onAddYear, brandModelYear }) => {

    const [form, setForm] = useState({ year: '' })
    const year = moment().format("YYYY");


    const onSubmit = () => {
        let _form = form
        _form.brand_model_id = brandModelYear.id
        onAddYear(_form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }



    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Año a ({brandModelYear.name})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Año</label>
                            <select className='form-control' name='year' onChange={updateInput} value={form.year}>
                                <option value={0}>Seleccione</option>
                                {[...Array(45)].map((d, k) => <option key={k} value={year - k}>{year - k}</option>)}
                            </select>

                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Agregar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalYear;