import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getProductSales } from '../../Redux/productDuck'
import { syncSales } from '../../Redux/orderDuck'
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import moment from 'moment';

const SalesProductsPage = ({ getProductSales, fetching_list, data, syncSales }) => {

    const [list, setList] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [subtotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
            let _quantity = data.reduce((a, b) => a + parseFloat(b.amount), 0)
            let _subtotal = data.reduce((a, b) => a + parseFloat(b.price), 0)
            let _total = data.reduce((a, b) => a + parseFloat(b.subtotal), 0)
            setQuantity(_quantity)
            setSubTotal(_subtotal);
            setTotal(_total)
            console.log('_data', data);

        }
    }, [fetching_list])

    useEffect(() => {
        getProductSales()
    }, [])

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col lg={2}><h3>Venta de Productos</h3></Col>
                <Col lg={2}><button className='btn btn-sm btn-info' onClick={syncSales}>Sincronizar ventas</button></Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered table-condensed table-hover'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha</th>
                                <th>Orden del Día</th>
                                <th>Placa</th>
                                <th>Producto</th>
                                <th className='text-center'>Cantidad</th>
                                <th className='text-center'>Costo Unitario</th>
                                <th className='text-center'>SubTotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>

                                <td>{moment(d.finish_at).format("YYYY-MM-DD")}</td>
                                <td>{d.day}</td>
                                <td>{d.plate}</td>
                                <td>{d.product}</td>
                                <td align='right'>{d.amount}</td>
                                <td align='right'>{formatNumber(d.price)}</td>
                                <td align='right'>{formatNumber(d.subtotal)}</td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}><b>TOTAL</b></td>
                                <td align='right'><b>{quantity}</b></td>
                                <td align='right'><b>{formatNumber(subtotal)}</b></td>
                                <td align='right'><b>{formatNumber(total)}</b></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product } = props;

    return {
        fetching_list: product.fetching_list,
        data: product.data,
    }
}

export default connect(mapsState, { getProductSales, syncSales })(SalesProductsPage);