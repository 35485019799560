import axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    data: [],
    errors: {}
}


const DEFAULT = "DEFAULT"
const DEFAULT_SUCCESS = "DEFAULT_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"
const CREATE_PUBLICITY = "CREATE_PUBLICITY"
const CREATE_PUBLICITY_SUCCESS = "CREATE_PUBLICITY_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_PUBLICITY:
            return { ...state, fetching_create: 1 }
        case CREATE_PUBLICITY_SUCCESS:
            return { ...state, fetching_create: 2 }
        default:
            return state;
    }
}

export let getPublicity = () => dispatch => {
    dispatch({
        type: DEFAULT
    })

    let storage = localStorage.getItem("storage")
    storage = JSON.parse(storage)

    if (storage) {
        dispatch({
            type: DEFAULT_SUCCESS,
            payload: storage
        })
    } else {
        dispatch({
            type: DEFAULT_ERROR,
            payload: storage
        })
    }
}

export let onCreatePublicity = (form) => dispatch => {
    dispatch({
        type: CREATE_PUBLICITY
    })

    axios.post(`${API_URL}/api/publicity`, form, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: CREATE_PUBLICITY_SUCCESS,
        });
    }).catch(err => {
    });


}