import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { SocketContext } from '../../context/SocketContext';
import { getMessagesWhatsApp } from "../../Redux/whatsappDuck";
import { getClientsWhatsapp } from "../../Redux/clientDuck";
import Axios from 'axios';
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import './index.css';
import person_white from '../../Images/person_white.png'

const API_URL = process.env.REACT_APP_API_URL

const WhatsAppPage = ({ getMessagesWhatsApp, fetching_list, data, getClientsWhatsapp, fetching_client, data_clients }) => {
    const { socket, online } = useContext(SocketContext)

    const [list, setList] = useState([]);
    const [listClient, setListClient] = useState([]);
    const [connected, setConnected] = useState(false);
    const [selected, setSelected] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        getMessagesWhatsApp();
        getClientsWhatsapp()
    }, [])

    useEffect(() => {
        if (fetching_client === 2) {
            setListClient(data_clients)
            setSelected(data_clients[0])
        }
    }, [fetching_client])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (socket) {
            socket.on("new-message", row => {
                updateListMessage(row)
            })

            socket.on("message", data => {
                console.log("message--", data)
            })

            socket.on("connection-whatsapp-ok", data => {
                setConnected(true)
            })
        } else {
            console.log("socket no existe")
        }
    }, [socket])

    const updateListMessage = (row) => {
        console.log('list::', list);

        setList([
            ...list,
            row
        ])
    }

    const connectWhatspp = () => {
        console.log("connectWhatspp", "connectando")
        socket.emit("connection-whatsapp")
    }
    const sendMessage = () => {
        socket.emit("list-rooms", { "msg": "prueba desde el front" })
    }

    return (
        <TemplateMenu>
            <Row>
                <Col className='text-center'>
                    <h4>WhatsApp</h4>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    {online ? <span className='text-success'>Conectado</span> : 'Desconectado'}
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={10}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={3}>
                                    <div id="container-contacts">
                                        {listClient.map((row, k) => <Row className={`py-3 border-bottom ${row.id === selected.id ? 'bg-selected' : ''}`} key={k}>
                                            <Col lg={2}>
                                                <div className='circle-user'>
                                                    <img src={`${person_white}`} alt="" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col className='font-14'>{row.name}</Col>
                                                </Row>
                                                <Row>
                                                    <Col className='text-muted font-13'>las messaje</Col>
                                                </Row>
                                            </Col>
                                            <Col lg={3} className='d-flex align-items-center justify-content-center font-11'>
                                                {"12:30 am"}
                                            </Col>
                                        </Row>)}

                                    </div>
                                </Col>
                                <Col>
                                    <div id="container-messages">
                                        <div id="container-chat">
                                            {list.map((row, k) => <Row key={k} className={`${row.type_message === 1 ? 'justify-content-end' : 'justify-content-start'} mt-2`}>
                                                <Col lg={8} className={`${row.type_message === 1 ? 'text-right' : ''}`}>
                                                    <Card className={`${row.type_message === 1 ? 'bg-chat' : ''}`}>
                                                        <Card.Body className='p-1'>
                                                            <Row>
                                                                <Col>{JSON.stringify(row.message, null, 2)}</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className='text-right'>{"2323"}</Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)}
                                        </div>
                                        <div id='container-message'>
                                            <Row>
                                                <Col lg={2}>

                                                </Col>
                                                <Col>
                                                    <input className='input-message form-control' placeholder='Escribe un Mensaje' />
                                                </Col>
                                                <Col lg={1} className='d-flex pl-0'>
                                                    <button className='btn btn-primary' onClick={sendMessage}>Enviar</button>
                                                </Col>
                                            </Row>

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { whatsapp, client } = props;

    return {
        fetching_list: whatsapp.fetching_list,
        data: whatsapp.data,
        fetching_client: client.fetching_client,
        data_clients: client.clients,
    }
}


export default connect(mapsState, { getMessagesWhatsApp, getClientsWhatsapp })(WhatsAppPage);