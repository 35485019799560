import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalOperators = ({ show, onHide, listOperator, onClick }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Operadores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {listOperator.map((d, k) => <Row key={k} className="my-2 border cursor-pointer" onClick={() => onClick(d)}>
                    <Col>{d.name}</Col>
                </Row>)}
            </Modal.Body>
        </Modal>
    )
}

export default ModalOperators;