import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createCategoryProduct } from '../../Redux/categoryProductDuck'


const ModalCategory = ({ show, onHide, errors, fetching_create, createCategoryProduct, onSuccess, formEdit }) => {

    const [form, setForm] = useState({ name: '', priority: 1, })

    useEffect(() => {
        console.log('formEdit', formEdit);

    }, [formEdit])

    useEffect(() => {
        if (fetching_create === 2) {
            onSuccess()
        }
    }, [fetching_create])

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const onSubmit = (e) => {
        let _form = form;
        createCategoryProduct(_form)
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>AGREGAR CATEGORIA</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Nombre</label>
                                <InputField
                                    placeholder="Nombre"
                                    value={form.name || ''}
                                    name="name"
                                    onChange={updateInput}
                                    error={errors.name}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onHide}>Cerrar</button>

                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { category_product } = props
    return {
        errors: category_product.errors,
        fetching_create: category_product.fetching_create
    }
}

export default connect(mapsState, { createCategoryProduct })(ModalCategory);