import React, { Fragment } from 'react'

const SelectField = ({ name, value, label, data, onChange, error, option }) => {

    return (
        <Fragment>
            <div className="form-group">
                <label>{label}</label>
                <select className="form-control" name={name} value={value} onChange={onChange}>
                    <option value="0">Seleccione</option>
                    {data.map((row, i) => <option key={i} value={row.id}>{row[option]}</option>)}
                </select>
                {error !== undefined ? (<label className="error-label">{error}</label>) : null}
            </div>
        </Fragment>
    )
}

export default SelectField