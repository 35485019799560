import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SearchSection from '../Components/SearchSection';
import { getPurchases, onDeletePurchases } from '../../Redux/purchaseDuck';
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import ItemPurchase from './item.purchase';


const PurchasePage = ({ getPurchases, fetching_list, products, onDeletePurchases, fetching_create }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getPurchases()
    }, [])
    useEffect(() => {
        if (fetching_list === 2) {
            setList(products)
        }
    }, [fetching_list])
    useEffect(() => {
        if (fetching_create === 2) {
            getPurchases()
        }
    }, [fetching_create])

    const filterContent = () => {

    }

    const onCleanFilter = () => {

    }

    const onDelete = (item) => {
        if (window.confirm("Realmenre deseas Eliminar esta compra?")) {
            onDeletePurchases(item.id)
        }
    }

    return (<TemplateMenu>
        <Container fluid>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/compras/new" className='btn btn-success btn-sm'>Realizar Compra</Link>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Solicitud</th>
                                <th>Proveedor</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <ItemPurchase row={row} key={row.id} onDelete={onDelete} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>


                    </table>

                    {/* <PaginationTable /> */}
                </Col>
            </Row>
        </Container>
    </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { purchase } = props
    return {
        fetching_list: purchase.fetching_list,
        fetching_create: purchase.fetching_create,
        products: purchase.data,
    }
}

export default connect(mapsState, { getPurchases, onDeletePurchases })(PurchasePage);