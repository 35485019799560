import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';

const ItemTableInventory = ({ row }) => {
    let color = row.type_move_id === 1 ? 'info' : 'warning'

    color = row.is_initial === true ? 'secondary' : color


    return (
        <tr className={`table-${color}`}>
            <td>{moment(row.registration_date).format("YYYY-MM-DD")}</td>
            <td>{row.product}</td>
            <td>{row.measure_unit}</td>
            <td>{row.description} ({formatNumber(row.cost)})</td>
            <td align="right">{formatNumber(row.cost_average)}</td>
            {row.type_move_id === 1 ?
                <>
                    <td align="right">{formatNumber(row.amount)}</td>
                    <td align="right">{formatNumber(row.cost * row.amount)}</td>
                    <td></td>
                    <td></td>
                </> :
                <>
                    <td></td>
                    <td></td>
                    <td align="right">{formatNumber(row.amount)}</td>
                    <td align="right">{formatNumber(row.cost * row.amount)}</td></>}

            <td align="right">{formatNumber(row.amount_total)}</td>
            <td align="right">{formatNumber(row.cost_total)}</td>
        </tr>
    )
}

export default ItemTableInventory;