import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getOperators, getAdministrators } from '../../Redux/userDuck'
import { getTurns } from '../../Redux/turnDuck'
import { getPaymentByTurn } from '../../Redux/reportDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'

import { connect } from 'react-redux';
import ItemReportByTurn from './item.report.by.turn';
import { formatNumber } from '../../utils';


const ReportByTurn = ({ getAdministrators, getTurns, data_turn, data_admin, fetching_admin, fetching_list, getPaymentByTurn, fetching_payment, data_payment,
    getParameterBySegment, fetching_segment, data_segment }) => {

    const [form, setForm] = useState({ administrator_id: 0, turn_id: 0, method_payment_id: 0 })
    const [listAdmin, setListAdmin] = useState([])
    const [listTurn, setListTurn] = useState([])
    const [listMethods, setListMethods] = useState([])
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getAdministrators()
        getTurns()
        getParameterBySegment("payment")
    }, [])

    useEffect(() => {
        if (fetching_payment === 2) {
            setList(data_payment)

            let _total = data_payment.reduce((a, b) => a + parseFloat(b.amount), 0)
            setTotal(_total)

        }
    }, [fetching_payment])

    useEffect(() => {
        if (fetching_segment === 2) {
            setListMethods(data_segment)
        }
    }, [fetching_segment])

    useEffect(() => {
        if (fetching_admin === 2) {
            setListAdmin(data_admin)
        }
    }, [fetching_admin])

    useEffect(() => {
        if (fetching_list === 2) {
            setListTurn(data_turn)
        }
    }, [fetching_list])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSearch = () => {
        getPaymentByTurn(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-4'>
                <Col><h4>Report pagos por Turno</h4></Col>
            </Row>
            <Row>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Turno</label>
                        <select className='form-control' name='turn_id' value={form.turn} onChange={updateField}>
                            <option value={0}>Seleccione</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.date_start} ({d.user.name}) {`-> ${d.status_text}`}</option>)}

                        </select>
                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Administradores</label>
                        <select className='form-control' name='administrator_id' value={form.administrator_id} onChange={updateField}>
                            <option value={0}>Seleccione</option>
                            {listAdmin.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}

                        </select>
                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Métodos de Pago</label>
                        <select className='form-control' name='method_payment_id' value={form.method_payment_id} onChange={updateField}>
                            <option value={0}>Todos</option>
                            {listMethods.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                        </select>
                    </div>
                </Col>
                <Col className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-warning' onClick={onSearch}>Buscar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Orden</th>
                                <th>Usuario</th>
                                <th>Categoria</th>
                                <th>Cliente</th>
                                <th>Placa</th>
                                <th>Método de Pago</th>
                                <th className='text-center'>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length === 0 ? <tr><td colSpan={7}>No Tienes Registros</td></tr> : list.map((d, k) => <ItemReportByTurn item={d} key={k} />)}
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan={6}>TOTAL</th>
                                <th className='text-right'>{formatNumber(total)}</th>
                            </tr>
                        </thead>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapState = (props) => {
    let { turn, user, report, parameter } = props

    return {
        fetching_list: turn.fetching_list,
        data_turn: turn.data,
        fetching_admin: user.fetching_admin,
        data_admin: user.data_administrator,
        fetching_payment: report.fetching_payment,
        data_payment: report.data_payment,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
    }
}

export default connect(mapState, { getAdministrators, getTurns, getPaymentByTurn, getParameterBySegment })(ReportByTurn);