import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import { getCategoryProduct, deleteCategoryProduct } from '../../Redux/categoryProductDuck'
import './index.css'
import TemplateMenu from '../../template/menu.template'
import ItemTableCategoryProduct from './item.table.category.product';
import ModalCategoryProduct from './ModalCategoryProduct';

const CategoryProduct = ({ getCategoryProduct, data, fetching, fetching_list, deleteCategoryProduct, fetching_delete }) => {

    const [modal, setModal] = useState(false);
    const [listCategory, setListCategory] = useState([]);
    const [form, setForm] = useState({});

    useEffect(() => {
        getCategoryProduct()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setListCategory(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_delete === 2) {
            getCategoryProduct()
        }
    }, [fetching_delete])

    const editCategory = (item) => {
        setForm(item)
        setModal(true)
    }

    const deleteCategory = (item) => {
        if (window.confirm("¿Deseas eliminar la categoria?")) {
            deleteCategoryProduct(item)
        }
    }

    const onSuccess = () => {
        setModal(false)
        getCategoryProduct()
    }


    return <TemplateMenu fluid>
        <Row className='d-flex justify-content-center my-4'>
            <Col lg={2} md={4}>
                <h4 className="font-title pt-3">CATEGORIA PRODUCTOS</h4>
            </Col>
            <Col lg={2} md={4} className="d-flex align-items-center">
                <button className='btn btn-info btn-sm' onClick={() => setModal(true)}>Crear</button>
            </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
            <Col lg={6}>

                <Table className="table-sm table-bordered">
                    <thead className='thead-dark'>
                        <tr>
                            <th></th>
                            <th>Nombre</th>
                            <th>Orden</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(listCategory.length) ? listCategory.map((row, key) => <ItemTableCategoryProduct
                            index={key + 1}
                            item={row} key={key} onEdit={() => editCategory(row)} onDelete={deleteCategory}
                        />) : <tr><td colSpan={5}>No Hay Datos</td></tr>}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <ModalCategoryProduct show={modal} onSuccess={onSuccess} size={listCategory.length + 1} formEdit={form} onHide={() => setModal(false)} />

    </TemplateMenu>
}

const mapsState = (props) => {
    let { category_product } = props

    return {
        data: category_product.data,
        fetching: category_product.fetching_delete,
        fetching_list: category_product.fetching_list,
        fetching_delete: category_product.fetching_delete,
    }
}

export default connect(mapsState, { getCategoryProduct, deleteCategoryProduct })(CategoryProduct);