
import { getHeaders, validateInput } from '../utils'
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    data: [],
    errors: {}
}

const LIST_SUPPLIER = "LIST_SUPPLIER"
const LIST_SUPPLIER_SUCCESS = "LIST_SUPPLIER_SUCCESS"
const CREATE_SUPPLIER = "CREATE_SUPPLIER"
const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS"
const CREATE_SUPPLIER_ERROR = "CREATE_SUPPLIER_ERROR"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SUPPLIER:
            return { ...state, fetching_list: 1 }
        case LIST_SUPPLIER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_SUPPLIER:
            return { ...state, fetching_create: 1 }
        case CREATE_SUPPLIER_SUCCESS:
            return { ...state, fetching_create: 2, errors: {} }
        case CREATE_SUPPLIER_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        default:
            return state;
    }
}

export let getListSuppplier = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SUPPLIER
    })


    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/supplier`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_SUPPLIER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateSuppplier = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUPPLIER
    })

    let rules = [
        { field: "business", msg: "El Alias es requerido" },
        { field: "business_name", msg: "La Razon Social es requerida" },
        { field: "document", msg: "El Documento es requerido" },
    ]

    let validation = validateInput(form, rules);
    if (validation != null) {
        dispatch({
            type: CREATE_SUPPLIER_ERROR,
            payload: validation
        })
        return
    }

    if (getState().user.loggedIn) {
        axios.post(`${API_URL}/api/supplier`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_SUPPLIER_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_SUPPLIER_ERROR,
                payload: data.errors
            });

        });
    } else {
        console.log("no Ingreso");
    }
}

export let onUpdateSuppplier = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUPPLIER
    })


    if (getState().user.loggedIn) {
        let rules = [
            { field: "business", msg: "El Alias es requerido" },
            { field: "business_name", msg: "La Razon Social es requerida" },
            { field: "document", msg: "El Documento es requerido" },
        ]

        let validation = validateInput(form, rules);
        if (validation != null) {
            dispatch({
                type: CREATE_SUPPLIER_ERROR,
                payload: validation
            })
            return
        }



        axios.put(`${API_URL}/api/supplier/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_SUPPLIER_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_SUPPLIER_ERROR,
                payload: data.errors
            });

        });
    } else {
        console.log("no Ingreso");
    }
}