
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_fee: 0,
    fetching_plan: 0,
    data: [],
    errors: {}
}


const PARKING = "PARKING"
const PARKING_SUCCESS = "PARKING_SUCCESS"
const PARKING_FEE = "PARKING_FEE"
const PARKING_FEE_SUCCESS = "PARKING_FEE_SUCCESS"
const PARKING_PLAN = "PARKING_PLAN"
const PARKING_PLAN_SUCCESS = "PARKING_PLAN_SUCCESS"



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PARKING:
            return { ...state, fetching_list: 1 }
        case PARKING_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case PARKING_FEE:
            return { ...state, fetching_fee: 1 }
        case PARKING_FEE_SUCCESS:
            return { ...state, fetching_fee: 2 }
        case PARKING_PLAN:
            return { ...state, fetching_fee: 1 }
        case PARKING_PLAN_SUCCESS:
            return { ...state, fetching_fee: 2 }
        default:
            return state;
    }
}

export let getParking = () => dispatch => {
    dispatch({
        type: PARKING
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    fetch(`${API_URL}/api/parking-list?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: PARKING_SUCCESS,
            payload: resp.results
        });

    }).catch(function (error) {
        console.log(error);
    });
}

export let storeFee = (form) => dispatch => {
    dispatch({
        type: PARKING_FEE
    })

    let branch = JSON.parse(localStorage.getItem("branch"))
    form.branch = branch.id


    fetch(`${API_URL}/api/parking-fee`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: PARKING_FEE_SUCCESS,
            payload: resp.results
        });

    }).catch(function (error) {
        console.log(error);
    });
}
export let OnEditFee = (form) => dispatch => {
    dispatch({
        type: PARKING_FEE
    })

    let branch = JSON.parse(localStorage.getItem("branch"))
    form.branch = branch.id


    fetch(`${API_URL}/api/parking-fee/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: PARKING_FEE_SUCCESS,
            payload: resp.results
        });

    }).catch(function (error) {
        console.log(error);
    });
}

export let storePlan = (form) => dispatch => {
    dispatch({
        type: PARKING_PLAN
    })

    let branch = JSON.parse(localStorage.getItem("branch"))
    form.branch = branch.id

    fetch(`${API_URL}/api/parking-plan`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: PARKING_PLAN_SUCCESS,
            payload: resp.results
        });

    }).catch(function (error) {
        console.log(error);
    });
}
export let onEditPlan = (form) => dispatch => {
    dispatch({
        type: PARKING_PLAN
    })

    let branch = JSON.parse(localStorage.getItem("branch"))
    form.branch = branch.id

    fetch(`${API_URL}/api/parking-plan/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: PARKING_PLAN_SUCCESS,
            payload: resp.results
        });

    }).catch(function (error) {
        console.log(error);
    });
}