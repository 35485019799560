import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';

import TemplateMenu from '../../template/menu.template'
import { getAdvanceSupplies, getOperators } from '../../Redux/userDuck'
import { onDeleteExpenseWorker } from '../../Redux/expenseDuck'
import { connect } from 'react-redux';
import { getParameterBySegment } from '../../Redux/parameterDuck';
import { getTurns } from '../../Redux/turnDuck';
import { formatNumber } from '../../utils';

const AdvanceSuppliesPage = ({ errors, getAdvanceSupplies, getParameterBySegment, getTurns, fetching_list, data,
    fetching_list_turn, fetching_segment, data_segment, data_turn, segment, getOperators, fetching_operator, data_operator,
    onDeleteExpenseWorker, fetching_delete }) => {

    const [list, setList] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 3,
        method_payment_id: 0, type_move_id: 0, deleted: false, operator_id: 0
    })
    const [modal, setModal] = useState(false)
    const [modalPhoto, setModalPhoto] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [listTurn, setListTurn] = useState([])
    const [total, setTotal] = useState(0)
    const [listOperator, setListOperator] = useState([]);


    useEffect(() => {
        getAdvanceSupplies(form)
        getParameterBySegment("box")
        getTurns()
        getOperators();

    }, [])

    useEffect(() => {
        if (fetching_delete == 2) {
            getAdvanceSupplies(form)
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_list == 2) {
            setList(data);
        }
    }, [fetching_list])

    useEffect(() => {

        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator)
        }
    }, [fetching_operator])


    useEffect(() => {
        if (fetching_segment == 2) {
            if (segment === 'box') {
                setListExpense(data_segment);
                getParameterBySegment("payment")
            }

            if (segment === 'payment') {
                setListPayment(data_segment)
            }
        }
    }, [fetching_segment])

    const updateField = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }

    }

    const onFilter = () => {
        getAdvanceSupplies(form)
    }

    const deleteItem = (item) => {
        if (window.confirm(`¿Deseas Eliminar el Registro?`)) {
            onDeleteExpenseWorker(item.id)
        }

    }

    return (
        <TemplateMenu>
            <Row>
                <Col lg={3} className="d-flex align-items-center"><h4>Insumos y Anticipos</h4>
                    {/* <button className='btn btn-sm btn-info ml-4' onClick={() => setModal(true)}>Agregar</button> */}
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Método de Pago</label>
                                <select className='form-control' name="method_payment_id" onChange={updateField}>
                                    <option value={0}>Todos</option>
                                    {listPayment.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Trabajadores</label>
                                <select className='form-control' name='operator_id' value={form.operator_id} onChange={updateField}>
                                    <option value={0}>Todos</option>
                                    {listOperator.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Concepto</label>
                                <select className='form-control' name="type_move_id" onChange={updateField}>
                                    <option value={0}>Todos</option>
                                    <option value={1}>Anticipos</option>
                                    <option value={2}>Insumos</option>
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de Inicio</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_init}
                                    name="date_init"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha Fin</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_end}
                                    name="date_end"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Turnos</label>
                                <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                                    <option value={0}>Seleccione</option>
                                    {listTurn.map((d, k) => <option key={k} value={d.id}>{d.id}- {d.user.name} | {d.date_start} | {d.date_end}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Borrados?</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    value={form.deleted}
                                    name="deleted"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>

                    </Row>

                </Col>
                <Col lg={2} className="d-flex align-items-center">
                    <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={12}>
                    <Table className='table-sm table-bordered table-hover' responsive>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha</th>
                                <th>Turno</th>
                                <th>Trabajador</th>
                                <th>Concepto</th>
                                <th>Descripción</th>
                                <th>Método de Pago</th>
                                <th>Soporte</th>
                                <th>Estado</th>
                                <th className='text-center'>Valor</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map((d, k) => <tr key={k}>
                                <td>{d.registration_date}</td>
                                <td>{d.cashier_id}</td>
                                <td>{d.worker}</td>
                                <td>{d.type_move}</td>
                                <td>{d.description}</td>
                                <td>{d.method_payment}</td>
                                <td>{d.suppor}</td>
                                <td>{d.is_paid ? 'Pagado' : 'Pendiente'}</td>
                                <td align='right'>{formatNumber(d.value)}</td>
                                <td>
                                    {d.is_paid === false && <button className='btn btn-sm btn-danger' onClick={() => deleteItem(d)}>Eliminar</button>}

                                </td>
                            </tr>) : <tr><td colSpan={6}>No hay registros</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={7}><b>TOTAL</b></td>
                                <td align='right'><b>{formatNumber(list.reduce((a, b) => a + parseFloat(b.value), 0))}</b></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user, parameter, turn, expense } = props

    return {
        errors: {},
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        segment: parameter.segment,
        turn: turn.row,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching_list: user.fetching_advance,
        data: user.data_advance,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
        fetching_delete: expense.fetching_delete,
    }
}

export default connect(mapsState, { getAdvanceSupplies, getParameterBySegment, getTurns, getOperators, onDeleteExpenseWorker })(AdvanceSuppliesPage);