import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'

import { getReviewParking } from '../../Redux/reportDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';

const ReviewParkingPage = ({ getReviewParking, fetching_bank, data }) => {

    let { date_init, date_end } = useParams()

    const [list, setList] = useState([])
    const tableRef = useRef(null);


    useEffect(() => {
        getReviewParking({ date_init, date_end })
    }, [date_init])

    useEffect(() => {
        if (fetching_bank === 2) {
            setList(data);
        }
    }, [fetching_bank])

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Reporte de Parqueadero <DownloadTableExcel
                    filename="reporte_parqueadero"
                    sheet="parqueadero"
                    currentTableRef={tableRef.current}>
                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                </DownloadTableExcel> </h4> </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered' ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha</th>
                                <th>Turno</th>
                                <th>Trabajador</th>
                                <th>Descripción</th>
                                <th>Método de Pago</th>
                                <th className='text-center'>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>
                                <td>{d.registration_date}</td>
                                <td>{d.cashier_id}</td>
                                <td>{d.worker}</td>
                                <td>{d.description}</td>
                                <td>{d.method_payment}</td>
                                <td align='right'>{formatNumber(d.value)}</td>
                            </tr>)}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5}>TOTAL</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { report } = props

    return {
        fetching_bank: report.fetching_bank,
        data: report.data_bank,
    }
}

export default connect(mapsState, { getReviewParking })(ReviewParkingPage);