import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'

import { getReviewSaleServices } from '../../Redux/reportDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { toogleAlert } from '../../Redux/alertDuck'

const ReviewSaleServicesPage = ({ getReviewSaleServices, fetching_bank, data, toogleAlert }) => {

    let { date_init, date_end } = useParams()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const turn_id = searchParams.get('turn_id');

    const [list, setList] = useState([])
    const tableRef = useRef(null);


    useEffect(() => {
        toogleAlert(true)
        getReviewSaleServices({ date_init, date_end, turn_id })
    }, [date_init, turn_id])

    useEffect(() => {
        if (fetching_bank === 2) {
            setList(data);
            toogleAlert(false)
        }
    }, [fetching_bank])

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Reporte de Ventas de Servicios <DownloadTableExcel
                    filename="reporte_venta_servicios"
                    sheet="venta_servicios"
                    currentTableRef={tableRef.current}>
                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                </DownloadTableExcel> </h4> </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered' ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Orden</th>
                                <th>Fecha</th>
                                <th>Turno</th>
                                <th>Categoria</th>
                                <th className='text-center'>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>
                                <td>{d.id}</td>
                                <td>{moment(d.finish_at).format("YYYY-MM-DD hh:mm A")}</td>
                                <td>{d.cashier_id}</td>
                                <td>{d.category}</td>
                                <td align='right'>{formatNumber(d.total)}</td>
                            </tr>)}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={4}>TOTAL</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { report } = props

    return {
        fetching_bank: report.fetching_bank,
        data: report.data_bank,
    }
}

export default connect(mapsState, { getReviewSaleServices, toogleAlert })(ReviewSaleServicesPage);