import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { getCategoryById, onCreateOrUpdatePrice, deleteCategoryPrice } from '../../Redux/categoryDuck'
import ModalParkingPrice from './modal.parking.price';


const EditConfigParking = ({ getCategoryById, fetching_by_id, row, onCreateOrUpdatePrice,
    deleteCategoryPrice, fetching_delete, fetching_price }) => {
    let { id } = useParams();
    const [modal, setModal] = useState(false)
    const [data, setData] = useState({})
    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setList(row.category_price)
        }
    }, [fetching_by_id])
    useEffect(() => {
        if (fetching_price === 2) {
            setModal(false)
            getCategoryById(id)

        }
    }, [fetching_price])

    useEffect(() => {
        if (fetching_delete === 2) {
            getCategoryById(id)
        }
    }, [fetching_delete])

    useEffect(() => {
        getCategoryById(id)
    }, [id])


    const onCreate = (form) => {
        let _data = data;
        data.category_price = form
        onCreateOrUpdatePrice(_data)
    }
    const onDelete = (id) => {
        if (window.confirm(`¿Deseas Eliminar el Regsitro?`)) {
            deleteCategoryPrice(id)
        }

    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-2'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{data.title}</Card.Title>
                            <p>{data.description}</p>
                            <Row className='my-3'>
                                <Col lg={3}><b>Precios</b></Col>
                                <Col>
                                    <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Agregar</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>TIPO</th>
                                                <th>PRECIO</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((a, k) => <tr key={k}>
                                                <td>{a.category_type}</td>
                                                <td align='right'>{a.price_formated}</td>
                                                <td align='right'>
                                                    <button className='btn btn-sm btn-danger' onClick={() => onDelete(a.id)}>X</button>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ModalParkingPrice show={modal} onHide={() => setModal(false)} onCreate={onCreate} />
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { category } = props

    return {
        fetching_by_id: category.fetching_by_id,
        fetching_delete: category.fetching_delete,
        fetching_price: category.fetching_price,
        row: category.row,
    }
}
export default connect(mapsState, { getCategoryById, onCreateOrUpdatePrice, deleteCategoryPrice })(EditConfigParking);