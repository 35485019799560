import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { getOperators } from '../../Redux/userDuck'
import { getCategories } from '../../Redux/categoryDuck'
import { getReview } from '../../Redux/reportDuck'
import moment from 'moment';
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import { Chart as ChartJs, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js'
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { toogleAlert } from '../../Redux/alertDuck'
import { getTurns } from '../../Redux/turnDuck';



import "./index.css"
import { useHistory } from 'react-router-dom';

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend, Filler
)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Ventas en El Mes por Precio',
        },
    },
}

const options2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Ventas en El Mes por Unidades',
        },
    },
}


const ReviewPage = ({ getParameterBySegment, getOperators, getCategories, data_category, data_operator, data_segment, fetching_category,
    fetching_operator, fetching_segment, getReview, fetching_report, data_report, toogleAlert, getTurns, fetching_list_turn, data_turn }) => {
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 0, operator_id: 0, method_payment_id: 0,
        category_id: 0, turn_id: 0
    })

    const history = useHistory();
    const [listMethods, setListMethods] = useState([]);
    const [listOperator, setListOperator] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [dataCategory, setDataCategory] = useState([]);
    const [dataOperator, setDataOperator] = useState([]);
    const [dataPayment, setDataPayment] = useState([]);
    const [totalCategory, setTotalCategory] = useState(0);
    const [amountCategory, setAmountCategory] = useState(0);
    const [dataService, setDataService] = useState([]);
    const [listDay, setListDay] = useState([]);
    const [listAmount, setListAmount] = useState([]);
    const [listSubtotal, setListSubtotal] = useState([]);
    const [listTotal, setListTotal] = useState([]);
    const [listExpense, setListExpense] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [listParking, setListParking] = useState([]);
    const [balance, setBalance] = useState([]);
    const [dataGh, setDataGh] = useState({})
    const [dataGhAmount, setDataGhAmount] = useState({})
    const [totalPayment, setTotalPayment] = useState(0)
    const [totalAmountParking, setTotalAmountParking] = useState(0)
    const [totalParking, setTotalParking] = useState(0)
    const [totalProductAmount, setTotalProductAmount] = useState(0)
    const [totalProduct, setTotalProduct] = useState(0)
    const [totalExpensePayment, setTotalExpensePayment] = useState(0)
    const [total, setTotal] = useState({ cash: 0, bank: 0, debito: 0, ingresos: 0, utility: 0, debito_pending: 0 })
    const [input, setInput] = useState({ service: 0, tip: 0, credit: 0, cxc: 0, parking: 0, product: 0, insumos: 0, store: 0 })
    const [output, setOutput] = useState({ discount: 0, operative: 0, anticipos: 0, payroll: 0, payroll_pending: 0 })
    const [balanceProduct, setBalanceProduct] = useState({})
    const [methodService, setMethodService] = useState([])
    const [methodProduct, setMethodProduct] = useState([])
    const [methodParking, setMethodParking] = useState([])
    const tableRef = useRef(null);
    const tableGeneralRef = useRef(null);
    const tableDailyBalance = useRef(null);
    const tableCategory = useRef(null);
    const tableServices = useRef(null);
    const tableProduct = useRef(null);
    const [listTurn, setListTurn] = useState([])

    useEffect(() => {
        getParameterBySegment("payment")
        getOperators();
        getCategories()

        toogleAlert(true)
        getTurns()
    }, [])


    useEffect(() => {
        if (fetching_report === 2) {

            let { categories, input, output, result_payments_services, cash, bank, debito, utility, ingresos, sales_by_day, result_cart,
                result_by_service, result_by_product, result_parking, result_operator

            } = data_report

            console.log('data_report', data_report);
            setDataOperator(result_operator)
            setTotal({ cash, bank, debito, utility, ingresos, debito_pending: data_report.debito_pending })
            setInput(input)
            setOutput(output)
            setMethodService(result_payments_services)
            setMethodProduct(result_cart)
            setListParking(result_parking)

            // setMethodParking(result_methods_parking)
            // setBalanceGeneral(general)

            setListProducts(result_by_product)


            setBalance(sales_by_day)
            // setTotalServicios(sales_by_day.reduce((a, b) => a + parseFloat(b.subtotal_servicios), 0))
            // setTotalProduct(sales_by_day.reduce((a, b) => a + parseFloat(b.total_products), 0))
            setDataCategory(categories)

            setDataService(result_by_service)

            toogleAlert(false)

            // setTotalDiscount(sales_by_day.reduce((a, b) => a + parseFloat(b.total_discount), 0))
            // setListProducts(list_products)

            // setListParking(parking)

            // setTotalProductAmount(list_products.reduce((a, b) => a + parseFloat(b.amount), 0))
            // setTotalProduct(list_products.reduce((a, b) => a + parseFloat(b.total), 0))


            // setTotalAmountParking(parking.reduce((a, b) => a + parseFloat(b.amount), 0))


            // let _total_payment = methods_payment.reduce((a, b) => a + parseFloat(b.ingresos), 0);
            // let _total_payment_expense = methods_payment.reduce((a, b) => a + parseFloat(b.expense), 0);

            // setDataPayment(methods_payment)
            // setTotalPayment(_total_payment)
            // setTotalExpensePayment(_total_payment_expense)

            // let _total_expense = sales_by_day.reduce((a, b) => a + parseFloat(b.total_expense), 0);
            // setTotalExpense(_total_expense)
            // let _total_expense_operative = sales_by_day.reduce((a, b) => a + parseFloat(b.expense), 0);
            // setTotalExpenseOperative(_total_expense_operative)

            // let _total_expense_salary = sales_by_day.reduce((a, b) => a + parseFloat(b.payroll), 0);
            // setTotalExpenseSalary(_total_expense_salary)

            // setTotalAnticipo(sales_by_day.reduce((a, b) => a + parseFloat(b.discount_worker), 0))

            // setSubtotal(sales_by_day.reduce((a, b) => a + parseFloat(b.subtotal), 0))
            // setTotal(sales_by_day.reduce((a, b) => a + parseFloat(b.total), 0))

            // let _total_category = categories.reduce((a, b) => a + parseFloat(b.total), 0);
            // setTotalCategory(_total_category)
            // let _amount_category = categories.reduce((a, b) => a + parseFloat(b.amount), 0);
            // setAmountCategory(_amount_category)


            // setListDay(data_sales.label);
            // setListAmount(data_sales.amount);
            // setListTotal(data_sales.data);
            // setListSubtotal(data_sales.subdata);
            // setListExpense(data_sales.expense);


            // setDataGh({
            //     labels: data_report.data_sales.label,
            //     datasets: [
            //         {
            //             label: 'Totales Servicios',
            //             data: data_report.data_sales.data,
            //             borderColor: 'rgb(255, 99, 132)',
            //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //         },
            //         {
            //             label: 'Totales Gastos',
            //             data: data_report.data_sales.expense,
            //             borderColor: 'rgb(53, 162, 235)',
            //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
            //         },
            //     ],
            // })

            // setDataGhAmount({
            //     labels: data_report.data_sales.label,
            //     datasets: [
            //         {
            //             label: 'Totales',
            //             data: data_report.data_sales.amount,
            //             borderColor: 'rgb(255, 99, 132)',
            //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //         },
            //     ],
            // })


        }
    }, [fetching_report])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            setListTurn(data_turn)

            let turn_active = data_turn.filter(d => d.status_id === 1)

            if (turn_active.length > 0) {
                let _form = {
                    ...form,
                    ["turn_id"]: turn_active[0].id
                }
                setForm(_form)
                getReview(_form)
            }

        }
    }, [fetching_list_turn])


    useEffect(() => {
        if (fetching_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_category])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator)
        }
    }, [fetching_operator])

    useEffect(() => {
        if (fetching_segment === 2) {
            setListMethods(data_segment)
        }
    }, [fetching_segment])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onFilter = (e) => {
        toogleAlert(true);
        getReview(form)
    }

    const showReview = (link) => {
        history.push(`${link}/${form.date_init}/${form.date_end}?turn_id=${form.turn_id}`)
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='my-2'>
                    <Col lg={2}><h3>Resumen</h3></Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Estado</label>
                                    <select className='form-control' name='status_id' value={form.status_id} onChange={updateField}>
                                        <option value={0}>Seleccione</option>
                                        <option value={1}>Nuevos</option>
                                        <option value={2}>En Proceso</option>
                                        <option value={3}>Pagados</option>
                                        <option value={4}>Pagados y Liquidados</option>
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Categoria</label>
                                    <select className='form-control' name='category_id' value={form.category_id} onChange={updateField}>
                                        <option value={0}>Seleccione</option>

                                        {listCategory.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Métodos de Pago</label>
                                    <select className='form-control' name='method_payment_id' value={form.method_payment_id} onChange={updateField}>
                                        <option value={0}>Seleccione</option>

                                        {listMethods.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Trabajadores</label>
                                    <select className='form-control' name='operator_id' value={form.operator_id} onChange={updateField}>
                                        <option value={0}>Seleccione</option>
                                        {listOperator.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>Turnos</label>
                                    <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                                        <option value={0}>TODOS</option>
                                        {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} | {d.date_start} | {d.date_end}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha de Inicio</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={form.date_init}
                                        name="date_init"
                                        onChange={updateField}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha Fin</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={form.date_end}
                                        name="date_end"
                                        onChange={updateField}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2} className="d-flex align-items-center">
                        <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <Table className='table-sm table-hover  table-bordered' responsive>
                            <thead className='thead-dark'>
                                <tr>
                                    <th colSpan={listDay.length + 1} className="text-center">TOTAL POR DÍAS</th>
                                </tr>
                                <tr>
                                    <th>DIA</th>
                                    {listDay.map((d, k) => <th key={k}>{d}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>CANTIDAD</td>
                                    {listAmount.map((d, k) => <td key={k} align="right">{d}</td>)}
                                </tr>
                                <tr>
                                    <td>GASTOS</td>
                                    {listExpense.map((d, k) => <td key={k} align="right">{formatNumber(d)}</td>)}
                                </tr>
                                <tr>
                                    <td>SUBTOTAL</td>
                                    {listSubtotal.map((d, k) => <td key={k} align="right">{formatNumber(d)}</td>)}
                                </tr>
                                <tr>
                                    <td>TOTAL</td>
                                    {listTotal.map((d, k) => <td key={k} align="right">{formatNumber(d)}</td>)}
                                </tr>

                            </tbody>
                        </Table>
                    </Col>
                </Row> */}

                <Row>
                    <Col>
                        {Object.keys(dataGh).length > 0 && <Line data={dataGh} options={options} />}
                    </Col>
                    <Col>
                        {Object.keys(dataGhAmount).length > 0 && <Line data={dataGhAmount} options={options2} />}
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col>
                        <Row>
                            <Col className='text-center'>
                                <h3>DETALLE BALANCE</h3>

                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Table className='table-sm table-bordered table-hover'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={2} className="text-center">DETALLE SERVICIOS</th>
                                        </tr>
                                        <tr>
                                            <th>DESCRIPCIÓN</th>
                                            <th className='text-center'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {methodService.map((d, k) => <tr key={k}>
                                            <td>{d.method_payment}</td>
                                            <td align='right'>{formatNumber(d.total)}
                                                <AiFillEye className='cursor-pointer' onClick={() => showReview(`resumen-banco/${d.method_payment_id}`)} /></td>
                                        </tr>)}
                                        <tr>
                                            <td>Insumos</td>
                                            <td align='right'>{formatNumber(input.insumos)}
                                                <AiFillEye className='cursor-pointer' onClick={() => showReview("resumen-insumo")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Compra a Crédito</td>
                                            <td align='right'>{formatNumber(0)}</td>
                                        </tr>
                                        <tr>
                                            <td>Venta de Servicios</td>
                                            <td align='right'>{formatNumber(input.service)}
                                                <AiFillEye className='cursor-pointer' onClick={() => showReview("resumen-ventas-servicios")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Propinas</td>
                                            <td align='right'>{formatNumber(input.tip)}
                                                <AiFillEye className='cursor-pointer' onClick={() => showReview("review-tips")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Descuento por Servicios</td>
                                            <td align='right'>{formatNumber(output.discount)}
                                                <AiFillEye className='cursor-pointer' onClick={() => showReview("review-discount-services")} /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg={4}>
                                <Table className='table-sm table-bordered table-hover'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={2} className="text-center">PRODUCTOS</th>
                                        </tr>
                                        <tr>
                                            <th>DETALLE</th>
                                            <th className='text-center'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {methodProduct.map((d, k) => <tr key={k}>
                                            <td>{d.method_payment}</td>
                                            <td align='right'>{formatNumber(d.total)}</td>
                                        </tr>)}
                                        <tr>
                                            <td>Propinas</td>
                                            {/* <td align='right'>{formatNumber(balanceProduct.tip_product)}
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg={4}>
                                <Table className='table-sm table-bordered table-hover'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={3} className="text-center">PARQUEADERO</th>
                                        </tr>
                                        <tr>
                                            <th>DETALLE</th>
                                            <th className='text-center'>CANT</th>
                                            <th className='text-center'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listParking.map((d, k) => <tr key={k}>
                                            <td>{d.method_payment}</td>
                                            <td align='right'>{formatNumber(d.quantity)}</td>
                                            <td align='right'>{formatNumber(d.total)}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                <h3>BALANCE GENERAL <DownloadTableExcel
                                    filename="balance_general"
                                    sheet="balance"
                                    currentTableRef={tableGeneralRef.current}>
                                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                </DownloadTableExcel>
                                </h3>

                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center'>
                            <Col lg={6}>
                                <Table className='table-sm table-bordered table-hover' ref={tableGeneralRef}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={3} className="text-center">SERVICIOS</th>
                                        </tr>
                                        <tr>
                                            <th>DETALLE</th>
                                            <th className='text-center'>DEBITO</th>
                                            <th className='text-center'>CREDITO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Efectivo</td>
                                            <td align='right'>{formatNumber(total.cash)}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Banco</td>
                                            <td align='right'>{formatNumber(total.bank)}</td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td>Ventas a Crédito</td>
                                            <td align='right'>{formatNumber(input.credit)}</td>
                                            <td align='right'></td>
                                        </tr>
                                        <tr>
                                            <td>Cuentas por Cobrar</td>
                                            <td align='right'>{formatNumber(input.cxc)}</td>
                                            <td align='right'></td>
                                        </tr>
                                        <tr>
                                            <td>Compra a Crédito</td>
                                            <td align='right'>{formatNumber(0)}</td>
                                            <td align='right'></td>
                                        </tr>
                                        <tr>
                                            <td>Venta de Servicios</td>
                                            <td></td>
                                            <td align='right'>{formatNumber(input.service)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Venta de Productos Adicionales</td>
                                            <td></td>
                                            <td align='right'>{formatNumber(input.product)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Venta de Tienda</td>
                                            <td></td>
                                            <td align='right'>{formatNumber(input.store)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Servicio de Parqueo</td>
                                            <td></td>
                                            <td align='right'>{formatNumber(input.parking)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Otros Ingresos</td>
                                            <td></td>
                                            <td align='right'>{formatNumber(input.other_revenues)}</td>
                                        </tr>
                                        <tr>
                                            <td>Insumos</td>
                                            <td align='right'>{formatNumber(input.insumos)}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Propinas Recibidas</td>
                                            <td></td>
                                            <td align='right'>{formatNumber(input.tip)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Gastos Operativos</td>
                                            <td align='right'>{formatNumber(output.operative)}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Anticipos a Trabajadores</td>
                                            <td align='right'>{formatNumber(output.anticipos)}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Pago a Trabajadores</td>
                                            <td align='right'>
                                                <Row>
                                                    <Col>{formatNumber(output.payroll)}</Col>
                                                </Row>
                                                {output.payroll_pending > 0 && <Row>
                                                    <Col className='text-danger'>({formatNumber(output.payroll_pending)})</Col>
                                                </Row>}

                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Descuento por Servicios</td>
                                            <td align='right'>{formatNumber(output.discount)}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><b>IGUALDADES</b></td>
                                            <td align='right'>{formatNumber(total.payroll_pending > 0 ? total.debito_pending : total.debito)}</td>
                                            <td align='right'>{formatNumber(total.ingresos)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><b>DISPONIBLE</b></td>
                                            <td align='right'>
                                                <b>{formatNumber(total.utility)}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col><h3>BALANCE DIARIO
                                <DownloadTableExcel
                                    filename="balance_diario"
                                    sheet="orders"
                                    currentTableRef={tableDailyBalance.current}>
                                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                </DownloadTableExcel>
                            </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className='table-sm table-bordered table-hover' ref={tableDailyBalance}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th rowSpan={2} className="text-center">FECHA</th>
                                            <th className='text-center' colSpan={5}>INGRESOS</th>
                                            <th className='text-center' colSpan={6}>GASTOS</th>
                                            <th className='text-center' rowSpan={2}>TOTAL</th>
                                        </tr>
                                        <tr>
                                            <th className='text-center'>SERVICIOS</th>
                                            <th className='text-center'>PROPINAS</th>
                                            <th className='text-center'>PRODUCTOS</th>
                                            <th className='text-center'>PARQUEADERO</th>
                                            <th className='text-center'>SUBTOTAL</th>
                                            <th className='text-center'>OPERATIVOS</th>
                                            <th className='text-center'>SALARIOS</th>
                                            <th className='text-center'>ANTICIPOS</th>
                                            <th className='text-center'>INSUMOS</th>
                                            <th className='text-center'>DESCUENTOS</th>
                                            <th className='text-center'>SUBTOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {balance.map((b, k) => <tr key={k}>
                                            <td className='border-right-table'>{b.date}</td>
                                            <td align='right' >{formatNumber(b.services)}</td>
                                            <td align='right'>{formatNumber(b.tip)}</td>
                                            <td align='right'>{formatNumber(b.products)}</td>
                                            <td align='right'>{formatNumber(b.parking)}</td>
                                            <td align='right' className='border-right-table'>{formatNumber(b.subtotal)}</td>
                                            <td align='right' className='table-warning'>{formatNumber(b.expense)}</td>
                                            <td align='right' className='table-warning'>{formatNumber(b.payroll)}</td>
                                            <td align='right' className='table-warning'>{formatNumber(b.anticipos)}</td>
                                            <td align='right' className='table-warning'>{formatNumber(b.insumo)}</td>
                                            <td align='right' className='table-warning'>{formatNumber(b.discount)}</td>
                                            <td align='right' className={`border-right-table table-warning`}>{formatNumber(b.subtotal_expense)}</td>
                                            <td align='right' className={`${b.total > 0 ? '' : 'text-danger'}`}>{formatNumber(parseFloat(b.total))}</td>
                                        </tr>)}

                                    </tbody>
                                    <tfoot>
                                        <tr className='border-top-table'>
                                            {/* <th className='border-right-table border-top-table'>SUBTOTAL</th>
                                            <th className='text-right'>{formatNumber(totalServicios)}</th>
                                            <th className='text-right'>{formatNumber(0)}</th>
                                            <th className='text-right'>{formatNumber(totalProduct)}</th>
                                            <th className='text-right '>{formatNumber(0)}</th>
                                            <th className='text-right border-right-table'>{formatNumber(subtotal + 0)}</th>
                                            <th className='text-right'>{formatNumber(totalExpenseOperative)}</th>
                                            <th className='text-right'>{formatNumber(totalExpenseSalary)}</th>
                                            <th className='text-right'>{formatNumber(0)}</th>
                                            <th className='text-right'>{formatNumber(totalAnticipo)}</th>
                                            <th className='text-right'>{formatNumber(totalDiscount)}</th>
                                            <th className='text-right border-right-table'>{formatNumber(totalExpense)}</th>
                                            <th className='text-right'>{formatNumber(total)}</th> */}
                                        </tr>

                                    </tfoot>
                                </Table>

                            </Col>
                        </Row>

                    </Col>
                    <Col lg={5}>
                        <Row>
                            <Col>
                                <Table className='table-sm table-hover table-bordered' ref={tableCategory}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={5} className="text-center">TOTAL POR CATEGORIA <DownloadTableExcel
                                                filename="total_categoria"
                                                sheet="orders"
                                                currentTableRef={tableCategory.current}>
                                                <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                            </DownloadTableExcel>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className='text-center'>DESCRIPCIÓN</th>
                                            <th className='text-center'>CANTIDAD</th>
                                            <th className='text-center'>SUBTOTAL</th>
                                            <th className='text-center'>DESCUENTO</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataCategory.map((d, k) =>
                                            <tr key={k}>
                                                <td>{d.category}</td>
                                                <td align='right'>{d.amount}</td>
                                                <td align='right'>{formatNumber(d.subtotal)}</td>
                                                <td align='right'>{formatNumber(d.discount)}</td>
                                                <td align='right'>{formatNumber(d.total)}</td>
                                            </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>TOTAL</th>
                                            <th className='text-right'>{formatNumber(dataCategory.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                            <th className='text-right'></th>
                                            <th className='text-right'></th>
                                            <th className='text-right'>{formatNumber(dataCategory.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Table className='table-sm table-hover  table-bordered' ref={tableServices}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={5} className="text-center">TOTAL POR SERVICIOS
                                                <DownloadTableExcel
                                                    filename="total_servicios"
                                                    sheet="orders"
                                                    currentTableRef={tableServices.current}>
                                                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                                </DownloadTableExcel></th>
                                        </tr>
                                        <tr>
                                            <th>DESCRIPCIÓN</th>
                                            <th>CANTIDAD</th>
                                            <th>SUBTOTAL</th>
                                            <th>DESCUENTO</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataService.map((d, k) =>
                                            <tr key={k}>
                                                <td>{d.service}</td>
                                                <td align='right'>{d.quantity}</td>
                                                <td align='right'>{formatNumber(d.price)}</td>
                                                <td align='right'>{formatNumber(d.discount)}</td>
                                                <td align='right'>{formatNumber(d.total)}</td>
                                            </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>TOTAL</th>
                                            <th className='text-right'>{formatNumber(dataService.reduce((a, b) => a + parseFloat(b.quantity), 0))}</th>
                                            <th className='text-right'></th>
                                            <th className='text-right'></th>
                                            <th className='text-right'>{formatNumber(dataService.reduce((a, b) => a + parseFloat(b.total), 0))}</th>

                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className='table-sm table-hover table-bordered' ref={tableProduct}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={4} className="text-center">TOTAL POR PRODUCTOS
                                                <DownloadTableExcel
                                                    filename="total_servicios"
                                                    sheet="orders"
                                                    currentTableRef={tableProduct.current}>
                                                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                                </DownloadTableExcel>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>DESCRIPCIÓN</th>
                                            <th>CANTIDAD</th>
                                            <th>PRECIO</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listProducts.map((d, k) =>
                                            <tr key={k}>
                                                <td>{d.product}</td>
                                                <td align='right'>{d.quantity}</td>
                                                <td align='right'>{formatNumber(d.price)}</td>
                                                <td align='right'>{formatNumber(d.subtotal)}</td>
                                            </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>TOTAL</th>
                                            <th className='text-right'>{listProducts.reduce((a, b) => a + parseFloat(b.quantity), 0)}</th>
                                            <th className='text-right'></th>
                                            <th className='text-right'>{formatNumber(listProducts.reduce((a, b) => a + parseFloat(b.subtotal), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Table className='table-sm table-hover  table-bordered'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={3} className="text-center">TOTAL PARQUEADERO</th>
                                        </tr>
                                        <tr>
                                            <th className='text-center'>CANTIDAD</th>
                                            <th className='text-center'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listParking.map((d, k) =>
                                            <tr key={k}>
                                                <td align='right'>{d.quantity}</td>
                                                <td align='right'>{formatNumber(d.total)}</td>
                                            </tr>)}
                                    </tbody>
                                    <tfoot>
                                        {/* <tr>
                                        <th>TOTAL</th>
                                        <th className='text-right'>{amountCategory}</th>
                                        <th className='text-right'>{formatNumber(totalCategory)}</th>
                                    </tr> */}
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Table className='table-sm table-hover  table-bordered'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th colSpan={3} className="text-center">TOTAL POR MÉTODOS DE PAGO</th>
                                        </tr>
                                        <tr>
                                            <th>DESCRIPCIÓN</th>
                                            <th>GASTOS</th>
                                            <th>INGRESOS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataPayment.map((d, k) =>
                                            <tr key={k}>
                                                <td>{d.description}</td>
                                                <td align='right'>{formatNumber(d.expense)}</td>
                                                <td align='right'>{formatNumber(d.ingresos)}</td>
                                            </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>TOTAL</th>
                                            <th className='text-right'>{formatNumber(totalExpensePayment)}</th>
                                            <th className='text-right'>{formatNumber(totalPayment)}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row> */}

                    </Col>
                    <Col lg={4}>

                        <Table className='table-sm table-hover table-bordered' ref={tableCategory}>
                            <thead className='thead-dark'>
                                <tr>
                                    <th colSpan={5} className="text-center">TOTAL POR OPERADOR <DownloadTableExcel
                                        filename="total_categoria"
                                        sheet="orders"
                                        currentTableRef={tableCategory.current}>
                                        <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                    </DownloadTableExcel>
                                    </th>
                                </tr>
                                <tr>
                                    <th className='text-center'>TRABAJADOR</th>
                                    <th className='text-center'>CANTIDAD</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataOperator.map((d, k) =>
                                    <tr key={k}>
                                        <td>{d.worker}</td>
                                        <td align='right'>{d.quantity}</td>
                                        <td align='right'>{formatNumber(d.total)}</td>
                                    </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>TOTAL</th>
                                    <th className='text-right'>{dataOperator.reduce((a, b) => a + parseFloat(b.quantity), 0)}</th>
                                    <th className='text-right'>{formatNumber(dataOperator.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { report, parameter, user, category, turn } = props

    return {
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
        fetching_category: category.fetching,
        data_category: category.data,
        data_report: report.data,
        fetching_report: report.fetching,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
    }
}

export default connect(mapsState, { getParameterBySegment, getOperators, getCategories, getReview, toogleAlert, getTurns })(ReviewPage);