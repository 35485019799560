import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getListPayroll } from '../../Redux/payrollDuck'
import { formatNumber } from '../../utils';
import moment from 'moment';

const PayrollPage = ({ getListPayroll, fetching_list, data }) => {
    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        getListPayroll()
    }, [])

    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col lg={1}>Nomina</Col>
                <Col>
                    <Link to="/nomina-create" className="btn btn-sm btn-info">Crear Nuevo</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha Creación</th>
                                <th>Rango</th>
                                <th>Dìas Trabajados</th>
                                <th>Trabajador</th>
                                <th>Salario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr>
                                <td>{moment(d.createdAt).format("YYYY-MM-DD")}</td>
                                <td>{d.date_init}/{d.date_end}</td>
                                <td>{d.days}</td>
                                <td>{d.worker.name}</td>
                                <td align='right'>{formatNumber(
                                    parseFloat(d.salary) + parseFloat(d.bonification)
                                    + parseFloat(d.tip) - parseFloat(d.supplies)
                                    - parseFloat(d.advances))}</td>
                            </tr>)}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { payroll } = props
    return {
        fetching_list: payroll.fetching_list,
        data: payroll.data,
    }
}

export default connect(mapsState, { getListPayroll })(PayrollPage);