
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_update: 0,
    data: [],
    errors: {}
}


const LIST_VEHICLE = "DEFAULT"
const LIST_VEHICLE_SUCCESS = "LIST_VEHICLE_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"
const UPDATE_VEHICLE = "UPDATE_VEHICLE"
const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_VEHICLE:
            return { ...state, fetching_list: 1 }
        case LIST_VEHICLE_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case UPDATE_VEHICLE:
            return { ...state, fetching_update: 1 }
        case UPDATE_VEHICLE_SUCCESS:
            return { ...state, fetching_update: 2 }
        default:
            return state;
    }
}

export let getListVehicle = () => (dispatch, getState) => {
    dispatch({
        type: LIST_VEHICLE
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        Axios.get(`${API_URL}/api/vehicle?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {

            dispatch({
                type: LIST_VEHICLE_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onUpdateVehicle = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_VEHICLE
    })

    if (getState().user.loggedIn) {
        Axios.put(`${API_URL}/api/vehicle/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: UPDATE_VEHICLE_SUCCESS,
            });
        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}