import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalPhoto = ({ show, photo, onHide }) => {
    return (
        <Modal show={show} size={"lg"} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Foto Soporte</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='d-flex justify-content-center'>
                    <Col lg={4}><img src={photo} className="img-fluid" /></Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPhoto;