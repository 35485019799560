import moment from "moment"

const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
    // return parseFloat(number).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const formatAddress = (address) => {

    let add = address[1].long_name
    let add2 = address[0].long_name

    let first = add.split(/^(Calle|Carrera|Avenida|Transversal)/).filter(row => !!row);

    first.push(add2.substring(0, 2))
    first.push(add2.substring(2, 4))

    let short = "";

    if (first[0] === 'Calle') {
        short = "Cl."
    }
    if (first[0] === 'Carrera') {
        short = "Cr."
    }
    if (first[0] === 'Transversal') {
        short = "Transv."
    }
    if (first[0] === 'Diagonal') {
        short = "Diag."
    }
    if (first[0] === 'Avenida') {
        short = "Av."
    }

    first.push(short)
    return first;

}

const removeSession = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("storage")
    localStorage.removeItem("date")
    localStorage.removeItem("menu")
    localStorage.removeItem("list_branch")
    localStorage.removeItem("range")
    localStorage.removeItem("branch")
}

function validateUser(form, edit) {
    let err = {};
    edit = edit || false

    // if (form.email === '') {
    //     err.email = "Email Requerida"
    // }

    // if (form.email !== '') {
    //     if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
    //         err.email = "Email no valido";
    //     }
    // }


    if (!edit) {
        if (form.password === '') {
            err.password = "Clave Requerida"
        }

        if (form.confirmation === '') {
            err.confirmation = "Confirmación Requerida"
        }
    }



    if (form.password !== '' && form.confirmation !== '') {
        if (form.password !== form.confirmation) {
            err.confirmation = "Confirmación debe coincidir con la Clave"
        }
    }

    if (form.name === '') {
        err.name = "Nombres Requeridos"
    }

    if (form.role_id === 0) {
        err.role_id = "Rol Requerida"
    }

    if (form.phone === '') {
        err.phone = "Celular Requerido"
    }

    if (form.business_id === 0) {
        err.business_id = "Cliente es Requerido"
    }
    if (form.branch_id === 0) {
        err.branch_id = "Sucursal es Requerido"
    }

    console.log('err', err)

    return err;
}

function validateBranch(form) {
    let err = {};

    if (form.title === '') {
        err.title = "Titulo Requerida"
    }

    if (form.priority === '') {
        err.priority = "Orden Requerida"
    }
    if (form.address === '') {
        err.address = "Dirección Requerida"
    }

    return err;
}

function validateBusiness(form) {
    let err = {};

    if (form.business === '') {
        err.business = "Alias es requerido"
    }

    if (form.business_name === '') {
        err.business_name = "Razón social es requerida"
    }
    if (form.document === '') {
        err.document = "Documento es requerido"
    }
    if (form.phone === '' || form.phone === null) {
        err.phone = "Telefono es requerido"
    }


    return err;
}
function validateProduct(form) {
    let err = {};

    if (form.name === '') {
        err.name = "El nombre es requerido"
    }
    if (form.description === '') {
        err.description = "La descripción es requerida"
    }

    if (form.priority === '') {
        err.priority = "La prioridad es requerida"
    }

    return err;
}

export const validatePhone = (phone) => {
    let pattern = /^3[0-9]{9}$/;

    if (pattern.test(phone)) {
        return true;
    }

    return false;

}

export const validateInput = (form, rules) => {
    let err = {}

    for (const [key, value] of Object.entries(form)) {
        rules.map(val => {
            if (key === val.field) {
                if (value === '' || value === 0 || value === null) {
                    err[key] = val.msg
                }
            }

        });
    }

    return Object.keys(err).length === 0 ? null : err
}



function errorLoginServer(error) {
    let err = {}

    if (error.response.status === 422) {
        err = { email: '', phone: '', name: '' }

        for (const [key, value] of Object.entries(error.response.data.errors)) {
            if ('email' === key) {
                err.email = value[0]

                if (value[0] === 'The email has already been taken.') {
                    err.message = "Email ya Existe"
                }

            }
            if ('phone' === key) {
                err.phone = value[0]
                if (value[0] === 'The phone has already been taken.') {
                    err.message = "Número de Teléfono ya Existe"
                }


            }
            if ('name' === key) {
                err.name = value[0]
            }
            if ('password' === key) {
                err.password = value[0]
                if (value[0] === 'The password must be at least 4 characters.') {
                    err.message = "La contraseña debe tener por lo menos 4 Caracteres"
                }
            }
        }
    } else {
        err.message = "Datos Incorrectos"
    }

    return err;
}

const getHeaders = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
}
const getHeadersImage = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.token,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",

    }
}

const getQuantityDays = (date_init, date_end) => {
    return (moment.duration(moment(date_init).diff(moment(date_end).format("YYYY-MM-DD"))).asDays() * -1) + 1
}


export {
    formatNumber, capitalize, formatAddress, removeSession, errorLoginServer, validateUser,
    validateBranch, getHeaders, validateBusiness, validateProduct, getHeadersImage,
    getQuantityDays
}