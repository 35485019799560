import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const initState = { code: '', description: '' }

const ModalDetailing = ({ modalForm, onHide, onCreate, formEdit, onEdit, errors }) => {
    const [form, setForm] = useState(initState)

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
        }
    }, [formEdit])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmitPre = () => {
        let _form = form
        _form.type_element = modalForm.type;

        if (_form.id !== undefined) {
            onEdit(_form)
        } else {
            onCreate(_form)
        }
        setForm(initState)
    }

    return (
        <Modal show={modalForm.modal} onHide={onHide}>
            <Modal.Header closeButton>
                {modalForm.title}
            </Modal.Header>
            <Modal.Body>
                {modalForm.type === 1 && <Row>
                    <Col>
                        <div className="form-group">
                            <label>Código</label>
                            <InputField
                                placeholder="Código"
                                value={form.code || ''}
                                name="code"
                                onChange={updateInput}
                                error={errors.code} />
                        </div>
                    </Col>
                </Row>}

                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Descripción</label>
                            <InputField
                                placeholder="Descripción"
                                value={form.description || ''}
                                name="description"
                                onChange={updateInput}
                                error={errors.description} />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmitPre}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalDetailing;