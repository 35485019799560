import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getInventoryLowDown, onCreateLowDown, getInventoryLowDownByProduct, onEditLowDown, onDeleteLowDown } from '../../Redux/inventoryDuck'

import { getProducts } from '../../Redux/productDuck';
import { getParameterBySegment } from '../../Redux/parameterDuck';
import moment from 'moment';
import ItemTableLowDown from './item.table.lowdown';

const initState = { product_id: '', registration_date: moment().format("YYYY-MM-DD"), reason_id: 0, amount: '' };
const LowDownPage = ({ getInventoryLowDown, fetching_list, data, getProducts, fetching_list_product, products, getParameterBySegment,
    fetching_by_segment, data_segment, onCreateLowDown, fetching_create, getInventoryLowDownByProduct, fetching_by_id, row_inventory,
    onEditLowDown, onDeleteLowDown }) => {

    const [list, setList] = useState([])
    const [form, setForm] = useState(initState)
    const [listProduct, setListProduct] = useState([])
    const [listSegment, setListSegment] = useState([])
    const [inventory, setInventory] = useState("")
    const [errorInv, setErrorInv] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [error, setError] = useState({})
    const [quantityLowdown, setQuantityLowDown] = useState(0)

    useEffect(() => {
        getInventoryLowDown()
        getProducts();
        getParameterBySegment("low_down")
    }, [])

    useEffect(() => {
        if (fetching_by_id === 2) {
            if (row_inventory.length === 0) {
                setErrorInv(true)
            } else {
                setErrorInv(false)
                setInventory(row_inventory)
            }
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_list_product === 2) {
            setListProduct(products)
        }
    }, [fetching_list_product])


    useEffect(() => {
        if (fetching_create === 2) {
            getInventoryLowDown()
            setInventory("")
            setForm(initState);
            setShowEdit(false)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_by_segment === 2) {
            console.log('data_segment', data_segment);

            setListSegment(data_segment)
        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    const updateInput = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.value
        }
        setForm(_form)

        if (e.target.name === 'product_id') {
            getInventoryLowDownByProduct(_form)
        }

        if (e.target.name === 'registration_date' && form.product_id !== '') {
            getInventoryLowDownByProduct(_form)
        }
    }


    const onLowDown = () => {

        let _errors = {}

        if (form.reason_id === 0) {
            _errors.reason_id = "Razón es Obligatoria"
        }
        if (form.amount === 0 || form.amount === '') {
            _errors.amount = "La cantidad es Obligatoria"
        }

        console.log('(parseFloat(inventory.amount_total) + parseFloat(quantityLowdown))', (parseFloat(inventory.amount_total) + parseFloat(quantityLowdown)));


        if ((parseFloat(inventory.amount_total) + parseFloat(quantityLowdown)) < parseFloat(form.amount)) {
            _errors.amount = `No puedes superar la cantidad de ${(parseFloat(inventory.amount_total) + parseFloat(quantityLowdown))}`
        }

        if (Object.keys(_errors).length > 0) {

            setError({ ..._errors })
            return;
        }

        if (form.id === undefined) {
            onCreateLowDown(form)
        } else {
            onEditLowDown(form)
        }

    }

    const onEdit = (row) => {
        row.registration_date = moment(row.registration_date).format("YYYY-MM-DD")
        getInventoryLowDownByProduct(row)
        setForm(row)
        setQuantityLowDown(row.amount)
    }

    const onDelete = (row) => {

        if (window.confirm("¿Realmente deseas eliminar Esta Baja?")) {
            onDeleteLowDown(row.id)
        }
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col><h2>Inventario</h2></Col>
            </Row>
            <Row className='my-2 d-flex justify-content-center'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Fecha</Form.Label>
                                        <input type={"date"} className='form-control' name='registration_date' value={form.registration_date} placeholder="Cantidad" onChange={updateInput} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Producto</Form.Label>
                                        <select className='form-control' name='product_id' value={form.product_id} onChange={updateInput}>
                                            <option value={0}>Seleccione</option>
                                            {listProduct.map((p, k) => <optgroup key={k} value={p.id} label={p.name}>
                                                {p.products.map((p, key) => <option value={p.id}>{p.name}</option>)}
                                            </optgroup>)}
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {errorInv === true && <Row><Col className='text-danger text-center'><h3>Para {form.registration_date}, No tiene Inventario Disponible</h3></Col></Row>}
                            {inventory !== '' && <>
                                <Row className='my-3'>
                                    <Col className='text-center'><h4>Cantidad disponible para {form.registration_date} ({inventory.amount_total} {inventory.measure_unit})</h4></Col>
                                </Row>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Razón</Form.Label>
                                            <select className='form-control' name='reason_id' value={form.reason_id} onChange={updateInput}>
                                                <option value={0}>Seleccione</option>
                                                {listSegment.map((p, k) => <option key={k} value={p.id}>{p.description}</option>)}
                                            </select>
                                            {error.reason_id !== undefined && (<label className="error-label">{error.reason_id}</label>)}

                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Cantidad</Form.Label>
                                            <input type={"number"} className='form-control' value={form.amount} name="amount" placeholder="Cantidad" onChange={updateInput} />
                                            {error.amount !== undefined && (<label className="error-label">{error.amount}</label>)}

                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} className="d-flex align-items-center">
                                        <button className='bbtn btn-sm btn-info' onClick={onLowDown}>Dar de Baja</button>
                                    </Col>
                                </Row>
                            </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table-bordered table-sm'>
                        <thead className='thead-dark'>
                            <tr>
                                <th align="center">Fecha</th>
                                <th>Producto</th>
                                <th>Razón</th>
                                <th>Cantidad</th>
                                <th>Costo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map(row => <ItemTableLowDown row={row} key={row.id} onEdit={onEdit} onDelete={onDelete} />) : <tr><td colSpan={12} align="center">No se encontraron registros</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { inventory, product, parameter } = props
    return {
        fetching_list: inventory.fetching_list,
        data: inventory.data,
        products: product.data,
        fetching_list_product: product.fetching_list,
        fetching_create: inventory.fetching_create,
        row_inventory: inventory.row,
        fetching_by_id: inventory.fetching_by_id,
        fetching_by_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment
    }
}

export default connect(mapsState, {
    getInventoryLowDown, getProducts, getParameterBySegment, onCreateLowDown,
    getInventoryLowDownByProduct, onEditLowDown, onDeleteLowDown
})(LowDownPage);