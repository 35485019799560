import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { getServicesByCategory } from '../../Redux/serviceDuck';
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';

const initState = { payment_id: 0, plate: '', operator_id: 0, client_id: 0, category_id: 0 };

const ModalOrderManual = ({ show, payments, onHide, listClient, listOperator, listPayment, listCategory, getServicesByCategory, fetching_list, data, onSuccess }) => {
    const [form, setForm] = useState(initState)
    const [errors, setErrors] = useState({})
    const [listServices, setListServices] = useState([])

    useEffect(() => {

        if (fetching_list === 2) {
            setListServices(data)
        }
    }, [fetching_list])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === "client_id") {
            getServicesByCategory(form.category_id, e.target.value)
        }
    }

    const onSuccessPre = () => {
        let _form = form

        _form.operator = listOperator.filter(d => d.id === parseInt(form.operator_id))[0]
        _form.category = listCategory.filter(d => d.id === parseInt(form.category_id))[0]
        _form.payment = listPayment.filter(d => d.id === parseInt(form.payment_id))[0]
        _form.service = listServices.filter(d => d.id === parseInt(form.service_id))[0]

        onSuccess(_form)

        setForm(initState)

    }

    return (
        <Modal show={show} size={"lg"} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Cargar Orden de Servicio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Fecha</label>
                            <InputField
                                type={"date"}
                                placeholder="Fecha de Registro"
                                value={form.registration_date || ''}
                                name="registration_date"
                                onChange={updateField}
                                error={errors.registration_date}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label>Placa</label>
                            <InputField
                                placeholder="Placa"
                                value={form.plate || ''}
                                name="plate"
                                onChange={updateField}
                                error={errors.description}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label>Categoria</label>
                            <select className='form-control' name='category_id' value={form.category_id} onChange={updateField}>
                                <option value={0}>Seleccione</option>
                                {listCategory.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                            </select>
                        </div>
                    </Col>


                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Cliente</label>
                            <select className='form-control' name='client_id' value={form.client_id} onChange={updateField}>
                                <option value={0}>Seleccione</option>
                                {listClient.map((d, k) => <option key={k} value={d.id}>{d.label}</option>)}
                            </select>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label>Operador</label>
                            <select className='form-control' name='operator_id' value={form.operator_id} onChange={updateField}>
                                <option value={0}>Seleccione</option>
                                {listOperator.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                            </select>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Servicios</label>
                            <select className='form-control' name='service_id' value={form.service_id} onChange={updateField}>
                                <option value={0}>Seleccione</option>
                                {listServices.map((d, k) => <option key={k} value={d.id}>{d.description} ({formatNumber(d.price)})</option>)}
                            </select>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label>Método de Pago</label>
                            <select className='form-control' name='payment_id' value={form.payment_id} onChange={updateField}>
                                <option value={0}>Seleccione</option>
                                {listPayment.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                            </select>
                        </div>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className='d-flex justify-content-end'>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSuccessPre}>Crear</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { service } = props
    return {
        fetching_list: service.fetching_list,
        data: service.data,
    }
}

export default connect(mapsState, { getServicesByCategory })(ModalOrderManual);