import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import ItemTableBriefcaseAccount from './item.table.briefcase.account';
import { getListBriefcase, onPaymentBriefcase, onDeleteBriefcase, onReverseBriefcase } from '../../Redux/briefcaseDuck'
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ModalPaymentBriefcase from './modal.payment';
import { getParameterBySegment } from '../../Redux/parameterDuck'


const ListBriefCasePage = ({ getListBriefcase, data, fetching_list, getParameterBySegment, fetching_segment,
    data_segment, onPaymentBriefcase, fetching_payment, onDeleteBriefcase, onReverseBriefcase, fetching_delete }) => {

    const [list, setList] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [modal, setModal] = useState(false)
    const [selected, setSelected] = useState({})

    useEffect(() => {
        getListBriefcase()
        getParameterBySegment("payment")
    }, [])

    useEffect(() => {
        if (fetching_payment === 2) {
            setModal(false)
            getListBriefcase()
        }
    }, [fetching_payment])

    useEffect(() => {
        if (fetching_delete === 2) {
            setModal(false)
            getListBriefcase()
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_segment === 2) {
            setListPayment(data_segment)
        }
    }, [fetching_segment])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    const onPaymentOrder = (row) => {
        setSelected(row)
        setModal(true)
    }
    const onSubmit = (method_payment_id) => {
        let form = { method_payment_id, briefcase_id: selected.id }
        onPaymentBriefcase(form)
    }
    const onDelete = (row) => {
        if (window.confirm(`¿Deseas Eliminar la Precuenta de Cobro?`)) {
            onDeleteBriefcase(row.id)
        }
    }
    const onReverse = (row) => {
        if (window.confirm(`¿Deseas Reversa la Cuenta de Cobro?`)) {
            onReverseBriefcase(row.id)
        }
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col lg={2}>
                    <p>Cuentas de Cobro</p>
                </Col>
                <Col>
                    <Link to={`/cartera-create`} className="btn btn-sm btn-success">Crear Cuenta</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>FECHA</th>
                                <th>CONSECUTIVO</th>
                                <th>CLIENTE</th>
                                <th>TELEFONO</th>
                                <th>ESTADO</th>
                                <th className='text-center'>TOTAL</th>
                                <th className='text-center'></th>

                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map((d, k) => <ItemTableBriefcaseAccount key={k} item={d}
                                onPaymentOrder={() => onPaymentOrder(d)}
                                onDelete={() => onDelete(d)}
                                onReverse={() => onReverse(d)}
                            />) : <tr>
                                <td colSpan={4} align='center'>No tienes Resultados</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5}>TOTAL</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <ModalPaymentBriefcase show={modal} onHide={() => setModal(false)} listPayment={listPayment} onSubmit={onSubmit} />
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { briefcase, parameter } = props

    return {
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_list: briefcase.fetching_list,
        fetching_payment: briefcase.fetching_payment,
        fetching_delete: briefcase.fetching_delete,
        data: briefcase.data
    }
}

export default connect(mapsState, {
    getListBriefcase, getParameterBySegment, onPaymentBriefcase,
    onDeleteBriefcase, onReverseBriefcase,
})(ListBriefCasePage);