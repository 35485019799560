import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const ModalPlate = ({ show, onHide, listCategory, onCreateVehicle, formEdit, onUpdateVehicle }) => {

    const [form, setForm] = useState({ plate: '', category_id: 0 });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (formEdit !== undefined)
            if (Object.keys(formEdit).length > 0) {
                setForm(formEdit)
            }
    }, [formEdit])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {

        if (form.id !== undefined) {
            onUpdateVehicle(form)
        } else {
            onCreateVehicle(form)
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Datos Vehículo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Placa</label>
                            <InputField
                                placeholder="Placa"
                                value={form.plate || ''}
                                name="plate"
                                onChange={updateInput}
                                error={errors.plate}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Categoria</label>
                            <select name='category_id' value={form.category_id} onChange={updateInput} className="form-control">
                                <option>Seleccion</option>
                                {listCategory.map(d => <option key={d.id} value={d.id}>{d.title}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPlate;