import React, { useState } from 'react'

const initState = { method_payment_id: 0, id: 0 };
const ItemTablePayment = ({ item, listMethods, onDeleteRow }) => {

    const [form, setForm] = useState(initState)

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onEditRow = (row) => {
        setForm(row)
    }

    const onSaveRow = () => {
        let method = listMethods.filter(d => parseInt(d.id) === parseInt(form.method_payment_id))[0]

        item.payment = method;
        item.method_payment_id = form.method_payment_id;

        setForm(initState)
        console.log('method', method);

        console.log('form----', form);

    }


    return (
        <tr>
            <td>
                {(form.id !== 0 & item.id === form.id) ?
                    <select className='form-control' name='method_payment_id' value={form.method_payment_id} onChange={updateField}>
                        <option value={0}>Seleccione</option>
                        {listMethods.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                    </select>
                    : item.payment.description}
            </td>
            <td align='right'>{item.amount_formated}</td>
            <td>
                {(form.id !== 0 & item.id === form.id) ? <>
                    <button className='btn btn-sm btn-success mx-2' onClick={onSaveRow}>Guargar</button>
                    <button className='btn btn-sm btn-warning' onClick={() => setForm(initState)}>Cancelar</button>
                </> :
                    <>
                        <button className='btn btn-sm btn-info mx-2' onClick={() => onEditRow(item)}>Editar</button>
                        <button className='btn btn-sm btn-danger' onClick={() => onDeleteRow(item)}>Quitar</button>
                    </>}

            </td>
        </tr >
    )
}

export default ItemTablePayment;