import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import ItemTableOrderWorker from './item.table.order.worker';
import { getListOrderWorker, onLiquidate, onEditPercent, onAddCostWorker, onUpdatePercentOrders } from '../../Redux/orderDuck';
import { onDeleteExpenseWorker } from '../../Redux/expenseDuck';
import { getTurns } from '../../Redux/turnDuck';
import moment from 'moment';
import ModalPayment from './modal.payment';
import { formatNumber } from '../../utils';
import ModalExpense from './modal.expense';
import { RiFileExcel2Fill } from "react-icons/ri";
import ModalChange from './modal.change';
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { toogleLoader } from '../../Redux/alertDuck';

const OrderWorkerPage = ({ getListOrderWorker, fetching_worker, data_worker, fetching_liquidate, onLiquidate, range, onEditPercent, fetching_update,
    onAddCostWorker, onDeleteExpenseWorker, fetching_create, onUpdatePercentOrders, fetching_percent, getParameterBySegment, fetching_segment, data_segment,
    turn_active, getTurns, fetching_list, data_turn, fetching_active, toogleLoader, business }) => {
    const [list, setList] = useState([])
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 0,
        diff: 0, turn_id: 0
    });

    const [show, setShow] = useState(false);
    const [modalExpense, setModalExpense] = useState(false);
    const [modalChange, setModalChange] = useState(false);
    const [data, setData] = useState({ detail: [], expense: [] })
    const [listPayment, setListPayment] = useState([])
    const [listTurn, setListTurn] = useState([])
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [subTotalGenerate, setSubTotalGenerate] = useState(0)
    const [totalGenerate, setTotalGenerate] = useState(0)
    const [totalValueGenerate, setTotalValueGenerate] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totaTip, setTotalTip] = useState(0)
    const [selected, setSelected] = useState({})
    const [selectedTurn, setSelectedTurn] = useState({ user: { name: '' } })
    const tableRef = useRef(null)

    useEffect(() => {
        if (fetching_worker === 2) {
            setList(data_worker);

            let _total_quantity = data_worker.reduce((a, b) => a = a + parseFloat(b.quantity), 0)
            let _subtotal_generate = data_worker.reduce((a, b) => a = a + (parseFloat(b.payment_worker)), 0)
            let _total_generate = data_worker.reduce((a, b) => a = a + (parseFloat(b.total)), 0)
            let _total_discount = data_worker.reduce((a, b) => a = a + (parseFloat(b.discount)), 0)
            let _total_value = data_worker.reduce((a, b) => a = a + (parseFloat(b.subtotal)), 0)
            let _total_tip = data_worker.reduce((a, b) => a = a + (parseFloat(b.tip)), 0)

            setTotalValueGenerate(_total_value)
            setTotalQuantity(_total_quantity);
            setSubTotalGenerate(_subtotal_generate)
            setTotalGenerate(_total_generate)
            setTotalTip(_total_tip)
            setTotalDiscount(_total_discount)
            toogleLoader(false)
        }
    }, [fetching_worker])


    useEffect(() => {
        if (fetching_active === 2) {
            getTurns()
        }
    }, [fetching_active])

    useEffect(() => {
        if (fetching_list === 2) {
            setListTurn(data_turn)
            if (turn_active !== null) {
                setForm({ ...form, ["turn_id"]: turn_active.id })
            }

        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_segment === 2) {
            setListPayment(data_segment)
        }
    }, [fetching_segment])

    useEffect(() => {
        if (fetching_liquidate === 2) {
            setShow(false);

            getListOrderWorker(form)
        }
    }, [fetching_liquidate])

    useEffect(() => {
        if (fetching_percent === 2) {
            setModalChange(false)
            getListOrderWorker(form)
        }
    }, [fetching_percent])

    useEffect(() => {
        if (fetching_update === 2) {
            setModalExpense(false)
            getListOrderWorker(form)
        }
    }, [fetching_update])

    useEffect(() => {
        if (fetching_create === 2) {
            getListOrderWorker(form)

        }
    }, [fetching_create])

    useEffect(() => {
        getParameterBySegment("payment")
    }, [])

    const updateField = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'turn_id' || e.target.name === 'status_id') {
            let _form = form
            _form.turn_id = e.target.value
            toogleLoader(true)
            getListOrderWorker(form)
        }

    }

    const onPaidEvent = (item) => {

        if (turn_active === null) {
            alert("No tienes un turno actual");
            return false;
        }

        let _turn = listTurn.filter(d => d.id === turn_active.id)[0]

        if (_turn === undefined) {
            alert("No tienes un turno actual");
            return false;
        }

        setSelectedTurn(_turn)
        setData(item)
        setShow(true)
    }
    const onPayment = (data) => {
        data.range = range
        data.turn = selectedTurn
        onLiquidate(data)
    }

    const onFilter = () => {
        if (form.status_id !== 0) {
            toogleLoader(true)
            getListOrderWorker(form)
        } else {
            alert("Escoge un estado")
        }
    }

    const onEditPercentRow = (item) => {
        onEditPercent(item)
    }

    const onOpenModalExpense = (item, type) => {
        setModalExpense(true)
        item.date_init = form.date_init
        item.type_move_id = type
        item.title = type === 1 ? 'Anticipos' : 'Insumos'
        setSelected(item)
    }

    const onAddExpenseWorker = (data) => {
        data.user_id = selected.id;
        data.cashier_id = turn_active.id
        onAddCostWorker(data)
    }
    const onChangePercent = item => {

        var now = moment(form.date_end); //todays date
        var end = moment(form.date_init); // another date
        var duration = moment.duration(now.diff(end));
        var days = duration.asDays();
        setForm({
            ...form,
            ["diff"]: days
        });

        setData(item)
        setModalChange(true)
    }

    const onDeleteRow = (row) => {
        if (window.confirm("¿Realmente deseas Borrar Este Registro?")) {
            onDeleteExpenseWorker(row.id)
        }
    }

    const onSuccessPercent = (data) => {
        onUpdatePercentOrders(data)
    }

    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col lg={3} className="d-flex align-items-center"><h4>Ordenes por Trabajadores</h4></Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>BUSCAR POR RANGO DE FECHA</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>({d.id}) {d.user.name} ({d.date_start}) - ({d.date_end})</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col lg={2}>
                    <div className="form-group">
                        <label>Estado</label>
                        <select className='form-control' name='status_id' value={form.status_id} onChange={updateField}>
                            <option value={0}>Seleccione</option>
                            {/* <option value={1}>Nuevos</option> */}
                            {/* <option value={2}>En Proceso</option> */}
                            <option value={3}>Listos Para Liquidar</option>
                            <option value={4}>Pagados y Liquidados</option>
                        </select>
                    </div>
                </Col>
                <Col lg={2} className="d-flex align-items-center">
                    <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <DownloadTableExcel
                        filename="orders_workers"
                        sheet="orders"
                        currentTableRef={tableRef.current}>
                        <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                    </DownloadTableExcel>

                </Col>
                <Col lg={2}>
                    <input className='form-control' />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table-sm table-bordered' responsive ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Trabajador</th>
                                <th className='text-center'>Días trabajados</th>
                                <th className='text-center'>Total Servicios</th>
                                <th className='text-center'>Total Generado</th>
                                <th className='text-center'>Total Generado por Trabajador</th>
                                <th className='text-center'>Total Descuentos</th>
                                <th className='text-center'>Total Propinas</th>
                                <th className='text-center'>Total a Pagar</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0)
                                ? list.map((l, k) => <ItemTableOrderWorker key={k} item={l}
                                    onPaidEvent={onPaidEvent}
                                    form={form}
                                    onEditPercent={onEditPercentRow}
                                    onOpenModalExpense={onOpenModalExpense}
                                    onDeleteRow={onDeleteRow}
                                    onChangePercent={onChangePercent}
                                    turn_id={form.turn_id}
                                />)
                                : <tr><td colSpan={7} align="center">No hay registros</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3}><b>TOTAL</b></td>
                                <td align='right'>{totalQuantity}</td>
                                <td align='right'>{formatNumber(totalValueGenerate)}</td>
                                <td align='right'>{formatNumber(subTotalGenerate)}</td>
                                <td align='right'>{formatNumber(totalDiscount)}</td>
                                <td align='right'>{formatNumber(totaTip)}</td>
                                <td align='right'>{formatNumber(totalGenerate)}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>

            <ModalPayment show={show} data={data} onPayment={onPayment} onHide={() => setShow(false)}
                listPayment={listPayment.filter(d => d.id !== 8 && d.id !== 38 && d.id !== 40 && d.id !== 41 && d.id !== 42)}
                turn_active={selectedTurn} />
            <ModalChange show={modalChange} data={data} onSuccess={onSuccessPercent} onHide={() => setModalChange(false)} days={form.diff} />
            <ModalExpense
                show={modalExpense}
                onHide={() => setModalExpense(false)} selected={selected}
                onSucess={onAddExpenseWorker}
                listPayment={listPayment}
                turn_active={turn_active} />
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { order, expense, parameter, turn, user } = props

    return {
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_worker: order.fetching_worker,
        data_worker: order.data_worker,
        range: order.range,
        fetching_liquidate: order.fetching_liquidate,
        fetching_create: expense.fetching_create,
        fetching_update: order.fetching_update,
        fetching_percent: order.fetching_percent,
        turn_active: turn.row,
        fetching_active: turn.fetching_active,
        fetching_list: turn.fetching_list,
        data_turn: turn.data,
        business: user.user.business,
    }
}


export default connect(mapsState, {
    getListOrderWorker, onLiquidate, onEditPercent, onAddCostWorker, onDeleteExpenseWorker,
    onUpdatePercentOrders, getParameterBySegment, getTurns, toogleLoader
})(OrderWorkerPage);
