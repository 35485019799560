import React from 'react';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL


const ItemTableCategoryProduct = ({ index, item, onDelete, onEdit }) => {
    let { category_price } = item
    let photo = API_URL + "/" + item.image;

    return (
        <tr>
            <td>{index}</td>
            <td>{item.name}</td>
            <td>{item.priority}</td>
            {/* <td>
                <img src={photo} className="img-icon" />
            </td> */}
            <td>{item.status_text}</td>
            <td>
                <button className="btn btn-info btn-sm mx-1" onClick={onEdit}>Editar</button>
                <button className="btn btn-danger btn-sm" onClick={() => onDelete(item)}>Quitar</button>
            </td>

        </tr>
    )
}
export default ItemTableCategoryProduct; 