import React from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ModalServices = ({ show, onHide, listServices, onClick }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Servicios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {listServices.map((d, k) => <Row key={k} className="my-2" onClick={() => onClick(d)}>
                    <Col>
                        <Card>
                            <Card.Body className='my-0 p-2'>
                                <Row>
                                    <Col><b>Nombre</b></Col>
                                </Row>
                                <Row>
                                    <Col>{d.service}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Descripción</b></Col>
                                </Row>
                                <Row>
                                    <Col>{d.description}</Col>
                                </Row>

                                <Row className='border-top mt-3'>
                                    <Col><b>PRECIO</b></Col>
                                    <Col className='text-right'><b>{formatNumber(d.price)}</b></Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>)}

            </Modal.Body>
        </Modal>
    )
}

export default ModalServices;