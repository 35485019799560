import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getListVehicle, onUpdateVehicle } from '../../Redux/vehicleDuck';
import { connect } from 'react-redux';
import ItemVehicle from './item.vehicle';
import ModalPlate from '../Clients/modal.plate';
import { getCategories } from '../../Redux/categoryDuck'


const VehiclePage = ({ fetching_list, data, getListVehicle, getCategories, fetching_category, data_category, onUpdateVehicle, fetching_update }) => {
    const [list, setList] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});


    useEffect(() => {
        getCategories()
        getListVehicle()
    }, [])

    useEffect(() => {
        if (fetching_update === 2) {
            setModal(false)
            setForm({})
            getListVehicle()
        }
    }, [fetching_update])

    useEffect(() => {
        if (fetching_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_category])


    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    const onEdit = (row) => {
        setForm(row)
        setModal(true)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col>Listado de Vehículos</Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={6}>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha de Registro</th>
                                <th>Placa</th>
                                <th>Categoria</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <ItemVehicle row={row} key={row.id} onEdit={onEdit} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>
                    </table>
                </Col>
            </Row>
            <ModalPlate show={modal} listCategory={listCategory} onHide={() => setModal(false)} formEdit={form} onUpdateVehicle={onUpdateVehicle} />
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { vehicle, category } = props

    return {
        fetching_list: vehicle.fetching_list,
        fetching_update: vehicle.fetching_update,
        fetching_category: category.fetching,
        data: vehicle.data,
        data_category: category.data
    }
}

export default connect(mapsState, { getListVehicle, getCategories, onUpdateVehicle })(VehiclePage);