import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button, Table, Tabs, Tab } from 'react-bootstrap';
import { getProducts, createOrUpdateProduct, updateProduct, deleteProduct } from '../../Redux/productDuck'
import './index.css'
import TemplateMenu from '../../template/menu.template'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
import UploadProductSegment from './upload.products';

const API_URL = process.env.REACT_APP_API_URL

const ProductPage = ({ getProducts, fetching_list, data, createOrUpdateProduct, fetching_edit, deleteProduct, fetching_delete }) => {

    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState({});
    const [listCategory, setListCategory] = useState([]);
    const [listProduct, setListProduct] = useState([]);

    useEffect(() => {
        getProducts()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setListCategory(data)
        }

    }, [fetching_list])

    useEffect(() => {
        if (fetching_edit === 2) {
            setShowModal(false)
            getProducts();
        }

    }, [fetching_edit])


    useEffect(() => {
        if (fetching_delete === 2) {
            getProducts();
        }
        if (fetching_delete === 3) {
            alert("No se puede Eliminar Este producto")
        }

    }, [fetching_delete])


    const onEdit = (row) => {
        setShowModal(true);
        setForm(row);
    }

    const onDelete = (row) => {
        if (window.confirm("¿Realmente Desea eliminar el producto?")) {
            deleteProduct(row);
        }
    }


    return <TemplateMenu>
        <Row>
            <Col>
                <Tabs defaultActiveKey="product" id="uncontrolled-tab-example">
                    <Tab eventKey="product" title="Productos">
                        <Container fluid>
                            <Row className="my-4">
                                <Col lg={3} md={4}>
                                    <h4 className="font-title pt-3">PRODUCTOS DE VENTA</h4>
                                </Col>
                            </Row>
                            {listCategory.map((d, key) => <Fragment key={key}><Row className='mt-4 border-bottom pt-4'>
                                <Col className='text-center'><h4>{d.name} <Link className='btn btn-sm btn-info' to={`/productos/new?category_id=${d.id}`}>Crear</Link></h4>
                                </Col>
                            </Row>
                                <Row className='border-top pb-1'>
                                    {d.products.map((product, k) => <Col lg={3} className="my-1" key={k}>
                                        <Card>
                                            <Card.Header>
                                                <Row>
                                                    <Col className='text-right'>
                                                        <Link to={`/productos/${product.id}`} className="btn btn-info btn-sm" >Editar</Link>
                                                        <button className='btn btn-danger btn-sm mx-2' onClick={() => onDelete(product)}>Eliminar</button>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row className='d-flex justify-content-center'>
                                                    <Col className='' lg={3} >
                                                        <img src={product.image} className="img-fluid" />
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col className='font-14 font-weight-bold'>{product.name}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className='text-muted font-12'>{product.description}</Col>
                                                        </Row>
                                                        <Row className='mt-1'>
                                                            <Col className='font-14 font-weight-bold'>{formatNumber(product.price)}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>)}

                                </Row>
                            </Fragment>)}
                        </Container>
                    </Tab>
                    <Tab eventKey="upload" title="Carga">
                        <UploadProductSegment />
                    </Tab>
                </Tabs>
            </Col>
        </Row>



        <Row>
        </Row>
    </TemplateMenu>
}

const mapsState = (props) => {
    let { product } = props

    return {
        data: product.data,
        fetching_list: product.fetching_list,
        fetching_edit: product.fetching_edit,
        fetching_delete: product.fetching_delete,
    }
}

export default connect(mapsState, { getProducts, createOrUpdateProduct, deleteProduct })(ProductPage);