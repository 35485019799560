import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getRoles } from '../../Redux/userDuck'
import ItemPermission from './item.permission';
import TemplateMenu from '../../template/menu.template'


const PermissionPage = ({ getRoles, fetching_rol, roles }) => {

    const [listRoles, setListRoles] = useState([])

    useEffect(() => {
        getRoles()
    }, [])


    useEffect(() => {
        if (fetching_rol === 2) {
            setListRoles(roles)
        }
    }, [fetching_rol])



    return (
        <TemplateMenu>
            <Container fluid>
                <Row>
                    <Col><h3>Listado De Permisos</h3></Col>
                </Row>
                <Row>
                    <Col>
                        <table className='table table-sm'>
                            <thead className="thead-dark">
                                <tr>
                                    <th>Titulo</th>
                                    <th>Estado</th>
                                    <th>Orden</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listRoles.map(p => <ItemPermission key={p.id} item={p} />)}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { user } = props
    return {
        roles: user.data_roles,
        fetching_rol: user.fetching_rol
    }
}

export default connect(mapsState, { getRoles })(PermissionPage);