import React from 'react'
import { formatNumber } from '../../utils';

const ItemReportByTurn = ({ item }) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.user}</td>
            <td>{item.category}</td>
            <td>{item.client}</td>
            <td>{item.plate}</td>
            <td>{item.method_payment}</td>
            <td align='right'>{formatNumber(item.amount)}</td>
        </tr>
    )
}

export default ItemReportByTurn;