import React, { Fragment } from 'react'
import { Row, Col, Container } from 'react-bootstrap';

const InputForm = ({ type, name, placeholder, value, error, onChange }) => {
    return (
        <Fragment>
            <Row className="w-100 p-0 m-0">
                <input type={type} name={name} value={value} placeholder={placeholder}
                    className={`w-100 py-1 my-1 ${error !== undefined ? 'border-naranja' : ''} d-inline-block`}
                    onChange={onChange}
                />
            </Row>
            {error !== undefined ? (<Row className="d-inline-block w-100 mx-0 text-center">
                <span className="text-naranja text-center ">{error}</span>
            </Row>) : null}

        </Fragment>
    )
}

export default InputForm;