import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../utils";

const ItemTableCounter = ({ row, onUpdate }) => {
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (row.amount !== null) {
      setAmount(row.amount);
    } else {
      setAmount("");
    }
  }, [row.amount]);

  return (
    <tr>
      <td>{row.product}</td>
      <td>{row.measure_unit}</td>
      <td>{row.control}</td>
      <td>
        <input
          type={"number"}
          className="form-control input-sm"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          onBlur={(e) => onUpdate(row, e.target.value)}
        />
      </td>
    </tr>
  );
};

export default ItemTableCounter;
