
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_create: 0,
    fetching_create_cylinder: 0,
    fetching_create_reference: 0,
    fetching_list_cylinder: 0,
    fetching_list_reference: 0,
    fetching_reference: 0,
    data: [],
    data_cylinder: [],
    data_reference: [],
    errors: {}
}


const CREATE_BRANDMODEL = "CREATE_BRANDMODEL"
const CREATE_BRANDMODEL_SUCCESS = "CREATE_BRANDMODEL_SUCCESS"
const CREATE_BRANDMODELYEAR = "CREATE_BRANDMODELYEAR"
const CREATE_BRANDMODELYEAR_SUCCESS = "CREATE_BRANDMODELYEAR_SUCCESS"
const CREATE_BRANDMODELCYLINDER = "CREATE_BRANDMODELCYLINDER"
const CREATE_BRANDMODELCYLINDER_SUCCESS = "CREATE_BRANDMODELCYLINDER_SUCCESS"
const LIST_BRANDMODEL = "LIST_BRANDMODEL"
const LIST_BRANDMODEL_SUCCESS = "LIST_BRANDMODEL_SUCCESS"
const LIST_CYLINDER = "LIST_CYLINDER"
const LIST_CYLINDER_SUCCESS = "LIST_CYLINDER_SUCCESS"
const CREATE_REFERENCE = "CREATE_REFERENCE"
const CREATE_REFERENCE_SUCCESS = "CREATE_REFERENCE_SUCCESS"
const LIST_REFERENCE = "LIST_REFERENCE"
const LIST_REFERENCE_SUCCESS = "LIST_REFERENCE_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_BRANDMODEL:
            return { ...state, fetching_create: 1 }
        case CREATE_BRANDMODEL_SUCCESS:
            return { ...state, fetching_create: 2 }
        case CREATE_BRANDMODELYEAR:
            return { ...state, fetching_create: 1 }
        case CREATE_BRANDMODELYEAR_SUCCESS:
            return { ...state, fetching_create: 2 }
        case CREATE_BRANDMODELCYLINDER:
            return { ...state, fetching_create_cylinder: 1 }
        case CREATE_BRANDMODELCYLINDER_SUCCESS:
            return { ...state, fetching_create_cylinder: 2 }
        case LIST_BRANDMODEL:
            return { ...state, fetching_list: 1 }
        case LIST_BRANDMODEL_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case LIST_CYLINDER:
            return { ...state, fetching_list_cylinder: 1 }
        case LIST_CYLINDER_SUCCESS:
            return { ...state, fetching_list_cylinder: 2, data_cylinder: action.payload }
        case LIST_REFERENCE:
            return { ...state, fetching_list_reference: 1 }
        case LIST_REFERENCE_SUCCESS:
            return { ...state, fetching_list_reference: 2, data_reference: action.payload }
        case CREATE_REFERENCE:
            return { ...state, fetching_create_reference: 1 }
        case CREATE_REFERENCE_SUCCESS:
            return { ...state, fetching_create_reference: 2 }
        default:
            return state;
    }
}

export let getListBrandModel = (brand_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_BRANDMODEL
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.get(`${API_URL}/api/brand-model?branch_id=${branch.id}&brand_id=${brand_id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_BRANDMODEL_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createBrandModel = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BRANDMODEL
    })

    if (getState().user.loggedIn) {

        Axios.post(`${API_URL}/api/brand-model`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_BRANDMODEL_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createBrandModelYear = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BRANDMODELYEAR
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.post(`${API_URL}/api/brand-model-year`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_BRANDMODELYEAR_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createBrandModelCylinder = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BRANDMODELCYLINDER
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.post(`${API_URL}/api/brand-model-cylinder`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_BRANDMODELCYLINDER_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}


export let getCylinderReference = (id) => (dispatch, getState) => {
    dispatch({
        type: LIST_REFERENCE
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.get(`${API_URL}/api/brand-model-reference/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_REFERENCE_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getBrandModelCylinder = (model_model_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_CYLINDER
    })

    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/brand-model-cylinder/${model_model_id}`

        console.log('url', url);

        Axios.get(url, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_CYLINDER_SUCCESS,
                payload: resp.data.results

            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}


export let createReference = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_REFERENCE
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.post(`${API_URL}/api/brand-model-reference`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_REFERENCE_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}