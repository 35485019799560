import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import './index.css'

const Alert=({alert})=>{
    let {message,fetching} = alert
    return (
        <Container fluid className={`fixed-bottom bg-alert ${fetching?'show-alert':''} `}>
            <Row className="my-2">
                <Col>{message}</Col>
            </Row>
        </Container>
    )
}

const mapsState=(props)=>{
    return {
        alert:props.alert
    }
}

export default connect(mapsState)(Alert);