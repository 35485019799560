import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { uploadData } from '../../Redux/productDuck'
import { connect } from 'react-redux';
const UploadProductSegment = ({ uploadData }) => {

    const [list, setList] = useState([])

    const updateField = (e) => {
        let content = e.target.value.split("\n")

        let rows = []

        content = content.map((d, i) => {
            rows.push(d.split("\t"))
        });

        setList(rows)

    }

    const uploadDataPre = () => {
        uploadData(list)
    }


    return (
        <Container fluid>
            <Row>
                <Col>Carga de Productos</Col>
            </Row>
            <Row>
                <Col lg={5}>
                    <textarea className='form-control' onChange={updateField} rows={5}></textarea>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <button className='btn btn-sm btn-info' onClick={uploadDataPre}>Subir</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className='table table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th></th>
                                        <th>Categoria</th>
                                        <th>Producto</th>
                                        <th>Descripcion</th>
                                        <th>Unidad de Medida</th>
                                        <th>Precio</th>
                                        <th>Costo</th>
                                        <th>Marca</th>
                                        <th>Referencia</th>
                                        <th>Aceite</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map((d, k) => <tr key={k}>
                                        <td>{k + 1}</td>
                                        <td>{d[0]}</td>
                                        <td>{d[1]}</td>
                                        <td>{d[2]}</td>
                                        <td>{d[3]}</td>
                                        <td>{d[4]}</td>
                                        <td>{d[5]}</td>
                                        <td>{d[6]}</td>
                                        <td>{d[7]}</td>
                                        <td>{d[8]}</td>
                                    </tr>)}

                                </tbody>
                            </table></Col>
                    </Row>

                </Col>
            </Row>
        </Container>


    )
}

const mapsState = (props) => {
    let { } = props
    return {

    }
}

export default connect(mapsState, { uploadData })(UploadProductSegment);