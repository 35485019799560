import React from 'react'

const ItemDetailService = ({ item }) => {
    let { service } = item
    return (
        <tr>
            <td>{service.description}</td>
            <td align='right'>{item.price_formated}</td>
        </tr>
    )
}

export default ItemDetailService;