
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    data: [],
    row: {},
    errors: {},
}


const DELETE_ALL = "DELETE_ALL"
const DELETE_ALL_SUCCESS = "DEFAULT_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export let deleteAllData = () => dispatch => {
    dispatch({
        type: DELETE_ALL
    })

    let url = `${API_URL}/api/admin-delete-data`

    Axios.delete(url, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: DELETE_ALL_SUCCESS
            })
        })
        .catch(error => {

        });


}