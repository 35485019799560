import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAllUser } from '../../Redux/userDuck'
import { formatNumber, getQuantityDays } from '../../utils';
import { onCreatePayroll } from '../../Redux/payrollDuck'
import moment from 'moment';
import { getParameterBySegment } from '../../Redux/parameterDuck'
import ModalPaymentPayroll from './modal.payment.payroll';
import { useToasts } from 'react-toast-notifications';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';

const CreaatePayrollPage = ({ getAllUser, fetching_list, data_users, onCreatePayroll, getParameterBySegment,
    fetching_segment, data_segment, fetching_create, fetching_active, row
}) => {

    const [listOperator, setListOperator] = useState([]);
    const [listMethod, setListMethod] = useState([]);
    const [modal, setModal] = useState(false);
    const [turnActive, setTurnActive] = useState({ user: { name: '' } });

    const initState = {
        salary: 0, worker_id: 0, discount: 0, supplies: 0, advances: 0,
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"),
        days: getQuantityDays(moment().format("YYYY-MM-01"), moment().format("YYYY-MM-DD")),
        extra_hour: 0,
    }

    const [form, setForm] = useState(initState)

    const [selected, setSelected] = useState({ id: 0 })
    const { addToast } = useToasts();

    useEffect(() => {
        if (fetching_active === 2) {
            setTurnActive(row)
        }
    }, [fetching_active])

    useEffect(() => {
        if (fetching_list === 2) {
            setListOperator(data_users)
        }
    }, [fetching_list])
    useEffect(() => {
        if (fetching_segment === 2) {
            setListMethod(data_segment)
        }
    }, [fetching_segment])
    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            setForm(initState)
            setSelected({ id: 0 })
            addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });
        }
    }, [fetching_create])

    useEffect(() => {
        getAllUser(true);
        getParameterBySegment("payment")
    }, [])

    const updateField = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.value
        }
        if (e.target.name === 'date_end') {
            let days = getQuantityDays(form.date_init, e.target.value)
            _form.days = days
            _form.salary = Math.round(((selected.salary / 30) * days))
        }

        setForm(_form)
    }
    const updateFieldWorker = (e) => {
        let _selected = listOperator.filter(d => d.id === parseInt(e.target.value))
        setSelected(_selected[0])

        let days = getQuantityDays(form.date_init, form.date_end)
        let _form = {
            ...form,
            [e.target.name]: e.target.value
        }

        _form.salary = Math.round((_selected[0].salary / 30) * days)
        setForm(_form)
    }

    const onSubmit = (method_payment_id) => {
        let _form = form
        _form.method_payment_id = method_payment_id;
        _form.cashier_id = turnActive.id;
        onCreatePayroll(form)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-3'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className='text-center'><h5>TURNO</h5></Col>
                            </Row>
                            <Row>
                                {turnActive !== undefined && <Col className='text-center'>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col><RiMoneyDollarCircleFill size={30} /></Col>
                                            </Row>
                                            <Row>
                                                <Col>Turno</Col>
                                                <Col>({turnActive.id}) - {turnActive.user.name}</Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col>Fecha de Apertura</Col>
                                                <Col>{turnActive.date_start}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>}

                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Liquidación de Trabajador</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Trabajadores</label>
                                        <select className='form-control' name='worker_id' value={form.worker_id} onChange={updateFieldWorker}>
                                            <option value={0}>Seleccione</option>
                                            {listOperator.map((d, k) => <option key={k} value={d.id}>{d.name} ({d.rol.title})</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            {selected.id !== 0 && <>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label>Fecha de Inicio</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={form.date_init}
                                                name="date_init"
                                                onChange={updateField}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label>Fecha Fin</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={form.date_end}
                                                name="date_end"
                                                onChange={updateField}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Sueldo
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(selected.salary)}
                                    </Col>
                                </Row>
                                <Row className='my-1 border-top'>
                                    <Col>
                                        Días Trabajados
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.days)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Salario
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.salary)}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col>
                                        Bonificación Extra Legal
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(selected.bonification)}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col>
                                        Horas Extras
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.extra_hour)}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        Descuentos
                                    </Col>
                                    <Col className='text-right'>
                                        <input type='number' className='form-control text-right' value={form.discount} name="discount"
                                            onChange={updateField} />
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col>
                                        Adelantos
                                    </Col>
                                    <Col className='text-right'>
                                        <input type='number' className='form-control text-right' value={form.advances} name="advances"
                                            onChange={updateField} />
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        Insumos
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.supplies)}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col className='border-top'>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        TOTAL
                                    </Col>
                                    <Col className='text-right font-weight-bold'>
                                        {formatNumber(parseFloat(form.salary) +
                                            parseFloat(selected.bonification) - parseFloat(form.supplies)
                                            - parseFloat(form.discount))}
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col className='text-center'>
                                        <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Guardar</button>
                                    </Col>
                                </Row>
                            </>}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ModalPaymentPayroll show={modal}
                turn_active={turnActive}
                onHide={() => setModal(false)} listMethod={listMethod} total={parseFloat(form.salary) +
                    parseFloat(selected.bonification) - parseFloat(form.supplies)
                    - parseFloat(form.discount)} onSubmit={onSubmit} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user, parameter, payroll, turn } = props

    return {
        fetching_list: user.fetching_list,
        data_users: user.list,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_create: payroll.fetching_create,
        fetching_active: turn.fetching_active,
        row: turn.row
    }
}
export default connect(mapsState, { getAllUser, onCreatePayroll, getParameterBySegment })(CreaatePayrollPage);