import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template'
import FormProductInventory from './form.product.inventory';
import { getProductInventoryById, createOrUpdateProduct } from '../../Redux/productInventoryDuck'

const EditProductInventoryPage = ({ getProductInventoryById, fetching_by_id, row, createOrUpdateProduct, fetching_edit }) => {
    let { id } = useParams();
    const [form, setForm] = useState([])
    const history = useHistory();

    useEffect(() => {
        getProductInventoryById(id)
    }, [id])


    useEffect(() => {
        if (fetching_edit === 2) {
            history.goBack()
        }
    }, [fetching_edit])
    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    const onSubmit = (form) => {
        createOrUpdateProduct(form)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={6}><FormProductInventory formEdit={form} onSubmit={onSubmit} /></Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product_inventory } = props

    return {
        fetching_by_id: product_inventory.fetching_by_id,
        fetching_edit: product_inventory.fetching_edit,
        row: product_inventory.row
    }
}

export default connect(mapsState, { getProductInventoryById, createOrUpdateProduct })(EditProductInventoryPage);