import React, { useState } from 'react'
import { formatNumber } from '../../utils';

const ItemtablePrescriptionRow = ({ item, onDeleteRow, onEditRow }) => {

    const [form, setForm] = useState({ quantity: '' })

    const [isEdit, setIsEdit] = useState(false)

    const onShowEdit = () => {
        setIsEdit(true)
        setForm(item)
    }
    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onEditRowPre = () => {
        onEditRow(form)
        setIsEdit(false)
    }

    return (
        <tr>
            <td>{item.product}</td>
            <td>{item.measure_unit}</td>
            <td align='right'>{formatNumber(item.cost_average)}</td>
            <td align='right'>
                {!isEdit ? formatNumber(item.quantity) :
                    <input className='form-control text-right' name='quantity' value={form.quantity} onChange={updateField} />}

            </td>
            <td align='right'>{formatNumber(item.cost_average * item.quantity)}</td>
            <td>
                {isEdit ? <button className='btn btn-sm btn-success mx-1' onClick={onEditRowPre}>Guardar</button> :
                    <button className='btn btn-sm btn-info mx-1' onClick={onShowEdit}>Editar</button>}

                <button className='btn btn-sm btn-danger' onClick={() => onDeleteRow(item)}>Borrar</button>
            </td>
        </tr>
    )
}

export default ItemtablePrescriptionRow;