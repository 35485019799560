
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_config: 0,
    fetching_messages: 0,
    fetching_regenerate: 0,
    data: [],
    row: {},
    errors: {},
}


const LIST_WHATSAPP_CONFIG = "LIST_WHATSAPP_CONFIG"
const LIST_WHATSAPP_CONFIG_SUCCESS = "LIST_WHATSAPP_CONFIG_SUCCESS"
const LIST_MESSAGES = "LIST_MESSAGES"
const LIST_MESSAGES_SUCCESS = "LIST_MESSAGES_SUCCESS"
const REGENERATE_TOKEN = "REGENERATE_TOKEN"
const REGENERATE_TOKEN_SUCCESS = "REGENERATE_TOKEN_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_WHATSAPP_CONFIG:
            return { ...state, fetching_list: 1, errors: {}, data: [], fetching_regenerate: 0 }
        case LIST_WHATSAPP_CONFIG_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case LIST_MESSAGES:
            return { ...state, fetching_list: 1, }
        case LIST_MESSAGES_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case REGENERATE_TOKEN:
            return { ...state, fetching_regenerate: 1 }
        case REGENERATE_TOKEN_SUCCESS:
            return { ...state, fetching_regenerate: 2 }
        default:
            return state;
    }
}

export let getListConfigWhatsApp = () => (dispatch, getState) => {
    dispatch({
        type: LIST_WHATSAPP_CONFIG
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.get(`${API_URL}/api/whatsapp-config?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            console.log('re.data', resp.data);

            dispatch({
                type: LIST_WHATSAPP_CONFIG_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }

}

export let getMessagesWhatsApp = () => (dispatch, getState) => {
    dispatch({
        type: LIST_MESSAGES
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.get(`${API_URL}/api/whatsapp?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_MESSAGES_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }

}

export let onRenerateToken = (row) => (dispatch, getState) => {
    dispatch({
        type: REGENERATE_TOKEN
    })

    if (getState().user.loggedIn) {

        Axios.put(`${API_URL}/api/whatsapp-regenerate/${row.id}`, row, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: REGENERATE_TOKEN_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }

}