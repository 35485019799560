import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';
import { saveRecurrentCost } from '../../Redux/expenseDuck'

const initialState = { type_execution: '', parameter_id: '', description: '', cost: '' };

const ModalExpenseRecurrent = ({ show, onHide, listExpense, listType, saveRecurrentCost, fetching_create, onSuccess }) => {
    const [form, setForm] = useState(initialState)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (fetching_create === 2) {
            setForm(initialState)
            onSuccess()
        }
    }, [fetching_create])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveExpense = () => {
        saveRecurrentCost(form)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Gasto Recurrente</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Ejecutar Cada</label>
                            <select className='form-control' name='type_execution' onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                {listType.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Concepto</label>
                            <select className='form-control' name='parameter_id' onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                {listExpense.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Descripción</label>
                            <InputField
                                type="text"
                                placeholder="Agrega una Descripción"
                                value={form.description || ''}
                                name="description"
                                onChange={updateInput}
                                error={errors.description}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Valor</label>
                            <InputField
                                type="number"
                                placeholder="Agrega un Valor"
                                value={form.cost || ''}
                                name="cost"
                                onChange={updateInput}
                                error={errors.cost}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onSaveExpense}>Agregar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

const mapsState = (props) => {
    let { expense } = props
    return {
        fetching_create: expense.fetching_create
    }
}

export default connect(mapsState, { saveRecurrentCost })(ModalExpenseRecurrent);