import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createBranch, updateBranch } from '../../Redux/branch.officeDuck'
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'

const ModalBranch = ({ showModal, onHide, errors, fetching, createBranch, updateBranch, onSuccess, formEdit, list_business, user,
    list_department }) => {
    const initState = { description: '', address: '', phone: '', priority: '', status: true, business_id: 0, department_id: 0, city_id: 0 };

    const [form, setForm] = useState({ name: '' })
    const [listCity, setListCity] = useState([])


    useEffect(() => {

        if (fetching === 2) {
            onHide()
            setForm(initState)
            onSuccess()
        }
    }, [fetching])

    useEffect(() => {

        if (Object.keys(formEdit).length > 0) {

            if (formEdit.department_id !== 0) {
                let department = list_department.filter(d => parseInt(d.id) === parseInt(formEdit.department_id))
                setListCity(department[0].cities)
            }

            setForm(formEdit);
        }
        else {
            if (fetching !== 3 && fetching !== 2) {
                setForm(initState)
            }
        }
    }, [formEdit])

    useEffect(() => {

    }, [])


    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'department_id') {
            let department = list_department.filter(d => parseInt(d.id) === parseInt(e.target.value))
            setListCity(department[0].cities)
        }
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {

        if (form.id !== undefined) {
            updateBranch(form)
        } else {
            createBranch(form)
        }
    }


    return (
        <Modal show={showModal} onHide={closeModalTmp}>
            <div className="modal-header border-bottom-custom">
                <BlueTitleBack title='Sucursales' />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    {/* <img src={close} className="size-icon-35" /> */}
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Descripción</label>
                                <InputField
                                    placeholder="Agrega una Descripción"
                                    value={form.description || ''}
                                    name="description"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row>

                        <Col>
                            <div className="form-group">
                                <label>Dirección</label>
                                <InputField
                                    placeholder="Agrega una dirección"
                                    value={form.address || ''}
                                    name="address"
                                    onChange={updateInput}
                                    error={errors.address}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Telefono</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega un número de celular"
                                    value={form.phone || ''}
                                    name="phone"
                                    onChange={updateInput}
                                    error={errors.phone}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Departamento</label>
                                <select className="form-control" name='department_id' value={form.department_id} onChange={updateInput}>
                                    <option value={0}>Selecciona</option>
                                    {list_department.map(b => <option key={b.id} value={b.id}>{b.description}</option>)}
                                </select>
                                {errors.department_id !== undefined ? (<label className="error-label">{errors.department_id}</label>) : null}
                            </div>
                        </Col>

                        <Col>
                            <div className="form-group">
                                <label>Ciudad</label>
                                <select className="form-control" name='city_id' value={form.city_id} onChange={updateInput}>
                                    <option value={0}>Selecciona</option>
                                    {listCity.map(b => <option key={b.id} value={b.id}>{b.description}</option>)}
                                </select>
                                {errors.city_id !== undefined ? (<label className="error-label">{errors.city_id}</label>) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Orden</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega un Orden"
                                    value={form.priority || ''}
                                    name="priority"
                                    onChange={updateInput}
                                    error={errors.priority}
                                />
                            </div>
                        </Col>
                        {user.role_id === 1 &&
                            <Col>
                                <div className="form-group">
                                    <label>Empresa</label>
                                    <select className="form-control" name='business_id' value={form.business_id} onChange={updateInput}>
                                        <option value={0}>Selecciona</option>
                                        {list_business.map(b => <option key={b.id} value={b.id}>{b.business}</option>)}
                                    </select>
                                </div>
                            </Col>
                        }
                    </Row>


                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status || false}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button onClick={onSubmit} className="btn btn-sm btn-success">Guardar</button>

                    </Col>
                    <Col>
                        <button onClick={closeModalTmp} className="btn btn-sm btn-danger">Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { branch_office, user } = props;


    return {
        errors: branch_office.errors,
        fetching: branch_office.fetching_create,
        errors: branch_office.errors,
        user: user.data
    }
}

export default connect(mapsState, { createBranch, updateBranch })(ModalBranch);