import React from 'react'

const ItemTableSupplier = ({ item, onEdit }) => {
    return (
        <tr>
            <td>{item.business}</td>
            <td>{item.business_name}</td>
            <td>{item.document}</td>
            <td>{item.address}</td>
            <td>{item.status_text}</td>
            <td>
                <button className='btn btn-sm btn-info' onClick={onEdit}>Editar</button>
            </td>
        </tr>
    )
}

export default ItemTableSupplier;