import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';

const ModalPermission = ({ show, onSuccess, selected, onHide }) => {

    const [form, setForm] = useState({ title: '', priority: 0 })


    useEffect(()=>{
        if(Object.keys(selected).length>0){
            setForm(selected)
        }
    },[selected])

    const updateInput = (e) => {
        setForm({
            ...form, [e.target.name]: e.target.value
        })
    }

    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>Permiso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Título</label>
                            <input type={"test"} className='form-control' name='title' value={form.title} onChange={updateInput} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Orden</label>
                            <input type={"number"} className='form-control' name='priority' value={form.priority} onChange={updateInput} />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid>
                    <Row>

                        <Col className='d-flex justify-content-center'>
                            <button className='btn btn-secondary' onClick={onHide}>Cerrar</button>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <button className='btn btn-info' onClick={() => onSuccess(form)}>Guardar</button>
                        </Col>
                    </Row>
                </Container>

            </Modal.Footer>
        </Modal>
    )
}

export default ModalPermission;