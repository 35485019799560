import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import RowTableClient from './row.table.client';
import { connect } from 'react-redux';
import { getAllBusiness, onRenoveteBusiness } from '../../Redux/businessDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { Row, Col, Container } from 'react-bootstrap';
import SearchSection from '../Components/SearchSection';
import ModalBusiness from './ModalBusiness';
import TemplateMenu from '../../template/menu.template'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const BusinessPage = ({ getAllBusiness, data, fetching, toogleAlert, current_page, last_page, fetching_search, onRenoveteBusiness }) => {

    const [list, setList] = useState([])
    const observer = useRef()
    const [status, setStatus] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [formEdit, setFormEdit] = useState()


    useEffect(() => {

        getAllBusiness()
        toogleAlert(true)
    }, [])


    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)

            setList(data)
        }
    }, [fetching])


    const showModalTmp = () => {
        setShowModal(true)
    }

    const filterContent = (txt) => {

    }

    const onCleanFilter = () => {
        getAllBusiness()
    }

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()

        // console.log('current_page',current_page)

        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && current_page <= last_page) {
                toogleAlert(true, "Cargando datos...")
                getAllBusiness(parseInt(current_page) + 1)

            }
        })

        console.log('node', node)
        if (node) observer.current.observe(node)

    }, [current_page, status])

    const onEdit = (row) => {
        setShowModal(true)
        setFormEdit(row);
    }

    const onSuccess = () => {

        getAllBusiness()
        toogleAlert(true)
    }

    const onRenovate = (row) => {
        if (window.confirm("Estas Seguro de Querer renovar?")) {
            onRenoveteBusiness(row)

        }
    }

    return (
        <TemplateMenu>
            <Row className="mb-3">
                <Col lg={6} className="pl-2">
                    <Link className='btn btn-sm btn-info' to={`/business-create`}>Crear Empresa</Link>
                </Col>
            </Row>

            <Row>
                <Col>
                    <table className="table table-sm table-hover table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Alias</th>
                                <th>Nombre Cliente</th>
                                <th>Correo Electrónico</th>
                                <th>No. Celular</th>
                                <th>Documento</th>
                                <th>Horario</th>
                                <th>Vence</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list !== undefined) ? list.map((row, i) => <RowTableClient
                                onRenovate={onRenovate}
                                onEdit={onEdit}
                                item={row}
                                index={i}
                                key={i}
                                refs={lastBookElementRef} />) : null}
                        </tbody>


                    </table>
                </Col>
            </Row>
            <ModalBusiness showModal={showModal} formEdit={formEdit} onHide={() => setShowModal(false)} onSuccess={() => onSuccess()} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { data, fetching_list, current_page, last_page, fetching_search } = props.business

    return {
        data: data,
        fetching: fetching_list,
        current_page,
        last_page,
        fetching_search
    }
}

export default connect(mapsState, { getAllBusiness, toogleAlert, onRenoveteBusiness })(BusinessPage);