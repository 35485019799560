import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getParking } from '../../Redux/parkingDuck'
import { syncSales } from '../../Redux/orderDuck'
import { connect } from 'react-redux';
import { getCategoryParking } from '../../Redux/categoryDuck'
import { storeFee, OnEditFee } from '../../Redux/parkingDuck'
import ItemCardParking from './item.card.parking';

const initState = { id: 0, category_price: {} }

const ParkingConfigPage = ({ getParking, fetching_list, data, getCategoryParking, storeFee, OnEditFee, fetching_fee }) => {

    const [listCategoryData, setListCategoryData] = useState([]);
    const [category, setCategory] = useState(initState);
    const [typePrice, setTypePrice] = useState(0);
    const [errorTypeFee, setErrorTypeFee] = useState('');
    const [errorTypePlan, setErrorTypePlan] = useState('');
    const [errorPrice, setErrorPrice] = useState('');
    const [errorPricePlan, setErrorPricePlan] = useState('');
    const [typePlan, setTypePlan] = useState(0);
    const [price, setPrice] = useState('');
    const [pricePlan, setPricePlan] = useState('');

    useEffect(() => {
        if (fetching_list === 2) {
            setTypePrice('')
            setCategory(initState)
            setListCategoryData(data)
        }
    }, [fetching_list, data])
    useEffect(() => {

        if (fetching_fee === 2) {
            getCategoryParking()
        }

    }, [fetching_fee])

    useEffect(() => {
        getCategoryParking()
    }, [])

    const editCategory = (item) => {

        if (item.category_plan !== null) {
            setTypePlan(item.category_plan.category_type)
            setPricePlan(item.category_plan.price)
        } else {
            setPricePlan('')
            setTypePlan(0)
        }

        if (item.category_price !== null) {
            setTypePrice(item.category_price.category_type)
            setPrice(item.category_price.price)
        } else {
            setPrice('')
            setTypePrice(0)
        }

        setCategory(item)
    }

    const onSaveFee = () => {

        if (parseInt(typePrice) === 0) {
            setErrorTypeFee("Dato Requerido")
            return;
        }

        if (price === '' || parseInt(price) === 0) {
            setErrorTypeFee('')
            setErrorPrice("Dato Requerido")
            return;
        }
        setErrorTypeFee("")

        let _category = category
        _category.price = price
        _category.new_price = price

        _category.category_type = typePrice
        _category.price_type = true

        if (category.category_price === null) {
            storeFee(_category)
        } else {
            OnEditFee(_category)
        }
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='my-4'>
                    <Col lg={4}><h3>Tarifas Parqueadero</h3></Col>
                </Row>
                <Row>
                    {listCategoryData.map((row, k) => <ItemCardParking item={row} key={k} />)}
                </Row>

            </Container>
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { parking, category } = props;

    return {
        fetching_list: category.fetching,
        data: category.data,
        fetching_fee: parking.fetching_fee,
        fetching_plan: parking.fetching_plan

    }
}

export default connect(mapsState, { getParking, syncSales, getCategoryParking, storeFee, OnEditFee })(ParkingConfigPage);