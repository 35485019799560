import React, { useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import ItemClient from './item.client';


const FormPublicity = ({ errors, list_clients, onSuccess }) => {
    const [form, setForm] = useState({ message: '', image: '', client: 'CLIENTE PRUEBA' })
    const [preview, setPreview] = useState('Aqui parecera el mensaje como sera enviado')
    const [image, setImage] = useState('')

    const updateInput = (e) => {

        let message = ''

        if (e.target.name === 'client') {
            message = form.message;
        } else {
            message = e.target.value;
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        message = message.replaceAll(":cliente", form.client)
        setPreview(message)
    }

    const updateInputFile = (e) => {
        setImage(e.target.files[0])

        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, image: reader.result })
            }
        }
        reader.readAsDataURL(file)
    }

    const onSendMessage = () => {
        let _list = list_clients.filter(d => d.checked === true);
        let _form = form
        _form.clients = _list;
        onSuccess(_form)
    }


    return (
        <>
            <Row className='d-flex justify-content-center'>
                <Col lg={9}>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Contenido</label>
                                        <textarea
                                            className='form-control'
                                            placeholder="Contenido"
                                            value={form.message || ''}
                                            name="message"
                                            onChange={updateInput}
                                            rows="5"
                                        >
                                        </textarea>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Imagen</label>
                                        <InputField
                                            type={"file"}
                                            className='form-control'
                                            placeholder="Contenido"
                                            onChange={updateInputFile}
                                            rows="5">
                                        </InputField>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Cliente Ejemplo</label>
                                        <InputField
                                            className='form-control'
                                            placeholder="Contenido"
                                            name={"client"}
                                            value={form.client}
                                            onChange={updateInput}
                                        >
                                        </InputField>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button className='btn btn-sm btn-success' onClick={onSendMessage}>Enviar</button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={5}>
                            <Row>
                                <Col><b>Resultado</b></Col>
                            </Row>
                            <pre>{preview}</pre>
                            {image !== '' && <Row className='d-flex justify-content-center'>
                                <Col lg={8}>
                                    <img src={form.image} className="img-fluid" />
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Col>
                <Col lg={3}>
                    <Row>
                        <Col>Listado de Clientes</Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup>
                                {list_clients.map((c, k) => <ItemClient row={c} key={k} />)}
                            </ListGroup>

                        </Col>
                    </Row>
                </Col>


            </Row>
            <Row className='d-flex justify-content-center my-3'>
                <Col lg={10}><b>NOTA:</b> Para agregar el nombre de cliente se debe colocar <b>:cliente</b>, en el ejemplo el Nombre será <b>CLIENTE PRUEBA</b></Col>
            </Row>
        </>
    )
}

export default FormPublicity;