import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getParking } from '../../Redux/parkingDuck'
import { syncSales } from '../../Redux/orderDuck'
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import moment from 'moment';

const ParkingPage = ({ getParking, fetching_list, data }) => {

    const [list, setList] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [subtotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (fetching_list === 2) {
            console.log('data', data);

            setList(data)
            // let _quantity = data.reduce((a, b) => a + parseFloat(b.amount), 0)
            // let _subtotal = data.reduce((a, b) => a + parseFloat(b.price), 0)
            let _total = data.reduce((a, b) => a + parseFloat(b.total), 0)
            // setQuantity(_quantity)
            // setSubTotal(_subtotal);
            setTotal(_total)
            // console.log('_data', data);

        }
    }, [fetching_list])

    useEffect(() => {
        getParking()
    }, [])

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col lg={4}><h3>Historico Parqueadero</h3></Col>

            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered table-condensed table-hover'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Placa</th>
                                <th>Categoria</th>
                                <th>Estado</th>
                                <th className='text-center'>Fecha Ingreso</th>
                                <th className='text-center'>Fecha Salida</th>
                                <th className='text-center'>SubTotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>
                                <td>{d.plate}</td>
                                <td>{d.category.title}</td>
                                <td>{d.status}</td>
                                <td align='right'>{d.created_at_formated}</td>
                                <td align='right'>{d.status_id === 1 ? '' : d.finish_at_formated}</td>
                                <td align='right'>{formatNumber(d.total)}</td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={5}><b>TOTAL</b></td>

                                <td align='right'><b>{formatNumber(total)}</b></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { parking } = props;

    return {
        fetching_list: parking.fetching_list,
        data: parking.data,
    }
}

export default connect(mapsState, { getParking, syncSales })(ParkingPage);