import React, { useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormBusiness from './form.business';

const CreateBusinessPage = ({ errors }) => {


    return (
        <TemplateMenu>
            <Row className='py-3'>
                <Col>Crear Empresa</Col>
            </Row>
            <FormBusiness errors={errors} />
        </TemplateMenu>
    )
}

const mapState = (props) => {
    return {
        errors: {}
    }
}

export default connect(mapState, {})(CreateBusinessPage);