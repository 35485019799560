import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import AsyncSelect from 'react-select/async';
import { formatNumber, getHeaders } from '../../utils';
import { getCategories } from '../../Redux/categoryDuck'
import { getServicesByCategory } from '../../Redux/serviceDuck'
import { getOperators } from '../../Redux/userDuck'
import moment from 'moment';
import { connect } from 'react-redux';
import ModalOperators from './modal.operators';
import ModalServices from './modal.services';
import ModalProducts from './modal.products';
import AsyncCreatableSelect from 'react-select/async-creatable';
import ModalClient from './modal.client';
import { onCreateOrder } from '../../Redux/orderDuck'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const API_URL = process.env.REACT_APP_API_URL


const CreateOrderPage = ({ getCategories, fetching_list, data_category, getOperators, fetching_operator, data_operator, getServicesByCategory,
    fetching_list_service, data_service, turn_active, fetching_active, onCreateOrder, fetching_create }) => {

    const [listSearch, setListSearch] = useState([])
    const [searchPlate, setSearchPlate] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [listOperator, setListOperator] = useState([])
    const [listOperatorSelected, setListOperatorSelected] = useState([])
    const [listServices, setListServices] = useState([])
    const [listServicesSelected, setListServicesSelected] = useState([])
    const [productSearch, setProductSearch] = useState('')
    const [modalOperator, setModalOperator] = useState(false)
    const [modalServices, setModalServices] = useState(false)
    const [modalProduct, setModalProduct] = useState(false)
    const [modalClient, setModalClient] = useState(false)
    const [plate, setPlate] = useState('')
    const [client, setClient] = useState('')
    const [category, setCategory] = useState('')
    const [limitDate, setLimitDate] = useState({ max: '', min: '' });
    const [form, setForm] = useState({ registration_date: '', category_id: 0, client_id: 0, observations: '', notification: true, status_id: 1 })

    const [turn, setTurn] = useState({ user: { name: '' } })

    const history = useHistory()

    useEffect(() => {
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
        setLimitDate({
            ...limitDate,
            ["min"]: date_init,
            ["max"]: date_end,
        })

        getCategories()
        getOperators()
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_active === 2) {
            setTurn(turn_active)
        }
    }, [fetching_active])


    useEffect(() => {
        if (fetching_list_service === 2) {
            setListServices(data_service)
            setModalServices(true)
        }
    }, [fetching_list_service])

    useEffect(() => {
        if (fetching_list === 2) {
            setListCategory(data_category)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator)
        }
    }, [fetching_operator])


    const loadSearchPlate = async (inputValue) => {
        let url = `${API_URL}/api/plate/${inputValue}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setSearchPlate(_search.results)

        return _search.results;
    }

    const loadSearchClient = async (inputValue) => {
        let url = `${API_URL}/api/clients?search=${inputValue}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search.results)

        return _search.results;
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onDeleteOperator = (e) => {

        let _list = listOperatorSelected.filter(d => parseInt(d.id) !== parseInt(e.id))

        setListOperatorSelected(_list)
    }

    const onDeleteService = (e) => {

        let _list = listServicesSelected.filter(d => parseInt(d.id) !== parseInt(e.id))

        setListServicesSelected(_list)
    }

    const onAddOperator = (operator) => {
        setListOperatorSelected([
            ...listOperatorSelected,
            operator
        ])
        setModalOperator(false)
    }

    const openModalService = () => {

        if (form.category_id === 0 || form.client_id === 0) {
            alert("Por favor Selecciona Categoria y Cliente")
            return
        }

        getServicesByCategory(form.category_id, form.client_id)
    }

    const onAddService = (operator) => {
        setListServicesSelected([
            ...listServicesSelected,
            operator
        ])

        setModalServices(false)
    }

    const onEditService = (e) => {

    }

    const onCreateOrderForm = () => {
        let _form = form
        _form.plate = plate.plate;
        _form.operators = listOperatorSelected;
        _form.services = listServicesSelected;
        _form.products = [];

        console.log('_form', _form);
        onCreateOrder(_form)
    }

    const onSuccessCreateClient = (_client) => {
        console.log('_client', _client);
        _client.label = `${_client.name} (${_client.phone})`
        _client.value = _client.id

        setClient(_client)
        setForm({
            ...form,
            ["client_id"]: _client.id
        })
        setModalClient(false)
    }

    const updateFieldCheck = (e) => {
        setForm({
            ...form,
            notification: e.target.checked
        })
    }

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col lg={2}>Creacion de Orden</Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={3}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>Turno Actual</Col>
                                <Col>{turn.user.name}</Col>
                            </Row>
                            <Row>
                                <Col>Fecha Inicio</Col>
                                <Col>{turn.date_start}</Col>
                            </Row>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col lg={4}>
                    <Row>
                        <Col><h3>Paso 1</h3></Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Placa</label>
                                <AsyncCreatableSelect
                                    value={plate}
                                    loadOptions={loadSearchPlate}
                                    isClearable
                                    onChange={(e) => {
                                        if (e !== null) {
                                            let { category, client } = e
                                            setPlate(e);

                                            if (client != null) {
                                                let _client = client
                                                _client.label = `${client.name} (${client.phone})`

                                                setClient(client)
                                                setForm({
                                                    ...form,
                                                    ["category_id"]: category.id,
                                                    ["client_id"]: client.id
                                                });
                                            }


                                        } else {
                                            setPlate('')
                                            setClient('')
                                            setForm({
                                                ...form,
                                                ["category_id"]: 0,
                                                ["client_id"]: 0,
                                            })
                                        }
                                    }}
                                    placeholder="Busca el Placa"
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={listSearch.length === 0 ? 9 : 12}>
                            <div className="form-group">
                                <label>Cliente</label>
                                <AsyncSelect
                                    value={client}
                                    loadOptions={loadSearchClient}
                                    onInputChange={(e) => {
                                        console.log('e', e);
                                    }}
                                    onChange={(e) => {
                                        console.log('e', e);
                                        setClient(e)
                                        setForm({
                                            ...form,
                                            ["client_id"]: e.value
                                        })
                                    }}
                                    placeholder="Busca el Cliente"
                                />
                            </div>
                        </Col>
                        <Col lg={listSearch.length === 0 ? 0 : 3} className={`${listSearch.length === 0 ? 'd-none' : ''} d-flex align-items-center`} >
                            <button className='btn btn-info btn-sm' onClick={() => setModalClient(true)}>Crear Cliente</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Categoria</label>
                                <select className='form-control' name='category_id' value={form.category_id} onChange={updateField}>
                                    <option value={0}>Seleccione</option>
                                    {listCategory.map((d, k) => <option key={k} value={d.id}>{d.title}</option>)}
                                </select>
                            </div>

                        </Col>
                    </Row>

                </Col>
                <Col>
                    <Row>
                        <Col><h3>Paso 2</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row className='my-2'>
                                <Col>
                                    <button className='btn btn-sm btn-info' onClick={() => setModalOperator(true)}>Agregar Operadores</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Table className='table-sm table-hover'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Operador</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listOperatorSelected.length > 0 ? listOperatorSelected.map((d, k) => <tr key={k}>
                                                    <td>{d.name}</td>
                                                    <td>
                                                        <button className='btn btn-sm btn-danger' onClick={() => onDeleteOperator(d)}>X</button>
                                                    </td>
                                                </tr>) : <tr><td colSpan={2}>No hay Operadores</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row className='my-2'>
                                <Col>
                                    <button className='btn btn-sm btn-info' onClick={openModalService}>Agregar Servicios</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Table className='table-sm table-bordered table-hover'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Servicio</th>
                                                <th>Subtotal</th>
                                                <th>Descuento</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listServicesSelected.length > 0 ? listServicesSelected.map((d, k) => <tr key={k}>
                                                    <td>{d.service}</td>
                                                    <td>{formatNumber(d.price)}</td>
                                                    <td>{formatNumber(0)}</td>
                                                    <td>{formatNumber(d.price)}</td>
                                                    <td>
                                                        <button className='btn btn-sm btn-info mx-1' onClick={() => onEditService(d)}>Editar</button>
                                                        <button className='btn btn-sm btn-danger' onClick={() => onDeleteService(d)}>X</button>
                                                    </td>
                                                </tr>) : <tr><td colSpan={2}>No hay Servicios</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className='my-2'>
                        <Col><h3>Paso 3</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Enviar Noticación</label>
                                <input type='checkbox' className='form-control' name='notification' checked={form.notification} onChange={updateFieldCheck} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Observaciones</label>
                                <input className='form-control' name='observations' value={form.observations} onChange={updateField} placeholder='Observaciones' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <button className='btn btn-success btn-sm' onClick={onCreateOrderForm}>Crear Orden</button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalOperators show={modalOperator} onHide={() => setModalOperator(false)} listOperator={listOperator} onClick={onAddOperator} />
            <ModalServices show={modalServices} onHide={() => setModalServices(false)} listServices={listServices} onClick={onAddService} />
            <ModalProducts show={modalProduct} onHide={() => setModalProduct(false)} />
            <ModalClient show={modalClient} onSuccess={onSuccessCreateClient} onHide={() => setModalClient(false)} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { category, user, service, turn, order } = props

    return {
        fetching_list: category.fetching,
        data_category: category.data,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
        fetching_list_service: service.fetching_list,
        data_service: service.data,
        fetching_active: turn.fetching_active,
        turn_active: turn.row,
        fetching_create: order.fetching_create,
    }
}

export default connect(mapsState, { getCategories, getOperators, getServicesByCategory, onCreateOrder })(CreateOrderPage);