
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    data: [],
    errors: {}
}

const LIST_DETAILING = "LIST_DETAILING"
const LIST_DETAILING_SUCCESS = "LIST_DETAILING_SUCCESS"
const CREATE_DETAILING = "CREATE_DETAILING"
const CREATE_DETAILING_SUCCESS = "CREATE_DETAILING_SUCCESS"
const CREATE_DETAILING_ERROR = "CREATE_DETAILING_ERROR"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_DETAILING:
            return { ...state, fetching_list: 1 }
        case LIST_DETAILING_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_DETAILING:
            return { ...state, fetching_create: 1 }
        case CREATE_DETAILING_SUCCESS:
            return { ...state, fetching_create: 2, errors: {} }
        case CREATE_DETAILING_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        default:
            return state;
    }
}

export let getDetailing = () => (dispatch, getState) => {
    dispatch({
        type: LIST_DETAILING
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"));

        fetch(`${API_URL}/api/detailing?branch_id=${branch.id}&type=0`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {


            dispatch({
                type: LIST_DETAILING_SUCCESS,
                payload: resp.results
            });

        })
    } else {
        console.log("no Ingreso");
    }
}


export let onCreateDetailing = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_DETAILING
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"));

        form.branch_id = branch.id

        Axios.post(`${API_URL}/api/detailing`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_DETAILING_SUCCESS
            });
        }).catch(err => {
            console.log('err', err.response.data);
            dispatch({
                type: CREATE_DETAILING_ERROR,
                payload: err.response.data.errors
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onEditDetailing = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_DETAILING
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"));

        form.branch_id = branch.id

        Axios.put(`${API_URL}/api/detailing/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_DETAILING_SUCCESS
            });
        }).catch(err => {
            console.log('err', err);

        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteDetailing = (id) => (dispatch, getState) => {
    dispatch({
        type: CREATE_DETAILING
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"));

        fetch(`${API_URL}/api/detailing/${id}`, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: CREATE_DETAILING_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}