import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Table } from 'react-bootstrap';
import ModalParameter from './ModalParameter';
import { getParameter, setParameter, closeModal, openModal } from '../../Redux/parameterDuck'
import TemplateMenu from '../../template/menu.template'


const Parameters = ({ showModal, getParameter, setParameter, closeModal, openModal, data }) => {

    useEffect(() => {
        getParameter()
    }, [])

    useEffect(() => {

    }, [data])


    const showModalTmp = () => {
        openModal()
    }

    const onHide = () => {
        closeModal()
        //console.log("closwe")
    }

    const edit = (item) => {
        console.log("item", item)
        setParameter(item)
    }

    const deleteItem = (item) => {
        console.log("item", item)
    }

    const printDetail = (item, i) => {
        return (
            <tr key={i}>
                <td>{item.description}</td>
                <td>{item.segment}</td>
                <td>{item.status_id}</td>
                <td>
                    <button className="btn btn-info mr-3 btn-sm" onClick={() => edit(item)}>Editar</button>
                    <button className="btn btn-danger btn-sm" onClick={() => deleteItem(item)}>Inactivar</button>
                </td>
            </tr>
        )
    }

    return (
        <TemplateMenu>
            <Row className="my-4 justify-content-center">
                <Col lg={2}>
                    <h4>Parametros</h4>
                </Col>
                <Col lg={2}>
                    <button className="btn btn-success" onClick={showModalTmp}>Crear</button>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={4}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th>Segmento</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(data.length > 0) ? data.length > 0 ? data.map(printDetail) : null : <tr><td colSpan="5" align="center">No tienes Registros</td></tr>}
                        </tbody>
                    </Table>

                </Col>
            </Row>
            <ModalParameter showModal={showModal} onHide={onHide} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    console.log("props", props.parameter.modal)
    return {
        data: props.parameter.data,
        showModal: props.parameter.modal,
    }
}

export default connect(mapsState, { getParameter, setParameter, closeModal, openModal })(Parameters);