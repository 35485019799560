import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { onCreatePrescription } from '../../Redux/prescriptionDuck'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormPrescription from './form.prescription';



const CreatePrescriptionPage = ({ onCreatePrescription, fetching_create }) => {

    const history = useHistory();


    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack()
        }
    }, [fetching_create])



    return (
        <TemplateMenu>

            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={8}>
                    <FormPrescription onCreate={onCreatePrescription} />
                </Col>
            </Row>



        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { prescription } = props

    return {
        fetching_create: prescription.fetching_create,
    }
}

export default connect(mapsState, { onCreatePrescription })(CreatePrescriptionPage);