import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import AsyncSelect from "react-select/async";
import uuid from 'react-uuid';
import { formatNumber, getHeaders } from '../../utils';
import ItemtablePrescriptionRow from './item.table.prescription.row';
import RowAddProduct from './row.add.product';

const API_URL = process.env.REACT_APP_API_URL;


const FormPrescription = ({ formEdit, onCreate, onEdit }) => {
    const [form, setForm] = useState({ id: 0 })
    const [product, setProduct] = useState('')
    const [listSearch, setListSearch] = useState([])
    const [listPrescription, setListPrescription] = useState([])
    const [showRow, setShowRow] = useState(false)

    useEffect(() => {
        if (formEdit !== undefined && Object.keys(formEdit).length > 0) {
            setProduct(formEdit)
            setListPrescription(formEdit.prescription_detail)
        }
    }, [formEdit])


    const onSubmit = () => {

        if (product === '') {
            alert("Por Favor Selecciona un Producto")
            return;
        }
        if (listPrescription.length === 0) {
            alert("Por Favor Agrega la Receta")
            return;
        }

        let _form = product
        _form.list_prescription = listPrescription;

        if (form.id === 0) {
            onCreate(_form)
        } else {
            _form.id = form.id
            onEdit(_form)
        }

    }

    const loadOptions = async (inputValue) => {
        let branch = JSON.parse(localStorage.getItem("branch"));
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

        let url = `${API_URL}/api/products-search/${inputValue}?branch=${branch.id}`;

        let _search = await fetch(url, {
            headers: getHeaders(),
        }).then((resp) => resp.json());

        return _search;
    }

    const onCreateRow = (form) => {

        form.uid = uuid();
        form.product = form.name
        form.measure_unit = form.measure_unit.description

        setListPrescription([
            ...listPrescription,
            form
        ])
        setShowRow(false)

    }

    const onDeleteRow = (item) => {
        let _list = listPrescription.filter(d => d.uid !== item.uid)
        setListPrescription(_list);
    }
    const onEditRow = (form) => {

        let _list = listPrescription.map(d => {
            if (form.uid === d.uid) {
                d.quantity = form.quantity;
            }
            return d
        })

        setListPrescription(_list)
        setShowRow(false)
    }

    return (
        <>
            <Row className='d-flex justify-content-center mt-4'>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>Nueva Receta</Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={8}>
                                    <AsyncSelect
                                        // isDisabled={isEdit}
                                        value={product}
                                        placeholder={`Busca el Producto`}
                                        loadOptions={loadOptions}
                                        // defaultOptions={defaultOptions}
                                        onChange={(e) => {
                                            setProduct(e)

                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Descripción</th>
                                                <th>Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Categoria</td>
                                                <td align='right'>{product.category}</td>
                                            </tr>
                                            <tr>
                                                <td>Precio</td>
                                                <td align='right'>{formatNumber(product.price || 0)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            <Row className='d-flex justify-content-center mt-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>PRODUCTO</th>
                                                <th>UNIDAD</th>
                                                <th>COSTO UNIDAD</th>
                                                <th>UNIDAD</th>
                                                <th>SUBTOTAL</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listPrescription.length === 0 ? <tr><td colSpan={6} align="center">No tienes Registros</td></tr>
                                                : listPrescription.map((d, k) => <ItemtablePrescriptionRow key={k} item={d} onDeleteRow={onDeleteRow} onEditRow={onEditRow} />)}
                                            {showRow && <RowAddProduct onCreateRow={onCreateRow} onCancel={() => setShowRow(false)} />}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={6}>
                                                    {!showRow && <button className='btn btn-sm btn-info' onClick={() => setShowRow(true)}>Agregar Producto</button>}

                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default FormPrescription;