import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getListBrandModel, createBrandModelYear } from '../../Redux/brandModelDuck';
import { connect } from 'react-redux';
import ModalCreateModel from './modal.create.model';
import ItemBrandModel from './item.brand.model';
import ModalYear from './modal.year';
import ModalCreateCylinder from './modal.create.cylinder';
import ModalSparePartModal from './modal.create.sparepart';


const BrandModelPage = ({ getListBrandModel, fetching_list, data, createBrandModelYear, fetching_create }) => {
    const [list, setList] = useState([])
    const [listBrand, setListBrand] = useState([])
    const [modal, setModal] = useState(false)
    const [modalYear, setModalYear] = useState(false)
    const [modalCylinder, setModalCylinder] = useState(false)
    const [modalSpare, setModalSpare] = useState(false)
    const [cylinder, setCylinder] = useState({})
    const [brandModel, setBrandModel] = useState({})
    const [brandModelYear, setBrandModelYear] = useState({})

    useEffect(() => {
        getListBrandModel()
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            getListBrandModel()
            setModalYear(false)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    const onAddModel = (brand) => {
        setBrandModel(brand)
        setModal(true)

    }

    const onSuccess = () => {
        setModal(false)
        getListBrandModel()
    }

    const onOpenModalYear = (model) => {
        setModalYear(true)
        setBrandModelYear(model)
    }

    const onAddCylinder = (year) => {
        setModalCylinder(true)
        setBrandModel(year)
    }

    const onSuccessCylinder = () => {
        setModalCylinder(false)
        getListBrandModel()
    }

    const onAddReference = (item) => {
        setCylinder(item)
        setModalSpare(true)
    }

    const onSuccessReference = () => {
        setModalSpare(false)

    }

    return (
        <TemplateMenu>
            <Row className='my-3 d-flex justify-content-center'>
                <Col><h2>Listado de Modelos</h2></Col>

            </Row>
            <Row className='d-flex justify-content-center'>
                <Col>
                    {list.map((d, k) => {
                        return <Fragment key={k}>
                            <Row className='border-bottom mt-3'>
                                <Col className='text-center'><h3>{d.title}</h3></Col>
                            </Row>
                            <Row>
                                {d.brands.map((item, k) => <ItemBrandModel key={k} item={item} onAddModel={onAddModel}
                                    onOpenModalYear={onOpenModalYear} onAddCylinder={onAddCylinder} onAddReference={onAddReference} />)}
                            </Row>
                        </Fragment>
                    })}
                </Col>
            </Row>
            <ModalYear show={modalYear} onHide={() => setModalYear(false)} brandModelYear={brandModelYear} onAddYear={createBrandModelYear} />
            <ModalCreateModel show={modal} brandModel={brandModel} onHide={() => setModal(false)} onSuccess={onSuccess} />
            <ModalCreateCylinder show={modalCylinder} onHide={() => setModalCylinder(false)} brandModel={brandModel} onSuccess={onSuccessCylinder} />
            <ModalSparePartModal show={modalSpare} onSuccess={onSuccessReference} cylinder={cylinder} />
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { brandModel } = props

    return {
        fetching_list: brandModel.fetching_list,
        data: brandModel.data,
        fetching_create: brandModel.fetching_create

    }
}

export default connect(mapsState, { getListBrandModel, createBrandModelYear })(BrandModelPage);