import axios from 'axios'
import { getHeaders } from '../utils';
import { logOutAction } from "./userDuck";

const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching: 0,
    data: [],
    vehicles: [],
    selected: {},
    errors: {},
    row: {},
    fetching_delete: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_vehicles: 0
}

const LIST_CLIENT = "LIST_CLIENT"
const LIST_CLIENT_SUCCESS = "LIST_CLIENT_SUCCESS"
const CREATE_CLIENT = "CREATE_CLIENT"
const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS"
const CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR"
const CLIENT_BY_ID = "CLIENT_BY_ID"
const CLIENT_BY_ID_SUCCESS = "CLIENT_BY_ID_SUCCESS"
const LIST_VEHICLES = "LIST_VEHICLES"
const LIST_VEHICLES_SUCCESS = "LIST_VEHICLES_SUCCESS"
const CREATE_VEHICLE = "CREATE_VEHICLE"
const CREATE_VEHICLE_SUCCESS = "CREATE_VEHICLE_SUCCESS"

export default function reducer(state = initialState, action) {
    let clients;
    switch (action.type) {
        case LIST_CLIENT:
            return { ...state, fetching_client: 1, fetching_by_id: 0, fetching_create: 0 }
        case LIST_CLIENT_SUCCESS:
            // clients = action.payload
            // clients = clients.concat(state.clients)
            return { ...state, fetching_client: 2, clients: action.payload, current_page: action.current_page, last_page: action.last_page }
        case CREATE_CLIENT:
            return { ...state, fetching_create: 1 }
        case CREATE_CLIENT_SUCCESS:
            return { ...state, fetching_create: 2, row: action.payload }
        case CREATE_CLIENT_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case CLIENT_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case CLIENT_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case LIST_VEHICLES:
            return { ...state, fetching_vehicles: 1 }
        case LIST_VEHICLES_SUCCESS:
            return { ...state, fetching_vehicles: 2, vehicles: action.payload }
        case CREATE_VEHICLE:
            return { ...state, fetching_vehicles: 1 }
        case CREATE_VEHICLE_SUCCESS:
            return { ...state, fetching_vehicles: 2 }
        default:
            return state;
    }
}

export let getAllClients = (page) => (dispatch, getState) => {
    dispatch({
        type: LIST_CLIENT
    })

    page = page || getState().user.current_page

    // axios.get(`${API_URL}/api/clients?page=${page}&limit=30`, {
    axios.get(`${API_URL}/api/clients`, {
        headers: getHeaders()
    })
        .then(response => {

            const { list } = getState().user

            let _data = list || []


            _data = _data.concat(response.data.results)

            dispatch({
                type: LIST_CLIENT_SUCCESS,
                payload: response.data.results,
                current_page: 0,

            })
        })
        .catch(error => { });
}

export let getClientsWhatsapp = (page) => (dispatch, getState) => {
    dispatch({
        type: LIST_CLIENT
    })

    page = page || getState().user.current_page

    // axios.get(`${API_URL}/api/clients?page=${page}&limit=30`, {
    axios.get(`${API_URL}/api/clients-whatsapp`, {
        headers: getHeaders()
    })
        .then(response => {
            const { list } = getState().user
            let _data = list || []
            _data = _data.concat(response.data.results)

            dispatch({
                type: LIST_CLIENT_SUCCESS,
                payload: response.data.results,
                current_page: 0,
            })
        })
        .catch(error => {
            console.log('error--', error);

        });
}


export let getClientById = (id) => (dispatch, getState) => {
    dispatch({
        type: CLIENT_BY_ID
    })


    axios.get(`${API_URL}/api/clients/${id}`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: CLIENT_BY_ID_SUCCESS,
                payload: response.data,

            })
        })
        .catch(error => { });
}

export let onEditClient = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CLIENT
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/clients/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_CLIENT_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_CLIENT_ERROR,
                payload: data.errors
            });
        });
    } else {
        console.log("no Ingreso");
    }
}


export let onAddVehicle = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_VEHICLE
    })

    if (getState().user.loggedIn) {

        axios.post(`${API_URL}/api/clients-vehicles`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_VEHICLE_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_CLIENT_ERROR,
                payload: data.errors
            });
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateClient = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CLIENT
    })

    if (getState().user.loggedIn) {
        axios.post(`${API_URL}/api/clients`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_CLIENT_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_CLIENT_ERROR,
                payload: data.errors
            });
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteVehicle = (id) => (dispatch, getState) => {
    dispatch({
        type: CREATE_VEHICLE
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/clients-vehicles/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_VEHICLE_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_CLIENT_ERROR,
                payload: data.errors
            });
        });
    } else {
        console.log("no Ingreso");
    }
}