import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import FormPublicity from './form.publicity';
import { getAllClients } from '../../Redux/clientDuck';
import { onCreatePublicity } from '../../Redux/publicityDuck';
import { validatePhone } from '../../utils';


const CreatePublicityPage = ({ getAllClients, clients, fetching_client, onCreatePublicity }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getAllClients()
    }, [])


    useEffect(() => {
        if (fetching_client === 2) {

            let _clients = clients.map(d => {
                d.validate = validatePhone(d.phone)
                return d;
            });

            setList(_clients)
        }

    }, [fetching_client])

    const onSuccess = (form) => {
        onCreatePublicity(form)
    }

    return (
        <TemplateMenu>
            <Row className="mt-3">
                <Col>
                    <FormPublicity errors={{}} list_clients={list} onSuccess={onSuccess} />
                </Col>
            </Row>
        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { client } = props
    return {
        fetching_client: client.fetching_client,
        clients: client.clients,
    }
}
export default connect(mapsState, { getAllClients, onCreatePublicity })(CreatePublicityPage);