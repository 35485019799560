import React from 'react'
import modify from '../../Images/modify.svg'
import { formatNumber } from '../../utils'
import { Link } from 'react-router-dom'

const ItemTableProduct = ({ item, onDelete }) => {
    let color = item.status === true ? '' : 'table-secondary'

    return (
        <tr className={`${color}`}>
            <td>{item.id}</td>
            <td>{item.priority}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{formatNumber(item.price)}</td>
            <td>{item.category}</td>
            <td>{item.measure_unit}</td>
            <td>{item.status_text}</td>
            <td className="text-center">
                <Link to={`/productos-inventory/${item.id}`} className="btn btn-info btn-sm" >Editar</Link>
                <button className='btn btn-danger btn-sm mx-2' onClick={() => onDelete(item)}>Eliminar</button>
            </td>
        </tr>
    )
}

export default ItemTableProduct;