import moment from 'moment';
import React from 'react'

const ItemVehicle = ({ row, onEdit }) => {
    return (
        <tr>
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td>{row.plate}</td>
            <td>{row.category.description}</td>
            <td>
                <button className='btn btn-sm btn-info' onClick={() => onEdit(row)}>Editar</button>
            </td>
        </tr>
    )
}

export default ItemVehicle;