import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onAssocCategory } from '../../Redux/categoryDuck'

const ModalCategoryAssoc = ({ showModal, onHide, onAssocCategory, listCategory, fetching_assoc, onSuccess }) => {

    const [form, setForm] = useState({ category_id: 0 })

    useEffect(() => {
        if (fetching_assoc === 2) {
            onSuccess()
        }
    }, [fetching_assoc])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        if (form.category_id !== 0) {
            onAssocCategory(form)
        } else {
            alert("Por favor agrega una categoria")
        }

    }



    return (
        <Modal centered show={showModal} onHide={closeModalTmp}>
            <Modal.Header closeButton>
                <Modal.Title>Asociar Categoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>

                    <Row>
                        <Col>

                            <div className="form-group">
                                <label>Titulo Categoría</label>
                                <select className='form-control' name='category_id' onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    {listCategory.map((d, k) => <option key={k} value={d.id}>{d.title}</option>)}
                                </select>
                            </div>

                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={closeModalTmp}>Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { category } = props
    return {
        fetching: category.fetching_create,
        fetching_assoc: category.fetching_assoc
    }
}

export default connect(mapsState, { onAssocCategory })(ModalCategoryAssoc);