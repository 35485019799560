import axios from 'axios'
import { getHeaders } from '../utils';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_segment: 0,
    data: [],
    data_segment: [],
    selected: {},
    errors: {},
    modal: false
}

let PARAMETER = "PARAMETER";
let PARAMETER_SUCCESS = "PARAMETER_SUCCESS";
let PARAMETER_ERROR = "PARAMETER_ERROR";
let SET_PARAMETER = "SET_PARAMETER";
let SET_PARAMETER_SUCCESS = "SET_PARAMETER_SUCCESS";
let PARAMETER_NEW = "PARAMETER_NEW";
let PARAMETER_NEW_SUCCESS = "PARAMETER_NEW_SUCCESS";
let CLOSE_MODAL = "CLOSE_MODAL";
let OPEN_MODAL = "OPEN_MODAL";
let PARAMETER_SEGMENT = "PARAMETER_SEGMENT";
let PARAMETER_SEGMENT_SUCCESS = "PARAMETER_SEGMENT_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PARAMETER:
            return { ...state, fetching: 1 }
        case PARAMETER_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case PARAMETER_ERROR:
            return { ...state, errors: action.payload, fetching: 3, selected: action.form }
        case SET_PARAMETER:
            return { ...state, fetching: 1 }
        case SET_PARAMETER_SUCCESS:
            return { ...state, fetching: 2, selected: action.payload, modal: true }
        case PARAMETER_NEW:
            return { ...state, fetching: 1 }
        case PARAMETER_NEW_SUCCESS:
            return { ...state, fetching: 3, data: action.payload }
        case CLOSE_MODAL:
            return { ...state, fetching: 1, selected: {}, modal: false }
        case OPEN_MODAL:
            return { ...state, fetching: 1, selected: {}, modal: true }
        case PARAMETER_SEGMENT:
            return { ...state, fetching_segment: 1 }
        case PARAMETER_SEGMENT_SUCCESS:
            return { ...state, fetching_segment: 2, data_segment: action.payload, segment: action.segment }
        default:
            return state;
    }
}

export let setParameter = (item) => (dispatch, getState) => {
    dispatch({
        type: SET_PARAMETER
    })
    dispatch({
        type: SET_PARAMETER_SUCCESS,
        payload: item
    })
}

export let closeModal = (item) => (dispatch, getState) => {
    dispatch({
        type: CLOSE_MODAL
    })
}
export let openModal = (item) => (dispatch, getState) => {
    dispatch({
        type: OPEN_MODAL
    })
}


export let getParameter = () => (dispatch, getState) => {
    dispatch({
        type: PARAMETER
    })

    console.log('llego');


    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/parameter`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PARAMETER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getParameterBySegment = (segment) => (dispatch, getState) => {
    dispatch({
        type: PARAMETER_SEGMENT
    })


    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/parameter/${segment}/segment`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PARAMETER_SEGMENT_SUCCESS,
                payload: resp.data.results,
                segment
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let createParameter = (form) => (dispatch, getState) => {

    console.log("createParameter", form)
    dispatch({
        type: PARAMETER_NEW
    })

    if (getState().user.loggedIn) {

        axios.post(`${API_URL}api/parameter`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PARAMETER_NEW_SUCCESS,
                payload: resp.data.results,
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: PARAMETER_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}



