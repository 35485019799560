import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import { getProductInventory, createOrUpdateProduct, updateProduct, deleteProductInventory } from '../../Redux/productInventoryDuck'
import './index.css'
import ItemTableProduct from './item.table.product.inventory';
import TemplateMenu from '../../template/menu.template'
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL

const ProductInventoryPage = ({ getProductInventory, fetching, data, createOrUpdateProduct, fetching_edit, deleteProductInventory, fetching_delete }) => {

    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState({});
    const [list, setList] = useState([])

    useEffect(() => {
        getProductInventory()
    }, [])

    useEffect(() => {
        if (fetching === 2) {
            setList(data)
        }

    }, [fetching])

    useEffect(() => {
        if (fetching_edit === 2) {
            setShowModal(false)
            getProductInventory();
        }

    }, [fetching_edit])


    useEffect(() => {
        if (fetching_delete === 2) {
            getProductInventory();
        }
        if (fetching_delete === 3) {
            alert("No se puede Eliminar Este producto")
        }

    }, [fetching_delete])


    const onEdit = (row) => {
        setShowModal(true);
        setForm(row);
    }

    const onDelete = (row) => {
        if (window.confirm("¿Realmente Desea eliminar el producto?")) {
            deleteProductInventory(row);
        }
    }


    return <TemplateMenu>
        <Row className="my-4">
            <Col lg={3} md={4}>
                <h4 className="font-title pt-3">PRODUCTOS DE INVENTARIO</h4>
            </Col>
            <Col lg={2} md={4}>
                <Link className='btn btn-sm btn-info' to={"/productos-inventory/new"}>Crear</Link>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>

                <Table className="table table-sm table-hover table-bordered">
                    <thead>
                        <tr className='thead-dark'>
                            <th>Id</th>
                            <th>Orden</th>
                            <th>Titulo</th>
                            <th>Descripción</th>
                            <th>Precio</th>
                            <th>Categoria</th>
                            <th>Unidad de Medida</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(list.length > 0) ? list.map((d, k) => <ItemTableProduct item={d} key={k}
                            onEdit={onEdit}
                            onDelete={onDelete}
                        />) : <tr ><td colSpan="8" align='center'>No se encontro información</td></tr>}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </TemplateMenu>
}

const mapsState = (props) => {
    let { product_inventory } = props

    return {
        data: product_inventory.data,
        fetching: product_inventory.fetching_list,
        fetching_edit: product_inventory.fetching_edit,
        fetching_delete: product_inventory.fetching_delete,
    }
}

export default connect(mapsState, { getProductInventory, createOrUpdateProduct, deleteProductInventory })(ProductInventoryPage);