import React from 'react'
import { Modal } from 'react-bootstrap';

const ModalCategoryGlobal = ({ show }) => {
    return (
        <Modal show={show}>
            <Modal.Body></Modal.Body>
        </Modal>
    )
}

export default ModalCategoryGlobal;