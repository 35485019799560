import axios from 'axios'
import { getHeaders } from '../utils';
import { logOutAction } from './userDuck';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    data: []
}

let ROL = "ROL";
let ROL_SUCCESS = "ROL_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ROL:
            return { ...state, fetching: 1 }
        case ROL_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        default:
            return state;
    }
}

export let getRols = () => (dispatch, getState) => {

    dispatch({
        type: ROL
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/rol`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: ROL_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}
