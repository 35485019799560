import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getListRating } from '../../Redux/orderDuck'
import { getTurns } from '../../Redux/turnDuck'


const RatingPage = ({ getListRating, fetching_rating, data_rating, getTurns, data_turn, fetching_list }) => {

    const [list, setList] = useState([])
    const [listTurn, setListTurn] = useState([])
    const [form, setForm] = useState({})

    useEffect(() => {
        getTurns()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            let turn_active = data_turn.filter(d => d.status_id === 1);

            if (turn_active.length > 0) {
                setForm({
                    ...form,
                    ["turn_id"]: turn_active[0].id
                })
                getListRating(turn_active[0].id)
            }
            setListTurn(data_turn)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_rating === 2) {
            setList(data_rating)
        }
    }, [fetching_rating])

    const updateField = () => {

    }

    return (
        <TemplateMenu>
            <Row>
                <Col lg={4}>
                    <div className="form-group">
                        <label>Turno</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>Seleccione</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.date_start} ({d.user.name}) {`-> ${d.status_text}`}</option>)}

                        </select>
                    </div>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Orden</th>
                                <th>Cliente</th>
                                <th>Placa</th>
                                <th>Puntaje</th>
                                <th>Comentario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map((d, k) => <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.client.name}</td>
                                <td>{d.plate}</td>
                                <td>{d.rating}</td>
                                <td>{d.observation_score}</td>
                            </tr>) :
                                <tr>
                                    <td align='center' colSpan={5}>No hay Datos</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <p>RatingPage</p>
        </TemplateMenu>

    )
}

const mapsState = (props) => {

    let { order, turn } = props

    return {
        fetching_rating: order.fetching_rating,
        data_rating: order.data_rating,
        fetching_list: turn.fetching_list,
        data_turn: turn.data,

    }
}

export default connect(mapsState, { getListRating, getTurns })(RatingPage);