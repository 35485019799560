import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { updateInventoryInitital } from '../../Redux/inventoryDuck'
import { getProductsInitial } from '../../Redux/productDuck'
import { connect } from 'react-redux';
import RowTableInitial from './row.table.initial';
import { useHistory } from 'react-router-dom';


const InitialInventoryPage = ({ getProductsInitial, fetching_initial, data, updateInventoryInitital }) => {

    const [list, setList] = useState([])
    const history = useHistory();

    useEffect(() => {
        if (fetching_initial === 2) {
            setList(data)
        }
    }, [fetching_initial])

    useEffect(() => {
        getProductsInitial()
    }, [])

    const onUpdateRow = (row) => {
        updateInventoryInitital(row)
    }

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col><h3 className='text-center'>Inventario Inicial</h3></Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    {list.map((d, key) => <Row className='mt-2'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{d.name}, Total Productos: ({d.products.length})</Card.Title>
                                    <Row className='d-flex justify-content-center mt-2'>

                                        <Col>
                                            <Table className='table-sm table-bordered table-hover'>
                                                <thead>
                                                    <tr className='thead-dark'>
                                                        <th>#</th>
                                                        <th width="50%">Producto</th>
                                                        <th width="10%">Medida</th>
                                                        <th>Cantidad</th>
                                                        <th>Costo</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(d.products.length > 0) ? d.products.map((row, k) => <RowTableInitial index={k + 1} key={k} item={row} onUpdateRow={onUpdateRow} />) :
                                                        <tr>
                                                            <td colSpan={5} align='center'>NO tienes Productos de Inventario </td></tr>}
                                                </tbody>
                                            </Table>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>)}
                </Col>
            </Row>


        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product } = props
    return {
        fetching_initial: product.fetching_initial,
        data: product.data,
    }
}

export default connect(mapsState, { getProductsInitial, updateInventoryInitital })(InitialInventoryPage);