import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import FormProductInventory from './form.product.inventory';
import { createOrUpdateProduct } from '../../Redux/productInventoryDuck'
import { useHistory } from 'react-router-dom';

const CreateProductInventoryPage = ({ createOrUpdateProduct, fetching_edit }) => {

    const history = useHistory();

    useEffect(() => {
        if (fetching_edit === 2) {
            history.goBack();
        }
    }, [fetching_edit])

    const onSubmit = (form) => {
        createOrUpdateProduct(form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col>Crear Producto</Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={6}><FormProductInventory onSubmit={onSubmit} /></Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product_inventory } = props


    return {
        fetching_edit: product_inventory.fetching_edit
    }
}

export default connect(mapsState, { createOrUpdateProduct })(CreateProductInventoryPage);