import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';


const ModalWhatsappConfig = ({ show, errors, formEdit }) => {
    const [form, setForm] = useState({ token: '' })

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit);
        }
    }, [formEdit])

    const updateInput = (e) => {

    }
    return (
        <Modal show={show}>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Token Facebook</label>
                            <InputField
                                type="text"
                                placeholder="Agrega un Valor"
                                value={form.token || ''}
                                name="token"
                                onChange={updateInput}
                                error={errors.value}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

const mapsState = (props) => {
    let { whatsapp } = props
    return {
        errors: whatsapp.errors
    }
}

export default connect(mapsState, {})(ModalWhatsappConfig);