import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemSupplier = ({ row, onDelete }) => {
    let { supplier, branch_office } = row

    return (
        <tr >
            <td>{row.id}</td>
            <td>{moment(row.registration_date).format("YYYY-MM-DD")}</td>
            <td>{supplier.business}</td>
            <td>{row.amount}</td>
            <td>{formatNumber(row.total)}</td>
            <td>
                <Link className='btn btn-sm btn-info' to={`/compras/${row.id}`}>Ver</Link>
                <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(row)}>Borrar</button>
            </td>
        </tr>
    )
}

export default ItemSupplier;