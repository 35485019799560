import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';

const ItemCotization = ({ row }) => {

    console.log('item', row);

    return (
        <tr>
            <td>{row.document}</td>
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td>{row.client}</td>
            <td>{row.category}</td>
            <td>{row.plate}</td>
            <td>{row.expiration_days}</td>
            <td align='right'>{formatNumber(row.total)}</td>
        </tr>
    )
}

export default ItemCotization;