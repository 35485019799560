import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { toogleAlert } from '../../Redux/alertDuck'
import { getInformationUser } from '../../Redux/userDuck'
import { connect } from 'react-redux'
import { Card, Col, Row } from 'react-bootstrap'

const ExpiredPlan = ({ toogleAlert, fetching, user, getInformationUser }) => {

    let [data, setData] = useState({ plan_business: { plan: { name: '' } } })

    useEffect(() => {
        console.log('user111', user);
        if (fetching === 2) {
            console.log('user111', user);

            setData(user)
        }
    }, [fetching])

    useEffect(() => {
        getInformationUser()
        toogleAlert(false)
    }, [])


    console.log('data', data);


    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-5'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Card.Title className='text-center'>Plan Vencido</Card.Title>
                            <Row>
                                <Col>Tipo Plan</Col>
                                <Col>{data.plan_business.plan.name}</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { user } = props

    console.log('user', user.data);

    return {
        fetching: user.fetching,
        user: user.data,
    }
}

export default connect(mapsState, { toogleAlert, getInformationUser })(ExpiredPlan);