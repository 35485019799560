
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    data: [],
    row: {},
    errors: {}
}


const LIST_PRESCRIPTION = "LIST_PRESCRIPTION"
const LIST_PRESCRIPTION_SUCCESS = "LIST_PRESCRIPTION_SUCCESS"
const CREATE_PRESCRIPTION = "CREATE_PRESCRIPTION"
const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS"
const PRESCRIPTION_BY_ID = "PRESCRIPTION_BY_ID";
const PRESCRIPTION_BY_ID_SUCCESS = "PRESCRIPTION_BY_ID_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PRESCRIPTION:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_PRESCRIPTION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_PRESCRIPTION:
            return { ...state, fetching_create: 1 }
        case CREATE_PRESCRIPTION_SUCCESS:
            return { ...state, fetching_create: 2 }
        case PRESCRIPTION_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case PRESCRIPTION_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        default:
            return state;
    }
}

export let getListPrescription = () => dispatch => {
    dispatch({
        type: LIST_PRESCRIPTION
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))


    fetch(`${API_URL}/api/prescription?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_PRESCRIPTION_SUCCESS,
            payload: resp.results
        });
    }).catch(function (error) {
        console.log(error);
    });
}
export let getPrescriptionById = (id) => dispatch => {
    dispatch({
        type: PRESCRIPTION_BY_ID
    })

    fetch(`${API_URL}/api/prescription/${id}`, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PRESCRIPTION_BY_ID_SUCCESS,
            payload: resp
        });
    }).catch(function (error) {
        console.log(error);
    });
}

export let onCreatePrescription = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PRESCRIPTION
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))


        form.branch_id = branch.id;
        form.date_init = date_init;

        Axios.post(`${API_URL}/api/prescription`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_PRESCRIPTION_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_PRESCRIPTION_SUCCESS,
                payload: data.errors
            });
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onEditPrescription = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PRESCRIPTION
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        form.branch_id = branch.id;
        form.date_init = date_init;

        Axios.post(`${API_URL}/api/prescription/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_PRESCRIPTION_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            let { status, data } = error.response;

            dispatch({
                type: CREATE_PRESCRIPTION_SUCCESS,
                payload: data.errors
            });
        });
    } else {
        console.log("no Ingreso");
    }
}