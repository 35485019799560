import React from 'react';
import modify from '../../Images/modify.svg'


const RowTableUser = ({ item, onEdit }) => {
  let department = item.department === null ? '' : item.department.description
  let city = item.city === null ? '' : item.city.description

  return (

    <tr>
      <td>{item.description}</td>
      <td>{item.address}</td>
      <td>{item.phone}</td>
      <td>{department}</td>
      <td>{city}</td>
      <td>{item.status_text}</td>
      <td className="text-center">
        <button className='btn btn-sm btn-info' onClick={() => onEdit(item)}>Editar</button>
      </td>
    </tr>

  )
}
export default RowTableUser;