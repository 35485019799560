import moment from 'moment';
import React, { useState } from 'react'
import { formatNumber } from '../../utils';

const ItemTableChangePercent = ({ d, percent }) => {


    return (
        <tr>
            <td>{moment(d.finish_at).format("YYYY-MM-DD")}</td>
            <td>{d.category}</td>
            <td>{d.service}</td>
            <td align='right'>{formatNumber(d.price_real)}</td>
            <td align='right'>{d.percent}%</td>
            <td align='right'>{formatNumber(d.price_to_paid_real)}</td>
            <td align='right'>{percent}%</td>
            <td align='right'>{formatNumber(d.price_real * (percent / 100))}</td>
        </tr>
    )

}

export default ItemTableChangePercent;