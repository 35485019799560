import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Table } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { getCategoriesClient } from '../../Redux/categoryDuck'
import ItemCategoryPrice from './ItemCategoryPrice';
import uuid from 'react-uuid';

const initState = { name: '', description: '', priority: '', status: true, type_product_id: 0 };
const initStateCategory = {
    id: 0, category_id: 0, service_id: 0, price: '', percent: '', price_night: '', percent_night: '',
    type_value: true, type_value_night: true
};

const ModalService = ({ showModal, onHide, errors, fetching, getCategoriesClient, categories, onCreate, onUpdate, formEdit, size }) => {

    const [form, setForm] = useState({ title: '', priority: size, code: '', status: true })
    const [formCategory, setFormCategory] = useState(initStateCategory)
    const [listCategory, setListCategory] = useState([])
    const [selected, setSelected] = useState({ id: 0 })
    const [errorsCategory, setErrorsCategory] = useState({})


    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit);
            setListCategory(formEdit.service_price);
        } else {
            initState.priority = size + 1;
            setForm(initState)
            setListCategory([])
        }
    }, [formEdit])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = (e) => {
        let _form = form;
        _form.categories = listCategory;

        if (form.id !== undefined) {
            onUpdate(_form)
        } else {
            onCreate(_form)
        }
    }

    const updateField = (e) => {
        if (e.target.type === 'checkbox') {
            setFormCategory({
                ...formCategory,
                [e.target.name]: e.target.checked
            })
        } else {
            setFormCategory({
                ...formCategory,
                [e.target.name]: e.target.value
            })
        }
    }

    const onEditRow = (item) => {

        let percent = item.percent;
        let percent_night = item.percent_night;

        if (item.type_value === false) {
            percent = item.price * (percent / 100)
        }

        if (item.type_value_night === false) {
            percent_night = item.price_night * (percent_night / 100)
        }

        setFormCategory({
            ...formCategory,
            ["category_id"]: item.category_id,
            ["price"]: item.price,
            ["price_night"]: item.price_night,
            ["percent"]: percent,
            ["percent_night"]: percent_night,
            ["type_value"]: item.type_value,
            ["type_value_night"]: item.type_value_night,
            ["id"]: item.id,
        })

        setSelected(item)
    }

    const onCancelRow = () => {
        setSelected({ id: 0 })
        setFormCategory(initStateCategory)
    }

    const onConfirmEditRow = () => {
        var regexPattern = new RegExp("true");
        var type_value = regexPattern.test(formCategory.type_value);
        var type_value_night = regexPattern.test(formCategory.type_value_night);
        let type_value_text = type_value === true ? 'Porcentaje' : 'Valor'
        let percent_night_value = formCategory.percent_night;
        let percent_value = formCategory.percent

        if (type_value !== true) {
            percent_value = ((formCategory.percent * 100) / formCategory.price)
        }
        if (type_value_night !== true) {
            percent_night_value = ((formCategory.percent_night * 100) / formCategory.price_night)
        }

        let _listCategory = []

        if (selected._id === undefined) {
            _listCategory = listCategory.map(d => {
                if (formCategory.id === d.id) {
                    d.price = formCategory.price
                    d.price_night = formCategory.price_night
                    d.percent = percent_value
                    d.percent_night = percent_night_value
                    d.type_value = type_value
                    d.type_value_text = type_value_text
                    d.type_value_night = formCategory.type_value_night
                }

                return d
            })
        } else {
            _listCategory = listCategory.map(d => {
                if (selected._id === d._id) {
                    d.price = formCategory.price
                    d.price_night = formCategory.price_night
                    d.percent = percent_value
                    d.percent_night = percent_night_value
                    d.type_value = type_value
                    d.type_value_text = type_value_text
                    d.type_value_night = formCategory.type_value_night
                }

                return d
            })
        }

        setListCategory(_listCategory)

        setSelected({ id: 0 })
        setFormCategory(initStateCategory)
    }

    const onAddRow = () => {
        let errors = {}
        if (formCategory.category_id === 0) {
            errors.category_id = "Campo requerido";
        }

        if (formCategory.price === '') {
            errors.price = "Precio requerido";
        }

        if (formCategory.price_night === '') {
            errors.price_night = "Precio requerido";
        }

        if (formCategory.percent === '') {
            errors.percent = "Porcentaje requerido";
        }

        if (formCategory.percent_night === '') {
            errors.percent_night = "Porcentaje requerido";
        }

        let validate = listCategory.filter(d => d.category_id === parseInt(formCategory.category_id))

        if (validate.length > 0) {
            setErrorsCategory({
                ...errorsCategory,
                ["category_id"]: "Categoria ya existe"
            })
        }

        if (Object.keys(errors).length > 0) {
            setErrorsCategory(errors)
            console.log('Con errores', errorsCategory);
            return;
        } else {
            setErrorsCategory({})

            let _listCategory = listCategory

            let service_id = form.id
            let price = formCategory.price
            let price_night = formCategory.price_night
            let percent = formCategory.percent
            let percent_night = formCategory.percent_night
            let type_value = formCategory.type_value
            let type_value_night = formCategory.type_value_night

            let type_value_text = formCategory.type_value === true ? "Porcentaje" : 'Valor'
            let type_value_night_text = formCategory.type_value_night === true ? "Porcentaje" : 'Valor'

            if (type_value != true) {
                percent = ((formCategory.percent * 100) / formCategory.price)
            }
            if (type_value_night != true) {
                percent_night = ((formCategory.percent_night * 100) / formCategory.price_night)
            }

            let category = categories.filter(d => parseInt(d.id) === parseInt(formCategory.category_id))[0]

            let row = {
                _id: uuid(), price, price_night, percent, percent_night, type_value, type_value_night, category, category_id: category.id,
                type_value_text, type_value_night_text, service_id
            }

            _listCategory.push(row);
            setListCategory(_listCategory)
            setFormCategory(initStateCategory)
        }
    }

    const onDeleteRow = (row) => {
        let _list = listCategory.filter(d => d._id !== row._id)
        setListCategory(_list)
    }

    const onDeleteServerRow = (row) => {
        let _list = listCategory.map(d => {
            if (d.id === row.id) {
                d.delete = true
            }
            return d;
        });

        setListCategory(_list)
    }

    return (
        <Modal centered show={showModal} onHide={closeModalTmp} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>SERVICIOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Nombre</label>
                                        <InputField
                                            placeholder="Nombre"
                                            value={form.name || ''}
                                            name="name"
                                            onChange={updateInput}
                                            error={errors.name}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Descripción</label>
                                        <InputField
                                            placeholder="Descripcion"
                                            value={form.description || ''}
                                            name="description"
                                            onChange={updateInput}
                                            error={errors.description}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <div className="form-group">
                                        <label>Orden</label>
                                        <InputField
                                            type="number"
                                            placeholder="Orden"
                                            value={form.priority}
                                            name="priority"
                                            onChange={updateInput}
                                            error={errors.priority}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Código</label>
                                        <InputField
                                            placeholder="code"
                                            value={form.code || ''}
                                            name="code"
                                            onChange={updateInput}
                                            error={errors.priority}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Descuento</label>
                                        <InputField
                                            type={"number"}
                                            placeholder="Descuento"
                                            value={form.discount || ''}
                                            name="discount"
                                            className="text-right form-control"
                                            onChange={updateInput}
                                            error={errors.discount}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Estado</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status}
                                            name="status"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label><b>Categorías</b></label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={5}>
                                            <div className="form-group">
                                                <label>Categoria</label>
                                                <select className='form-control' name='category_id' value={formCategory.category_id} onChange={updateField}>
                                                    <option value={0}>Seleccione</option>
                                                    {categories.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                                </select>
                                                {errorsCategory.category_id !== undefined ? (<label className="error-label">{errorsCategory.category_id}</label>) : null}

                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>DESCRIPCIÓN</th>
                                                        <th>DÍA</th>
                                                        <th>NOCHE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Precio</td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Precio"
                                                                value={formCategory.price || ''}
                                                                name="price"
                                                                onChange={updateField}
                                                                error={errorsCategory.price}
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Precio"
                                                                value={formCategory.price_night || ''}
                                                                name="price_night"
                                                                onChange={updateField}
                                                                error={errorsCategory.price_night}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Valor</td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Valor"
                                                                value={formCategory.percent || ''}
                                                                name="percent"
                                                                onChange={updateField}
                                                                error={errorsCategory.percent}
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Valor"
                                                                value={formCategory.percent_night || ''}
                                                                name="percent_night"
                                                                onChange={updateField}
                                                                error={errorsCategory.percent_night}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tipo</td>
                                                        <td>
                                                            <select className='form-control' name='type_value' value={formCategory.type_value} onChange={updateField}>
                                                                <option value={true}>Porcentaje</option>
                                                                <option value={false}>Valor</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className='form-control' name='type_value_night' value={formCategory.type_value_night}
                                                                onChange={updateField}>
                                                                <option value={true}>Porcentaje</option>
                                                                <option value={false}>Valor</option>
                                                            </select>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3 d-flex justify-content-center'>
                                        <Col lg={3}>
                                            {selected.id === 0 ?
                                                <button className='btn btn-info btn-sm' onClick={onAddRow}>Agregar</button> :
                                                <>
                                                    <button className='btn btn-success btn-sm' onClick={onConfirmEditRow}>Editar</button>
                                                    <button className='btn btn-danger mx-2 btn-sm' onClick={onCancelRow}>Cancelar</button>
                                                </>}
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th></th>
                                                        <th className='text-center' colSpan={3}>DIA</th>
                                                        <th className='text-center' colSpan={3}>NOCHE</th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Descripción</th>
                                                        <th>Precio</th>
                                                        <th>Valor</th>
                                                        <th>Tipo</th>
                                                        <th>Precio</th>
                                                        <th>Valor</th>
                                                        <th>Tipo</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listCategory.length === 0
                                                        ? <tr><td colSpan={4} className="text-center">No tienes Precios Asociados</td></tr>
                                                        : listCategory.filter(d => d.delete === undefined).map((row, key) => <ItemCategoryPrice
                                                            selected={row.id === selected.id}
                                                            row={row} key={key}
                                                            onEditRow={onEditRow}
                                                            onDeleteRow={onDeleteRow}
                                                            onDeleteServerRow={onDeleteServerRow}
                                                        />)}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={closeModalTmp}>Cerrar</button>

                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { product, category } = props
    return {
        errors: product.errors,
        selected: category.selected,
        categories: category.data,
        fetching: category.fetching
    }
}

export default connect(mapsState, { getCategoriesClient })(ModalService);