import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';
import ItemCardServiceSpecialModal from './item.card.service.special.modal';


const ModalServices = ({ show, onSuccess, onHide, listService, onAddService }) => {
    const [form, setForm] = useState({ registration_date: '' })
    const [error, setError] = useState({})


    const onSaveClient = () => {
    }

    const onDelete = () => {
    }

    return (
        <Modal show={show} onHide={onHide} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Servicios Actuales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <input className='form-control' placeholder='Filter' />
                    </Col>
                </Row>
                <Row>
                    {listService.map((d, k) => <ItemCardServiceSpecialModal item={d} key={k} onAddService={onAddService} />)}
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onSaveClient}>Editar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { client } = props

    return {

    }
}

export default connect(mapsState, {})(ModalServices);