import axios from 'axios'
import { removeSession, errorLoginServer, validateUser, getHeaders, validateInput } from '../utils'
import { client } from 'websocket'
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_email: 0,
    fetching_timer: 0,
    fetching_client: 0,
    fetching_search: 0,
    fetching_create: 0,
    fetching_dates: 0,
    fetching_operator: 0,
    fetching_admin: 0,
    fetching_message: 0,
    fetching_me: 0,
    fetching_update: 0,
    fetching_advance: 0,
    loggedIn: false,
    open: false,
    openNumber: false,
    selected: {},
    data: [],
    data_roles: [],
    data_operator: [],
    data_administraror: [],
    list: [],
    clients: [],
    data_dates: [],
    data_advance: [],
    errors: {},
    current_page: 1,
    last_page: 0
}


const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"
const USER = "USER"
const USER_SUCCESS = "USER_SUCCESS"
const LOGOUT = "LOGOUT"
const LOGIN_ERROR = "LOGIN_ERROR"
const RESTORE_SESSION = "RESTORE_SESSION"
const RESTORE_SESSION_SUCCESS = "RESTORE_SESSION_SUCCESS"
const RESTORE_SESSION_ERROR = "RESTORE_SESSION_ERROR"
const LIST_USER = "LIST_USER"
const LIST_USER_SUCCESS = "LIST_USER_SUCCESS"

const REGISTER = "REGISTER"
const REGISTER_SUCCESS = "REGISTER_SUCCESS"
const REGISTER_ERROR = "REGISTER_ERROR"
const USER_SELECTED = "USER_SELECTED"
const USER_CLEAR = "USER_CLEAR"
const UPDATE_USER = "UPDATE_USER"
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
const EMAIL = "EMAIL"
const EMAIL_SUCCESS = "EMAIL_SUCCESS"
const EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND"
const ROLES = "ROLES"
const ROLES_SUCCESS = "ROLES_SUCCESS"

const REGISTER_TIMER = "REGISTER_TIMER"
const REGISTER_TIMER_SUCCESS = "REGISTER_TIMER_SUCCESS"
const SEARCH = "SEARCH"
const SEARCH_SUCCESS = "SEARCH_SUCCESS"

const LIST_DATES = "LIST_DATES";
const LIST_DATES_SUCCESS = "LIST_DATES_SUCCESS";
const LIST_OPERATORS = "LIST_OPERATORS";
const LIST_OPERATORS_SUCCESS = "LIST_OPERATORS_SUCCESS";
const LIST_ADMINISTRARTOR = "LIST_ADMINISTRARTOR";
const LIST_ADMINISTRARTOR_SUCCESS = "LIST_ADMINISTRARTOR_SUCCESS";
const MESSAGE = "MESSAGE";
const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
const LIST_ADVANCE = "LIST_ADVANCE";
const LIST_ADVANCE_SUCCESS = "LIST_ADVANCE_SUCCESS";

export default function reducer(state = initialState, action) {
    let clients;
    switch (action.type) {
        case LOGIN:
            return { ...state, fetching: 1 };
        case LOGIN_SUCCESS:
            return { ...state, fetching: 2, ...action.payload, loggedIn: true };
        case LOGIN_ERROR:
            return { ...state, fetching: 3, errors: action.payload, loggedIn: false };
        case RESTORE_SESSION:
            return { ...state, fetching: 1 };
        case RESTORE_SESSION_SUCCESS:
            return { ...state, fetching: 2, ...action.payload, loggedIn: true };
        case RESTORE_SESSION_ERROR:
            return { ...state, fetching: 0, loggedIn: false };
        case USER:
            return { ...state, fetching_me: 1 };
        case USER_SUCCESS:
            return { ...state, fetching_me: 2, data: action.payload, loggedIn: true };
        case EMAIL:
            return { ...state, fetching_email: 1 };
        case EMAIL_SUCCESS:
            return { ...state, fetching_email: 2, selected: action.selected };
        case EMAIL_NOT_FOUND:
            return { ...state, fetching_email: 3 };
        case LIST_USER:
            return { ...state, fetching_list: 1 };
        case LIST_USER_SUCCESS:
            return { ...state, fetching_list: 2, list: action.payload };
        case LOGOUT:
            return { ...state, fetching: 0, loggedIn: false, fetching_list: 0, fetching_dates: 0 };

        case REGISTER: {
            return { ...state, fetching_create: 1 };
        }

        case REGISTER_SUCCESS: {
            return { ...state, fetching_create: 2 };
        }
        case REGISTER_ERROR: {
            return { ...state, fetching_create: 3, errors: action.payload };
        }

        case UPDATE_USER: {
            return { ...state, fetching_update: 1 };
        }

        case UPDATE_USER_SUCCESS: {
            return { ...state, fetching_update: 2, row: action.payload };
        }
        case USER_SELECTED:
            return { ...state, fetching: 0, selected: action.payload }
        case USER_CLEAR:
            return { ...state, fetching: 0, selected: {} }
        case REGISTER_TIMER:
            return { ...state, fetching_timer: 1 }
        case REGISTER_TIMER_SUCCESS:
            return { ...state, fetching_timer: 2, list: action.payload }

        case LIST_USER:
            return { ...state, fetching: 1 }
        case LIST_USER_SUCCESS:
            return { ...state, fetching: 2, list: action.payload, current_page: action.current_page, last_page: action.last_page }
        case SEARCH:
            return { ...state, fetching_search: 1 }
        case SEARCH_SUCCESS:
            clients = action.payload
            return { ...state, fetching_search: 2, clients, current_page: action.current_page, last_page: action.last_page }
        case ROLES:
            return { ...state, fetching_rol: 1 }
        case ROLES_SUCCESS:
            return { ...state, fetching_rol: 2, data_roles: action.payload }

        case LIST_DATES:
            return { ...state, fetching_dates: 1 }
        case LIST_DATES_SUCCESS:
            return { ...state, fetching_dates: 2, data_dates: action.payload }
        case LIST_OPERATORS:
            return { ...state, fetching_operator: 1 }
        case LIST_OPERATORS_SUCCESS:
            return { ...state, fetching_operator: 2, data_operator: action.payload }
        case LIST_ADMINISTRARTOR:
            return { ...state, fetching_admin: 1 }
        case LIST_ADMINISTRARTOR_SUCCESS:
            return { ...state, fetching_admin: 2, data_administrator: action.payload }
        case MESSAGE:
            return { ...state, fetching_message: 1 }
        case MESSAGE_SUCCESS:
            return { ...state, fetching_message: 2 }
        case LIST_ADVANCE:
            return { ...state, fetching_advance: 1 }
        case LIST_ADVANCE_SUCCESS:
            return { ...state, fetching_advance: 2, data_advance: action.payload }
        default:
            return state;
    }
}

export let onSearchUser = (txt) => (dispatch, getState) => {
    dispatch({
        type: SEARCH
    })


    let page = 1


    axios.get(`${API_URL}/api_v2/users-admin/${txt}?page=${page}&limit=30`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: SEARCH_SUCCESS,
                payload: response.data.results,
                current_page: response.data.current_page,
                last_page: response.data.last_page,
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
}



export let getUserPhone = (phone) => (dispatch, getState) => {
    dispatch({
        type: EMAIL
    })


    axios.get(`${API_URL}api/users-admin/${phone}`, {
        headers: getHeaders()
    })
        .then(response => {

            console.log('response', response)

            if (response.data.status === true) {
                dispatch({
                    type: EMAIL_SUCCESS,
                    selected: response.data.row
                })
            }
            if (response.data.status === false) {
                dispatch({
                    type: EMAIL_NOT_FOUND

                })
            }


        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
}



export let updateUser = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER
    })

    let rules = [
        { field: "name", msg: "El nombre es requerido" },
        // { field: "role_id", msg: "El rol es requerido" },
    ]

    let val = validateInput(form, rules);


    if (val !== null) {
        dispatch({
            type: REGISTER_ERROR,
            payload: val
        })
        return;
    }


    axios.put(`${API_URL}/api/user/${form.id}`, form, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: UPDATE_USER_SUCCESS
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }
            console.log("status", status)
        });

}

export let updateMessageUser = (message) => (dispatch, getState) => {
    dispatch({
        type: MESSAGE
    })


    axios.put(`${API_URL}/api/user-message/${message.id}`, message, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: MESSAGE_SUCCESS
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }
            console.log("status", status)
        });

}

export let setSelectedItem = (item) => (dispatch, getState) => {
    dispatch({
        type: USER_SELECTED,
        payload: item
    })
}
export let clearSelected = () => (dispatch, getState) => {
    dispatch({
        type: USER_CLEAR
    })
}



export let createUser = (form) => (dispatch, getState) => {
    dispatch({
        type: REGISTER
    })

    let rules = [
        { field: "name", msg: "El nombre es requerido" },
        { field: "role_id", msg: "El rol es requerido" },
    ]

    let val = validateInput(form, rules);


    if (val !== null) {
        dispatch({
            type: REGISTER_ERROR,
            payload: val
        })
        return;
    }

    let url = `${API_URL}/api/user`
    console.log("url", url)

    let branch = JSON.parse(localStorage.getItem("branch"))

    form.branch_id = branch.id

    axios.post(url, form, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: REGISTER_SUCCESS
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }
            console.log("status", status)
        });

}


export let onLogin = (form) => (dispatch, state) => {
    dispatch({
        type: LOGIN,
        fetching: true
    })

    let url = `${API_URL}/api/auth/login`
    console.log('url', url)

    axios.post(url, form)
        .then(response => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            })
            saveStorage(response.data)
        }).catch(error => {
            let err = {}
            let { status } = error.response

            if (status === 422) {
                err = errorLoginServer(error)
                dispatch({
                    type: LOGIN_ERROR,
                    payload: err
                });
            } else {
                err.message = "Usuario o clave no valido"
                dispatch({
                    type: LOGIN_ERROR,
                    payload: err
                });
            }

        })
}

export let logOutAction = () => (dispatch, getState) => {

    removeSession()

    console.log("borror");
    dispatch({
        type: LOGOUT,
    })
}


export let getAllUser = (status) => (dispatch, getState) => {
    dispatch({
        type: LIST_USER
    })

    let branch = JSON.parse(localStorage.getItem("branch"))


    axios.get(`${API_URL}/api/user?status=${status}&branch=${branch.id}`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: LIST_USER_SUCCESS,
                payload: response.data.results
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
}

export let getOperators = () => (dispatch, getState) => {
    dispatch({
        type: LIST_OPERATORS
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    axios.get(`${API_URL}/api/user-admin-operators?branch_id=${branch.id}`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: LIST_OPERATORS_SUCCESS,
                payload: response.data.results
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
}

export let getAdministrators = () => (dispatch, getState) => {
    dispatch({
        type: LIST_ADMINISTRARTOR
    })

    axios.get(`${API_URL}/api/user-admin-administrators`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: LIST_ADMINISTRARTOR_SUCCESS,
                payload: response.data.results
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
}


export let getInformationUser = () => (dispatch, getState) => {
    dispatch({
        type: USER
    })

    let { loggedIn } = getState().user

    if (loggedIn === true) {
        // axios.get(`${API_URL}api/user-admin`, {
        axios.get(`${API_URL}/api/me`, {
            headers: getHeaders()
        })
            .then(response => {

                dispatch({
                    type: USER_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                let { status } = error.response;

                console.log('error.response', error.response);

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }
            });
    }
}

export let restoreSessionAction = () => dispatch => {
    dispatch({
        type: RESTORE_SESSION
    })

    let storage = localStorage.getItem("storage")
    storage = JSON.parse(storage)

    if (storage) {
        dispatch({
            type: RESTORE_SESSION_SUCCESS,
            payload: storage
        })
    } else {
        dispatch({
            type: RESTORE_SESSION_ERROR,
            payload: storage
        })
    }
}

export let getRoles = () => (dispatch, getState) => {
    dispatch({
        type: ROLES
    })

    // axios.get(`${API_URL}api/user-admin`, {
    axios.get(`${API_URL}/api/roles`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: ROLES_SUCCESS,
                payload: response.data.results
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });

}

export let getDates = () => (dispatch, getState) => {
    dispatch({
        type: LIST_DATES
    })


    console.log('getState',);

    if (getState().user.loggedIn === true) {
        let range = JSON.parse(localStorage.getItem("range"));

        if (range !== null) {
            dispatch({
                type: LIST_DATES_SUCCESS,
                payload: range
            })
        } else {
            axios.get(`${API_URL}/api/range-dates`, {
                headers: getHeaders()
            })
                .then(response => {
                    localStorage.setItem("range", JSON.stringify(response.data.results))

                    dispatch({
                        type: LIST_DATES_SUCCESS,
                        payload: response.data.results
                    })
                })
                .catch(error => {
                    let { status } = error.response;

                    if (status === 401) {
                        logOutAction()(dispatch, getState)
                    }
                });
        }
    }
}

export let getAdvanceSupplies = (form) => (dispatch, getState) => {

    dispatch({
        type: LIST_ADVANCE
    })

    let { date_init, date_end, method_payment_id, type_move_id, deleted, turn_id, operator_id } = form || JSON.parse(localStorage.getItem("date"));

    if (getState().user.loggedIn === true) {

        method_payment_id = method_payment_id || 0
        type_move_id = type_move_id || 0
        operator_id = operator_id || 0
        turn_id = turn_id || 0
        deleted = deleted || false

        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/cost-worker?date_init=${date_init}&date_end=${date_end}&method_payment_id=${method_payment_id}&type_move_id=${type_move_id}
        &branch_id=${branch.id}&deleted=${deleted}&operator_id=${operator_id}&turn_id=${turn_id}`, {
            headers: getHeaders()
        })
            .then(response => {

                dispatch({
                    type: LIST_ADVANCE_SUCCESS,
                    payload: response.data.results
                })
            })
            .catch(error => {
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }
            });
    }
}

function saveStorage(storage) {
    let { user } = storage
    let { branch_office } = user;

    storage.loggedIn = true;
    localStorage.storage = JSON.stringify(storage)
    localStorage.token = storage.access_token
    localStorage.branch = JSON.stringify(branch_office);
}