import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux'
import { getListConfigWhatsApp, onRenerateToken } from '../../Redux/whatsappDuck'
import { Col, Row, Table } from 'react-bootstrap'
import ModalWhatsappConfig from './modal.whatsapp.config'

const WhatsappConfigPage = ({ getListConfigWhatsApp, fetching_list, data, onRenerateToken, fetching_regenerate }) => {
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data);
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_regenerate === 2) {
            getListConfigWhatsApp()
        }
    }, [fetching_regenerate])

    useEffect(() => {
        getListConfigWhatsApp()
    }, [])

    const onEdit = (row) => {
        setModal(true);
        setForm(row)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col className='text-center'>
                    <h3>Configuiración Whatsapp</h3>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-2'>
                <Col lg={12}>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Cliente</th>
                                <th>Phone Id Facebook</th>
                                <th>Token Facebook</th>
                                <th>Token API</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((row, k) => <tr key={k}>
                                <td>{row.customer.business_name}</td>
                                <td>{row.phone_id}</td>
                                <td>{row.token.substring(0, 20)}...</td>
                                <td>{row.token_api}</td>
                                <td>
                                    <button className='btn btn-sm btn-info' onClick={() => onEdit(row)}>Editar</button>
                                    <button className='btn btn-sm btn-warning mx-3' onClick={() => onRenerateToken(row)}>Regenerar Token</button>
                                </td>
                            </tr>)}

                        </tbody>
                    </Table>
                </Col>
            </Row>
            <ModalWhatsappConfig show={modal} formEdit={form} />
        </TemplateMenu>
    )
}
const mapState = (props) => {
    let { whatsapp } = props;
    return {
        fetching_list: whatsapp.fetching_list,
        data: whatsapp.data,
        fetching_regenerate: whatsapp.fetching_regenerate,
    }
}

export default connect(mapState, { getListConfigWhatsApp, onRenerateToken })(WhatsappConfigPage);