import React, { Fragment } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ItemBrandModel = ({ item, onAddModel, onOpenModalYear, onAddCylinder, onAddReference }) => {

    let { brand_model_year } = item

    return (
        <Col lg={4} className="my-2">
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg={8} className=''><b>{item.name}</b></Col>
                        <Col className='d-flex justify-content-end'>
                            <button className='btn btn-sm btn-success' onClick={() => onOpenModalYear(item)}>Agregar Año</button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {brand_model_year.length === 0 ? <p>No tiene Modelos</p> : brand_model_year.map((d, key) => {

                        return <Row className='my-2' key={key}>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col>AÑO</Col>
                                            <Col className='text-right'><b>{d.year}</b></Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>

                                        <Row>
                                            <Col>
                                                <Card>
                                                    <Card.Header>
                                                        <Row className=''>
                                                            <Col className='text-center'><h3>Modelos</h3></Col>
                                                            <Col className=''>
                                                                <button className='btn btn-sm btn-info' onClick={() => onAddModel(d)}>Agregar Modelos</button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>
                                                                {d.brand_model.length === 0 ? <p className='text-center'>No tiene Modelos</p> :
                                                                    d.brand_model.map((b, ke) => {
                                                                        return <Card key={ke}>
                                                                            <Card.Header>
                                                                                <Row className="">
                                                                                    <Col className='text-center'>{b.description}</Col>
                                                                                </Row>
                                                                            </Card.Header>
                                                                            <Card.Body>

                                                                                <Row>
                                                                                    <Col>
                                                                                        <Card>
                                                                                            <Card.Header>
                                                                                                <Row className=''>
                                                                                                    <Col className='text-center'><b>Cilindraje</b></Col>
                                                                                                    <Col>
                                                                                                        <button className='btn btn-sm btn-info' onClick={() => onAddCylinder(b)}>Agregar Cilindraje</button>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Card.Header>
                                                                                            <Card.Body>
                                                                                                <Row >
                                                                                                    <Col>
                                                                                                        {b.brand_model_cylinder.length === 0 ? <p>No Tiene Cilindraje</p> :
                                                                                                            <>
                                                                                                                {b.brand_model_cylinder.length === 0 ? <tr>
                                                                                                                    <td align='center'>No Tiene registros</td>
                                                                                                                </tr> : b.brand_model_cylinder.map((c, k2) =>
                                                                                                                    <Card>
                                                                                                                        <Card.Header key={k2}>
                                                                                                                            <Row>
                                                                                                                                <Col><b>{c.capacity}</b></Col>
                                                                                                                                <Col>
                                                                                                                                    <button className='btn btn-sm btn-warning' onClick={() => onAddReference(c)}>Agregar Referencias</button>
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </Card.Header>
                                                                                                                        <Card.Body>
                                                                                                                            {c.spare_part.length === 0 ? <Row><Col>No tiene Referencias</Col></Row> : c.spare_part.map(sp => <Row>
                                                                                                                                <Col>{sp.type_id_text}</Col>
                                                                                                                                <Col className='text-right'>{sp.reference}</Col>
                                                                                                                            </Row>)}
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                )}
                                                                                                            </>
                                                                                                        }

                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Card.Body>
                                                                                        </Card>
                                                                                    </Col>
                                                                                </Row>



                                                                            </Card.Body>



                                                                        </Card>
                                                                    })}
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>



                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    })}
                </Card.Body>
            </Card>

        </Col>
    )
}

export default ItemBrandModel;