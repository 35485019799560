import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css'
import { connect } from 'react-redux';
import { onLogin, getInformationUser, getDates } from '../../Redux/userDuck'
import { getAllBranchOffice } from '../../Redux/branch.officeDuck'

import { toogleAlert } from '../../Redux/alertDuck'
import { getTurnActive } from '../../Redux/turnDuck'
import ButtonNaranja from '../Components/Widgets/ButtonNaranja'
import WhiteTitle from '../Components/Widgets/WhiteTitle'
import InputForm from '../Components/Widgets/InputForm'
import logo_mana_white2 from '../../Images/logo.png'
import { useHistory } from "react-router-dom";
import moment from 'moment';


const Login = ({ show, onLogin, errors, fetching, getInformationUser, toogleAlert, getDates, getAllBranchOffice, fetching_dates, getTurnActive }) => {

    const [form, setForm] = useState({ email: '', password: '' })
    const history = useHistory();

    useEffect(() => {
        toogleAlert(false)
    }, [])

    useEffect(() => {
        if (fetching_dates === 2) {
            getAllBranchOffice()
            getInformationUser()

            history.push("/");
        }
    }, [fetching_dates])

    useEffect(() => {
        if (fetching === 2) {

            getDates();

        }
    }, [fetching])

    const handlerInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        onLogin(form)
    }

    return (

        <Container fluid className="d-flex pt-5 justify-content-center align-items-center container-fluid px-0 vh-100 bg-azul ">
            <Row className="p-3 d-flex justify-content-center align-items-center w-100 h-100">
                <Col lg={8} className=" pb-0 w-100 border-white-30 card-custom">

                    <Row className="">
                        <Col lg={6} className="pl-0">
                            <Row className="container-image d-flex align-items-center">
                                <Col>
                                    <Row className="d-flex justify-content-center">
                                        <Col className="d-flex justify-content-center">
                                            <img src={logo_mana_white2} alt="" className="img-fluid text-center img-logo-login" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className="d-flex flex-column justify-content-center align-items-center py-3 bg-burger">
                            <div className="bg-image-login"></div>
                            <Row className="justify-content-center">
                                <Col lg={6} className="d-flex justify-content-center">
                                    <WhiteTitle title='login' />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12} className="d-flex justify-content-center">
                                    <span className="text-error">{errors.message}</span>
                                </Col>
                            </Row>

                            <Row className="justify-content-center py-4 w-100">
                                <Col lg={11} className="w-100">
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputForm
                                            type='email'
                                            name='email'
                                            placeholder='Ingresa tu Correo'
                                            error={errors.email}
                                            value={form.email}
                                            onChange={handlerInput}
                                        />

                                        <InputForm
                                            name='password'
                                            type='password'
                                            placeholder='Contraseña'
                                            value={form.password}
                                            error={errors.password}
                                            onChange={handlerInput}

                                        />
                                        <div className="mt-4" />
                                        <ButtonNaranja title='Ingresar' onClick={(e) => onSubmit(e)} />

                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    )
}

const mapsState = (props) => {
    let { user } = props
    return {
        fetching: user.fetching,
        fetching_dates: user.fetching_dates,
        errors: user.errors,
    }
}

export default connect(mapsState, { onLogin, getInformationUser, toogleAlert, getDates, getAllBranchOffice, getTurnActive })(Login);