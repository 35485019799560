import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Alert from './Pages/Components/Widgets/Alert'
import ButtonAside from './Pages/Components/ButtonAside';
import logo_madera from './Images/logo.png'
import { useHistory } from "react-router-dom";
import { logOutAction, getInformationUser, getDates } from './Redux/userDuck'
import { getTurnActive } from './Redux/turnDuck'
import { getAllBranchOffice } from './Redux/branch.officeDuck'
import { getMenu } from './Redux/menuDuck'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import LoaderPage from './Pages/Loader';
const API_URL = process.env.REACT_APP_API_URL


const App = ({ loggedIn, getInformationUser, data, logOutAction, getMenu, getAllBranchOffice, getDates, fetching_filter, getTurnActive, loader }) => {

  const history = useHistory();
  const [list, setList] = useState([])

  useEffect(() => {
    getInformationUser()
    getMenu()
    getAllBranchOffice()
    getDates()
    getTurnActive()
  }, [])

  useEffect(() => {

    if (fetching_filter === 2) {
      window.location.reload()
    }

  }, [fetching_filter])


  const printDetail = (row, key) => {
    let image = API_URL + row.icon
    return (
      <Row className=" justify-content-center px-2 px-lg-2 px-xl-4" key={key}>
        <ButtonAside title={row.title} link={row.link} image={image} />
      </Row>
    )
  }

  return (
    <Router>
      <LoaderPage show={loader} />
      <ToastProvider>
        <Routes />
      </ToastProvider>
      <Alert />
    </Router>
  );
}

const mapsState = (props) => {
  let { menu, user, alert } = props
  return {
    loggedIn: user.loggedIn,
    data: user.data,
    menu: menu.data,
    fetching_filter: menu.fetching_filter,
    loader: alert.loader
  }
}

export default connect(mapsState, { getInformationUser, logOutAction, getMenu, getAllBranchOffice, getDates, getTurnActive })(App)