import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template'
import { getListPrescription } from '../../Redux/prescriptionDuck'
import { connect } from 'react-redux';
import ItemtablePrescription from './item.table.prescription';

const PrescriptionPage = ({ getListPrescription, fetching_list, data }) => {

    const [list, setList] = useState([])



    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        getListPrescription()
    }, [])

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col lg={2}><h3>Listado de Recetas</h3></Col>
                <Col>
                    <Link className='btn btn-sm btn-success' to={"/recetas/new"}>Crear Receta</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>PRODUCTO DE VENTA</th>
                                <th className='text-center'>CANTIDAD DE ITEMS</th>
                                <th className='text-center'>COSTO RECETA</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length == 0 ? <tr>
                                <td colSpan={5} align="center">No hay registros</td>
                            </tr>
                                : list.map(d => <ItemtablePrescription key={d.id} item={d} />)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { prescription } = props

    return {
        fetching_list: prescription.fetching_list,
        data: prescription.data,
    }
}

export default connect(mapsState, { getListPrescription })(PrescriptionPage);