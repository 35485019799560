import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalProducts = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>ModalProducts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>ModalProducts</Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalProducts;