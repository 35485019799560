
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    data: [],
    errors: {}
}


const LIST_FAMILY = "LIST_FAMILY"
const LIST_FAMILY_SUCCESS = "LIST_FAMILY_SUCCESS"
const CREATE_FAMILY = "CREATE_FAMILY"
const CREATE_FAMILY_SUCCESS = "CREATE_FAMILY_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_FAMILY:
            return { ...state, fetching_list: 1 }
        case LIST_FAMILY_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_FAMILY:
            return { ...state, fetching_create: 1 }
        case CREATE_FAMILY_SUCCESS:
            return { ...state, fetching_create: 2 }
        default:
            return state;
    }
}

export let getListFamily = () => (dispatch, getState) => {
    dispatch({
        type: LIST_FAMILY
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/family`

        Axios.get(url, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_FAMILY_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateFamily = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_FAMILY
    })

    if (getState().user.loggedIn) {

        Axios.post(`${API_URL}/api/family`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_FAMILY_SUCCESS
            });
        }).catch(err => {
            console.log('err', err.response.data);
            // dispatch({
            //     type: CREATE_DETAILING_ERROR,
            //     payload: err.response.data.errors
            // });
        })
    } else {
        console.log("no Ingreso");
    }
}