import React, { useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';

const ItemClient = ({ row, onNotifier }) => {

    const [checked, setChecked] = useState(false)

    const onChange = () => {
        setChecked(!checked)
        row.checked = !checked;
    }

    return (
        <ListGroup.Item className={`p-1 ${row.validate ? "list-group-item-success" : 'list-group-item-dark'}`}>
            <Row>
                <Col lg={2}>
                    {row.validate && <input className='input-sm form-control' type={"checkbox"} checked={checked} onChange={onChange} />}
                </Col>
                <Col>{row.name} ({row.phone})</Col>
            </Row>
        </ListGroup.Item>
    )
}

export default ItemClient;