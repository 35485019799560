import React, { useEffect, useState } from 'react'
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const FormBusiness = ({ title, errors, formEdit, onSuccess }) => {
    const [form, setForm] = useState({})
    const [clientDefault, setClienDefault] = useState({})
    const [userDefault, setUserDefault] = useState({})
    const [listBranch, setListBranch] = useState([])
    const [listUsers, setListUsers] = useState([])

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
            setListBranch(formEdit.branch_offices)
            setClienDefault(formEdit.client_default)
            setListUsers(formEdit.users)
            // setUserDefault(formEdit.user)
        }
    }, [formEdit])

    const updateInput = (e) => {

    }

    const updateInputDefault = (e) => {
        setClienDefault({
            ...clientDefault,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

    }

    const onClick = () => {
        let row = form
        row.client_default = clientDefault
        onSuccess(row)
    }

    return (
        <Form>
            <Row className='py-3'>
                <Col lg={1}>{title}</Col>
                <Col>
                    <button className='btn btn-sm btn-success' type='button' onClick={onClick}>Editar</button>
                </Col>
            </Row>
            <Row>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Datos Principales</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Alias</label>
                                        <InputField
                                            placeholder="Agrega el Alias"
                                            value={form.business || ''}
                                            name="business"
                                            onChange={updateInput}
                                            error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Razón Social</label>
                                        <InputField
                                            placeholder="Agrega la Razon social"
                                            value={form.business_name || ''}
                                            name="business_name"
                                            onChange={updateInput}
                                            error={errors.business_name}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Documento</label>
                                        <InputField
                                            type="number"
                                            placeholder="Agrega el documento"
                                            value={form.document || ''}
                                            name="document"
                                            onChange={updateInput}
                                            error={errors.document}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Telefono</label>
                                        <InputField
                                            type="number"
                                            placeholder="Agrega el telefono"
                                            value={form.phone || ''}
                                            name="phone"
                                            onChange={updateInput}
                                            error={errors.phone}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <InputField
                                            placeholder="Agrega el Email"
                                            value={form.email || ''}
                                            name="email"
                                            onChange={updateInput}
                                            error={errors.email}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Api Whatsapp</label>
                                        <InputField
                                            placeholder="Api"
                                            value={form.api || ''}
                                            name="api"
                                            onChange={updateInput}
                                            error={errors.api}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Dirección</label>
                                        <InputField
                                            placeholder="Agrega la Direccion"
                                            value={form.address || ''}
                                            name="address"
                                            onChange={updateInput}
                                            error={errors.address}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Resolución</label>
                                        <InputField
                                            placeholder="Resolución"
                                            value={form.resolution || ''}
                                            name="resolution"
                                            onChange={updateInput}
                                            error={errors.resolution}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Estado</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status || false}
                                            name="status"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-group">
                                        <label>Fin Día</label>
                                        <InputField
                                            type={"time"}
                                            placeholder="Inicio dia"
                                            value={form.end_day || ''}
                                            name="end_day"
                                            onChange={updateInput}
                                            error={errors.end_day}
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-group">
                                        <label>Fin Noche</label>
                                        <InputField
                                            type={"time"}
                                            placeholder="Inicio dia"
                                            value={form.end_night || ''}
                                            name="end_night"
                                            onChange={updateInput}
                                            error={errors.end_night}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Sucursales <button className='btn btn-sm btn-info'>Agregar</button>
                                    </Card.Title>

                                    <Row className='mt-3'>
                                        <Col>
                                            <ListGroup>
                                                {listBranch.map((d, k) =>
                                                    <ListGroup.Item key={k}>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col>{d.description}</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>{d.address}</Col>
                                                                </Row>

                                                            </Col>
                                                            <Col>
                                                                <div className='container-color' style={{ backgroundColor: d.color }}>
                                                                    {d.color}
                                                                </div>

                                                            </Col>
                                                            <Col>
                                                                <button className='btn btn-sm btn-info' type='button'>Editar</button>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )}

                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Cliente por defecto</Card.Title>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <InputField
                                                    placeholder="Agrega el Alias"
                                                    value={clientDefault.name || ''}
                                                    name="name"
                                                    onChange={updateInputDefault}
                                                // error={errors.business_default}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Celular</label>
                                                <InputField
                                                    placeholder="Celular"
                                                    value={clientDefault.phone || ''}
                                                    name="phone"
                                                    onChange={updateInputDefault}
                                                // error={errors.phone_business}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg={3}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Usuarios</Card.Title>
                            <Row>
                                <Col>
                                    <ListGroup>
                                        {listUsers.map((d, k) =>
                                            <ListGroup.Item key={k}>
                                                <Row>
                                                    <Col lg={9}>
                                                        <Row>
                                                            <Col><b>Usuario:</b> {d.name}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col><b>Rol:</b> {d.rol.title}</Col>
                                                        </Row>

                                                    </Col>
                                                    <Col>
                                                        <button className='btn btn-sm btn-info'>Editar</button>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>

            </Row>
        </Form>
    )
}

export default FormBusiness;