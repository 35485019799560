import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';
import { onCreateSuppplier, onUpdateSuppplier } from '../../Redux/supplierDuck'

const initState = { business: '', business_name: '', document: '', address: '', status: true };

const ModalSupplier = ({ show, onSuccess, onHide, onCreateSuppplier, fetching_create, errors_supplier, formEdit, onUpdateSuppplier }) => {
    const [form, setForm] = useState(initState)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
        }

    }, [formEdit])

    useEffect(() => {
        if (fetching_create === 2) {
            setForm(initState)
            onSuccess()
        } else if (fetching_create === 3) {
            setErrors(errors_supplier)
        }
    }, [fetching_create])


    const updateInput = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const onCreate = (e) => {
        if (form.id !== undefined) {
            onUpdateSuppplier(form)
        } else {
            onCreateSuppplier(form)
        }

    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Proveedores</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Alias</label>
                            <InputField
                                value={form.business}
                                name="business"
                                placeholder={"Alias"}
                                onChange={updateInput}
                                error={errors.business}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Razón Social</label>
                            <InputField
                                value={form.business_name}
                                name="business_name"
                                placeholder={"Razón Social"}
                                onChange={updateInput}
                                error={errors.business_name}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Nit</label>
                            <InputField
                                type={"number"}
                                value={form.document}
                                name="document"
                                placeholder={"Nit"}
                                onChange={updateInput}
                                error={errors.document}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Dirección</label>
                            <InputField
                                value={form.address}
                                name="address"
                                placeholder={"Dirección"}
                                onChange={updateInput}
                                error={errors.address}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Estado</label>
                            <input type={"checkbox"} className="form-control" name="status" value={form.status} checked={form.status} onChange={updateInput} />

                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className='d-flex justify-content-end'>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={() => onCreate(form)}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { supplier } = props
    return {
        fetching_create: supplier.fetching_create,
        errors_supplier: supplier.errors,

    }
}

export default connect(mapsState, { onCreateSuppplier, onUpdateSuppplier })(ModalSupplier);