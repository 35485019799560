import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import FormProduct from './form.product';
import { createOrUpdateProduct } from '../../Redux/productDuck'
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const CreateProductPage = ({ createOrUpdateProduct, fetching_edit }) => {

    const history = useHistory();
    const search = useLocation().search;
    const category_id = new URLSearchParams(search).get("category_id");

    useEffect(() => {
        if (fetching_edit === 2) {
            history.goBack();
        }
    }, [fetching_edit])

    const onSubmit = (form) => {
        createOrUpdateProduct(form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Crear Producto</h4></Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}><FormProduct onSubmit={onSubmit} category_id={category_id} /></Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product } = props


    return {
        fetching_edit: product.fetching_edit
    }
}

export default connect(mapsState, { createOrUpdateProduct })(CreateProductPage);