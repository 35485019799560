import React from 'react';
import modify from '../../Images/modify.svg'
import moment from 'moment';


const RowTableUser = ({ item, onEdit }) => {
  let { branch_office, business, rol } = item;
  let color = item.status === true ? '' : 'table-secondary'

  return (
    <tr className={`${color}`}>
      <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.document}</td>
      <td>{business.business}</td>
      <td>{(branch_office !== null) ? branch_office.description : ''}</td>
      <td>{rol.title}</td>
      <td>{item.percent}</td>
      <td>{item.status_text}</td>
      <td className="text-center">
        <button className='btn btn-sm btn-info' onClick={() => onEdit(item)}>Editar</button>
      </td>
    </tr>
  )
}
export default RowTableUser;