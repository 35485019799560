import axios from 'axios'
import { getHeaders } from '../utils'
import { logOutAction } from './userDuck'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_SOCKET = process.env.REACT_APP_SOCKET_URL


let initialState = {
    fetching: 0,
    fetching_filter: 0,
    data: [],
    errors: '',
    branch: {}
}

const MENU = "MENU"
const MENU_SUCCESS = "MENU_SUCCESS"
const MENUPDF = "MENUPDF"
const MENUPDF_SUCCESS = "MENUPDF_SUCCESS"
const SET_FILTER = "SET_FILTER"
const SET_FILTER_SUCCESS = "SET_FILTER_SUCCESS"


export default function reducer(state = initialState, action) {

    switch (action.type) {
        case MENU: {
            return { ...state, fetching: 1, fetching_filter: 0 };
        }
        case MENU_SUCCESS: {
            return { ...state, fetching: 2, data: action.payload };
        }
        case MENUPDF: {
            return { ...state, fetching: 1 };
        }
        case MENUPDF_SUCCESS: {
            return { ...state, fetching: 2 };
        }
        case SET_FILTER: {
            return { ...state, fetching_filter: 1 };
        }
        case SET_FILTER_SUCCESS: {
            return { ...state, fetching_filter: 2, branch: action.payload };
        }
        default:
            return state;
    }
}

export let getMenu = () => (dispatch, getState) => {
    dispatch({
        type: MENU
    });


    let url = `${API_URL}/api/auth/menu`

    let menu = JSON.parse(localStorage.getItem("menu"))

    if (menu !== null) {
        dispatch({
            type: MENU_SUCCESS,
            payload: menu
        });
    } else {
        axios.get(url,
            {
                headers: getHeaders()
            }).then(resp => {

                localStorage.setItem("menu", JSON.stringify(resp.data.results))
                dispatch({
                    type: MENU_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(function (error) {
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }

            });
    }
}


export let uploadMenuPdf = (form) => (dispatch, getState) => {

    dispatch({
        type: MENUPDF
    });


    let url = `${API_URL_SOCKET}/api_v2/menu-pdf`;
    console.log('llego', url)

    axios.post(url, form, {
        headers: getHeaders()
    }).then(resp => {

    });
}

export let onGetOrSetFilter = (branch) => (dispatch, getState) => {

    dispatch({
        type: SET_FILTER,
    });


    if (branch !== undefined) {
        console.log('llego acasc');

        branch.filter = `branch=${branch.id}`;
        localStorage.setItem("branch", JSON.stringify(branch))
    } else {
        branch = { id: 0, filter: `branch=0` }
        localStorage.setItem("branch", JSON.stringify(branch))
    }

    dispatch({
        type: SET_FILTER_SUCCESS,
        payload: branch
    });
}