import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const API_URL_SOCKET = process.env.REACT_APP_API_URL

const ItemTableBriefcaseAccount = ({ item, onPaymentOrder, onDelete, onReverse }) => {

    let { customer } = item;

    return (
        <tr>
            <td>{item.registration_date}</td>
            <td>{item.consecutive}</td>
            <td>{customer.name}</td>
            <td>{customer.phone}</td>
            <td>{item.status_id_text}</td>
            <td align='right'>{formatNumber(item.total)}</td>
            <td>
                {item.status_id === 1 ? <>
                    <Link className='btn btn-sm btn-info' to={`/cartera/${item.id}`} >EDITAR</Link>
                    <button className='btn btn-sm btn-danger mx-3' to={`/cartera/${item.id}`} onClick={onDelete}>BORRAR</button>
                    <button className='btn btn-sm btn-success' to={`/cartera/${item.id}`} onClick={onPaymentOrder}>PAGAR</button>
                </> : <>
                    <a rel="noreferrer" target='_blank' href={`${API_URL_SOCKET}/api/briefcase-view-pdf/${item.id}`}
                        className='btn btn-sm btn-warning' >PDF</a>
                    <button className='btn btn-sm btn-danger mx-3' to={`/cartera/${item.id}`} onClick={onReverse}>REVERSAR</button>

                </>}

            </td>
        </tr >
    )
}

export default ItemTableBriefcaseAccount;