import React from 'react'
import { MdInsertPhoto, MdPictureAsPdf } from "react-icons/md";
import { formatNumber } from '../../utils';


const ItemTableTurn = ({ item, onFinishTurn, onReOpenTurn, onShowPhotos }) => {
    let { user } = item

    return (
        <tr>
            <td>{user.name}</td>
            <td>{item.date_start}</td>
            <td>{item.status_id === 2 ? item.date_end : ''}</td>
            <td align='right'>{formatNumber(item.open_cash_formated)}</td>
            <td align='right'>{formatNumber(item.percent)}%</td>
            <td>
                {item.status_id !== 1 &&
                    <MdInsertPhoto size={20} className="cursor-pointer" onClick={() => onShowPhotos(item.image_path)} />
                }

            </td>
            <td>{item.status_text}</td>
            <td>
                {item.status_id === 1 ?
                    <button className='btn btn-sm btn-warning' onClick={onFinishTurn}>Finalizar Turno</button> :
                    <button className='btn btn-sm btn-secondary' onClick={onReOpenTurn}>ReAbrir Turno</button>
                }
            </td>

        </tr>
    )
}

export default ItemTableTurn;