import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { Link } from 'react-router-dom';


const initState = { registration_date: '', description: '', value: '', type_move_id: '', method_payment_id: 0 }

const ModalExpense = ({ show, onHide, selected, onSucess, listPayment, turn_active }) => {

    const [form, setForm] = useState(initState)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setForm({
            ...form,
            ["registration_date"]: selected.date_init,
            ["type_move_id"]: selected.type_move_id
        })
    }, [selected])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveExpense = () => {
        let errors = {}

        if (form.description === '') {
            errors.description = "Campo Requerido"
        }
        if (form.value === '') {
            errors.value = "Campo Requerido"
        }
        if (form.method_payment_id === 0) {
            errors.method_payment_id = "Campo Requerido"
        }


        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            setForm(initState)
            onSucess(form)
        }

    }



    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar {selected.title} a {selected.operator}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Fecha</label>
                            <InputField
                                type="date"
                                placeholder="Fecha"
                                value={form.registration_date || ''}
                                name="registration_date"
                                onChange={updateInput}
                                error={errors.registration_date}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Descripción</label>
                            <InputField
                                type="text"
                                placeholder="Agrega una Descripción"
                                value={form.description || ''}
                                name="description"
                                onChange={updateInput}
                                error={errors.description}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Valor</label>
                            <InputField
                                type="number"
                                placeholder="Agrega un Valor"
                                value={form.value || ''}
                                name="value"
                                onChange={updateInput}
                                error={errors.value}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Método de pago</label>
                            <select className='form-control' name='method_payment_id' onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                {listPayment.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                            </select>
                            {errors.method_payment_id !== undefined ? (<label className="error-label">{errors.method_payment_id}</label>) : null}

                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        {turn_active === null ? <Link to={`/turnos`} className="btn btn-sm btn-danger"> Abrir Turnos</Link> : <button className='btn btn-sm btn-info' onClick={onSaveExpense}>Agregar</button>}

                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalExpense;