import React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import ItemTableService from '../Services/item.table.service';

const ItemCardServiceSpecial = ({ item, onEdit }) => {
    let { service } = item
    let { service_price } = service


    return (
        <Col lg={4} className="mt-3">
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg={2}><h3>#{service.priority}</h3></Col>

                        <Col className='d-flex justify-content-end'>
                            <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col><b>Nombre</b></Col>
                        <Col>{service.name}</Col>
                    </Row>
                    <Row className='my-1'>
                        <Col><b>Descripción</b></Col>
                        <Col>{service.description}</Col>
                    </Row>
                    <Row className='my-1'>
                        <Col><b>Estado</b></Col>
                        <Col>{service.status_text}</Col>
                    </Row>
                    <Row className='my-1'>
                        <Col><b>PRECIOS</b></Col>
                    </Row>
                    <Row className='my-1'>
                        <Col>
                            <Table className='table-hover table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th colSpan={5} className="text-center">PRECIOS</th>
                                    </tr>
                                    <tr>
                                        <th rowSpan={2} className="text-center" align='center'>Categoria</th>
                                        <th colSpan={2} className='text-center'>DÍA</th>
                                        <th colSpan={2} className='text-center'>NOCHE</th>
                                    </tr>
                                    <tr>

                                        <th className='text-center'>Costo</th>
                                        <th className='text-center'>Valor</th>
                                        <th className='text-center'>Costo</th>
                                        <th className='text-center'>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {service_price.map((p, k) => <ItemTableService item={p} key={k} />)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        </Col>
    )
}

export default ItemCardServiceSpecial;