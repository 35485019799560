import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Table } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { getCategories } from '../../Redux/categoryDuck'
import ItemCategoryPriceModal from './ItemCategoryPriceModal';


const initState = { name: '', description: '', priority: '', status: false, type_product_id: 0 };
const initStateCategory = {
    id: 0, category_id: 0, service_id: 0, price: '', percent: '', price_night: '', percent_night: '',
    type_value: false, type_value_night: false
};

const ModalDetailService = ({ show, onHide, errors, getCategories, categories, onCreate, onUpdate, formEdit, size }) => {


    const [form, setForm] = useState({ title: '', priority: size })
    const [formCategory, setFormCategory] = useState({ price: '', price_night: '', percent: '', percent_night: '', type_value: false, type_value_night: false })
    const [listCategory, setListCategory] = useState([])
    const [errorsCategory, setErrorsCategory] = useState({})
    const [selected, setSelected] = useState({ id: 0 })

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            getCategories()
            setForm(formEdit);
            setListCategory(formEdit.service_price);
        } else {
            setForm(initState)

            let service_price = []
            categories.forEach(c => {
                service_price.push({ category_id: c.id, category: c, product_id: 0, price: 0, price_formated: "$0" });
            });
            setListCategory(service_price)
        }
    }, [formEdit])



    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const updateField = (e) => {

        setFormCategory({
            ...formCategory,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = (e) => {
        let _form = form;
        _form.categories = listCategory;

        onCreate(_form)


        // if (form.id !== undefined) {
        //     onUpdate(_form)
        // } else {
        //     onCreate(_form)
        // }

    }



    const onDeleteRow = (row) => {
        let _list = listCategory.filter(d => d.id !== row.id)
        setListCategory(_list)

    }
    const onEditRow = (item) => {
        setSelected(item)

        let percent = item.percent;
        let percent_night = item.percent_night;

        if (item.type_value === false) {
            percent = item.price * (percent / 100)
        }

        if (item.type_value_night === false) {
            percent_night = item.price_night * (percent_night / 100)
        }

        setFormCategory({
            ...formCategory,
            ["category_id"]: item.category_id,
            ["price"]: item.price,
            ["price_night"]: item.price_night,
            ["percent"]: percent,
            ["percent_night"]: percent_night,
            ["type_value"]: item.type_value,
            ["type_value_night"]: item.type_value_night,
            ["id"]: item.id,
        })
    }

    const onConfirmEditRow = () => {
        let errors = {}
        if (formCategory.category_id === 0) {
            errors.category_id = "Campo requerido";
        }

        if (formCategory.price === '') {
            errors.price = "Precio requerido";
        }

        if (formCategory.price_night === '') {
            errors.price_night = "Precio requerido";
        }

        if (formCategory.percent === '') {
            errors.percent = "Porcentaje requerido";
        }

        if (formCategory.percent_night === '') {
            errors.percent_night = "Porcentaje requerido";
        }

        var regexPattern = new RegExp("true");

        var type_value = regexPattern.test(formCategory.type_value);
        var type_value_night = regexPattern.test(formCategory.type_value_night);

        let type_value_text = type_value === true ? 'Porcentaje' : 'Valor'

        let percent_night_value = formCategory.percent_night;

        let percent_value = formCategory.percent


        if (type_value !== true) {
            percent_value = ((formCategory.percent * 100) / formCategory.price)
        }
        if (type_value_night !== true) {
            percent_night_value = ((formCategory.percent_night * 100) / formCategory.price_night)
        }


        let list = listCategory.map(d => {
            if (formCategory.id === d.id) {
                d.price = formCategory.price
                d.price_night = formCategory.price_night
                d.percent = percent_value
                d.percent_night = percent_night_value
                d.type_value = type_value
                d.type_value_text = type_value_text
                d.type_value_night = formCategory.type_value_night
            }

            return d
        })

        setListCategory(list)
        setSelected({ id: 0 })
        setFormCategory(initStateCategory)

    }

    const onCancelRow = () => {
        setFormCategory(initStateCategory)
        setSelected({ id: 0 })
    }

    return (
        <Modal centered show={show} onHide={closeModalTmp} size={"lg"}>
            <Modal.Header>
                <Modal.Title>SERVICIOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>

                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Nombre</label>
                                        <InputField
                                            placeholder="Nombre"
                                            value={form.name || ''}
                                            name="name"
                                            onChange={updateInput}
                                            error={errors.name}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Descripción</label>
                                        <InputField
                                            placeholder="Descripcion"
                                            value={form.description || ''}
                                            name="description"
                                            onChange={updateInput}
                                            error={errors.description}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <div className="form-group">
                                        <label>Orden</label>
                                        <InputField
                                            type="number"
                                            placeholder="Orden"
                                            value={form.priority || ''}
                                            name="priority"
                                            onChange={updateInput}
                                            error={errors.priority}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Estado</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status || false}
                                            name="status"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col lg={5}>
                                            <div className="form-group">
                                                <label>Categoria</label>
                                                <select className='form-control' name='category_id' value={formCategory.category_id} onChange={updateField}>
                                                    <option value={0}>Seleccione</option>
                                                    {categories.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                                </select>
                                                {errorsCategory.category_id !== undefined ? (<label className="error-label">{errorsCategory.category_id}</label>) : null}

                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>DESCRIPCIÓN</th>
                                                        <th>DÍA</th>
                                                        <th>NOCHE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Precio</td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Precio"
                                                                value={formCategory.price || ''}
                                                                name="price"
                                                                onChange={updateField}
                                                                error={errorsCategory.price}
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Precio"
                                                                value={formCategory.price_night || ''}
                                                                name="price_night"
                                                                onChange={updateField}
                                                                error={errorsCategory.price_night}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Valor</td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Valor"
                                                                value={formCategory.percent || ''}
                                                                name="percent"
                                                                onChange={updateField}
                                                                error={errorsCategory.percent}
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputField
                                                                type={"number"}
                                                                placeholder="Valor"
                                                                value={formCategory.percent_night || ''}
                                                                name="percent_night"
                                                                onChange={updateField}
                                                                error={errorsCategory.percent_night}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tipo</td>
                                                        <td>
                                                            <select className='form-control' name='type_value' value={formCategory.type_value} onChange={updateField}>
                                                                <option value={true}>Porcentaje</option>
                                                                <option value={false}>Valor</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className='form-control' name='type_value_night' value={formCategory.type_value_night} onChange={updateField}>
                                                                <option value={true}>Porcentaje</option>
                                                                <option value={false}>Valor</option>
                                                            </select>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3 d-flex justify-content-center'>
                                        <Col lg={3}>
                                            {selected.id !== 0 ?
                                                <>
                                                    <button className='btn btn-success btn-sm' onClick={onConfirmEditRow}>Editar</button>
                                                    <button className='btn btn-danger mx-2 btn-sm' onClick={onCancelRow}>Cancelar</button>
                                                </> :
                                                <button className='btn btn-info btn-sm' onClick={onConfirmEditRow}>Agregar</button>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th></th>
                                                        <th colSpan={3} className="text-center">DÍA</th>
                                                        <th colSpan={3} className="text-center">NOCHE</th>
                                                        <th></th>

                                                    </tr>
                                                    <tr>
                                                        <th>Descripción</th>
                                                        <th>Costo</th>
                                                        <th>Valor</th>
                                                        <th>Tipo</th>
                                                        <th>Costo</th>
                                                        <th>Valor</th>
                                                        <th>Tipo</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listCategory.map((row, key) => <ItemCategoryPriceModal row={row}
                                                        selected={selected}
                                                        key={key}
                                                        onDeleteRow={onDeleteRow} onEditRow={onEditRow} />)}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </Modal.Body >
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={closeModalTmp}>Cerrar</button>

                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

const mapsState = (props) => {
    let { product, category } = props
    return {
        errors: product.errors,
        selected: category.selected,
        categories: category.data,
        fetching: category.fetching
    }
}

export default connect(mapsState, { getCategories })(ModalDetailService);