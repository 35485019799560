import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import ModalPlate from './modal.plate';

import { onAddVehicle, onDeleteVehicle } from '../../Redux/clientDuck'

const AssocVehiclesPage = ({ vehicles, listCategory, client_id, onAddVehicle, fetching_vehicles, onDeleteVehicle }) => {

    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (fetching_vehicles === 2) {
            setModal(false)
        }
    }, [fetching_vehicles])

    const onCreateVehicle = (form) => {
        form.client_id = client_id
        onAddVehicle(form)
    }

    const onDeleteVehicleRow = (row) => {
        onDeleteVehicle(row.id)
    }


    return (
        <>
            <Row>
                <Col lg={2}><h4>Vehículos Asociados</h4></Col>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Crear</button>
                </Col>
            </Row>
            <Row>
                <Col lg={5}>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Categoria</th>
                                <th>Placa</th>
                                <th>Cantidad</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {(vehicles.length === 0) ? <tr><td>No tiene Placas</td></tr> : vehicles.map(d => <tr key={d.id}>
                                <td>{d.category.description}</td>
                                <td>{d.plate}</td>
                                <td>{d.quantity}</td>
                                <td><button className='btn btn-sm btn-danger' onClick={() => onDeleteVehicleRow(d)}>Borrar</button></td>
                            </tr>)}
                        </tbody>

                    </Table>
                </Col>
            </Row>

            <ModalPlate show={modal} listCategory={listCategory} onHide={() => setModal(false)} onCreateVehicle={onCreateVehicle} />
        </>
    )
}

const mapsState = (props) => {
    let { client } = props

    return {
        fetching_vehicles: client.fetching_vehicles
    }
}

export default connect(mapsState, { onAddVehicle, onDeleteVehicle })(AssocVehiclesPage);