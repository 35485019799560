import React from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { deleteAllData } from '../../Redux/adminDuck'

const DeletaDataPage = ({ deleteAllData }) => {
    return (
        <TemplateMenu>
            <Row>
                <Col>
                    <p>Deleta My Data</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <button className='btn btn-sm btn-danger' onClick={deleteAllData}>Delete All</button>
                </Col>
            </Row>
        </TemplateMenu>
    )
}
const mapsState = () => {
    return {

    }
}

export default connect(mapsState, { deleteAllData })(DeletaDataPage);