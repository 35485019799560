import React, { useEffect, useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'

const ItemSubMenu = ({ item, onClick }) => {
    let { permission_role } = item

    const [value, setValue] = useState(permission_role === null ? false : true)

    useEffect(() => {
        if (permission_role !== null) {
            setValue(true);
            item.checked = true;
        }

    }, [permission_role])

    const onChange = () => {
        item.checked = !value;
        setValue(!value)
    }

    return (
        // <ListGroup.Item className="cursor-pointer">
        <Row>
            <Col lg={10} onClick={() => onClick(item)}>{item.priority}. {item.title} </Col>
            <Col className='d-flex justify-content-end'><input type={"checkbox"} className="pl-2" checked={value} onChange={onChange} /></Col>
        </Row>

        // </ListGroup.Item>
    )
}

export default ItemSubMenu;