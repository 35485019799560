import moment from 'moment';
import React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import ItemTableService from './item.table.service';

const ItemCardService = ({ item, onEdit, onDelete }) => {
    let { service_price, service_client } = item

    return (
        <Col lg={4} className="mt-3">
            <Card border={service_client === null ? '' : 'primary'}>
                <Card.Header>
                    <Row>
                        <Col lg={2}><h4>#{item.priority} </h4></Col>
                        <Col className='d-flex justify-content-end'>
                            <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button>
                            <button className='btn btn-danger btn-sm mx-1' onClick={() => onDelete(item)}>Delete</button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg={3}><b>ID</b></Col>
                        <Col lg={2}><span className='text-muted'> ({item.id})</span></Col>
                    </Row>
                    <Row>
                        <Col lg={3}><b>Nombre</b></Col>
                        <Col>{item.name}</Col>
                    </Row>
                    <Row className='my-1'>
                        <Col lg={3}><b>Descripción</b></Col>
                        <Col>{item.description}</Col>
                    </Row>
                    <Row className='my-1'>
                        <Col lg={3}><b>Estado</b></Col>
                        <Col>{item.status_text}</Col>
                    </Row>
                    {parseFloat(item.discount) > 0 && <Row className='my-1'>
                        <Col lg={3}><b>Descuento</b></Col>
                        <Col>{formatNumber(item.discount)}</Col>
                    </Row>}

                    {service_client !== null &&
                        <Row className='my-1'>
                            <Col lg={3}><b>Asociado a: </b></Col>
                            <Col>{service_client.client.name}</Col>
                        </Row>}

                    <Row className='my-1'>
                        <Col>
                            <Table className='table-hover table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th colSpan={5} className="text-center">LISTADO DE PRECIOS</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th colSpan={2} className="text-center">DÍA</th>
                                        <th colSpan={2} className="text-center">NOCHE</th>
                                    </tr>
                                    <tr>
                                        <th>Categoria</th>
                                        <th>Costo</th>
                                        <th>Valor</th>
                                        <th>Costo</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {service_price.map((p, k) => <ItemTableService item={p} key={k} />)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        </Col>
    )
}

export default ItemCardService;