import axios from 'axios'
import moment from 'moment';
import { getHeaders } from '../utils';
import { logOutAction } from './userDuck';
const API_URL = process.env.REACT_APP_API_URL
const API_URL_SOCKET = process.env.REACT_APP_SOCKET_URL


let initialState = {
    fetching: 0,
    fetching_payment: 0,
    fetching_frecuent: 0,
    fetching_bank: 0,
    data: [],
    data_payment: [],
    data_bank: [],
}

let REVIEW = "REVIEW";
let REVIEW_SUCCESS = "REVIEW_SUCCESS";
let PAYMENT = "PAYMENT";
let PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
let FRECUENT_CLIENT = "FRECUENT_CLIENT";
let FRECUENT_CLIENT_SUCCESS = "FRECUENT_CLIENT_SUCCESS";
let BANK = "BANK";
let BANK_SUCCESS = "BANK_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REVIEW:
            return { ...state, fetching: 1 }
        case REVIEW_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case PAYMENT:
            return { ...state, fetching_payment: 1 }
        case PAYMENT_SUCCESS:
            return { ...state, fetching_payment: 2, data_payment: action.payload }
        case FRECUENT_CLIENT:
            return { ...state, fetching_frecuent: 1 }
        case FRECUENT_CLIENT_SUCCESS:
            return { ...state, fetching_frecuent: 2, data: action.payload }
        case BANK:
            return { ...state, fetching_bank: 1, data_bank: [] }
        case BANK_SUCCESS:
            return { ...state, fetching_bank: 2, data_bank: action.payload }
        default:
            return state;
    }
}

export let getReview = (form) => (dispatch, getState) => {

    dispatch({
        type: REVIEW
    })

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let method_payment_id = 0
    let category_id = 0
    let turn_id = 0

    if (form !== undefined) {
        date_init = form.date_init
        date_end = form.date_end
        method_payment_id = form.method_payment_id
        category_id = form.category_id
        turn_id = form.turn_id
    }


    let branch = JSON.parse(localStorage.getItem("branch"))

    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/report?date_init=${date_init}&date_end=${date_end}&branch_id=${branch.id}&method_payment_id=${method_payment_id}&category_id=${category_id}&turn_id=${turn_id}`
        console.log('url', url);

        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: REVIEW_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}


export let getPaymentByTurn = (form) => (dispatch, getState) => {

    dispatch({
        type: PAYMENT
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-payment-by-turn?cashier_id=${form.turn_id}&administrator_id=${form.administrator_id}&method_payment_id=${form.method_payment_id}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: PAYMENT_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }

            });
    } else {
        console.log("no Ingreso");
    }
}

export let getOrderByClient = (form) => (dispatch, getState) => {

    dispatch({
        type: PAYMENT
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-by-client?client_id=${form.client_id}&date_init=${form.date_init}&date_end=${form.date_end}&is_detail=${form.is_detail}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: PAYMENT_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }

            });
    } else {
        console.log("no Ingreso");
    }
}
export let getOrderByVehicle = (form) => (dispatch, getState) => {

    dispatch({
        type: PAYMENT
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-by-vehicle?client_id=${form.client_id}&date_init=${form.date_init}&date_end=${form.date_end}&is_detail=${form.is_detail}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: PAYMENT_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }

            });
    } else {
        console.log("no Ingreso");
    }
}

export let getFrecuentClient = (form) => (dispatch, getState) => {

    dispatch({
        type: PAYMENT
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-frecuent-client?client_id=${form.client_id}&date_init=${form.date_init}&date_end=${form.date_end}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: PAYMENT_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }

            });
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewBank = (form) => (dispatch, getState) => {

    dispatch({
        type: BANK
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-bank?date_init=${form.date_init}&date_end=${form.date_end}&method_payment_id=${form.method_payment_id}&turn_id=${form.turn_id}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: BANK_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                // if (status === 401) {
                //     logOutAction()(dispatch, getState)
                // }

            });
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewInsumo = (form) => (dispatch, getState) => {

    dispatch({
        type: BANK
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-insumo?date_init=${form.date_init}&date_end=${form.date_end}&turn_id=${form.turn_id}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: BANK_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                // if (status === 401) {
                //     logOutAction()(dispatch, getState)
                // }

            });
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewCredit = (form) => (dispatch, getState) => {

    dispatch({
        type: BANK
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-credit?date_init=${form.date_init}&date_end=${form.date_end}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: BANK_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                // if (status === 401) {
                //     logOutAction()(dispatch, getState)
                // }

            });
    } else {
        console.log("no Ingreso");
    }
}


export let getReviewCxc = (form) => (dispatch, getState) => {

    dispatch({
        type: BANK
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-cxc?date_init=${form.date_init}&date_end=${form.date_end}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: BANK_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                // if (status === 401) {
                //     logOutAction()(dispatch, getState)
                // }

            });
    } else {
        console.log("no Ingreso");
    }
}


export let getReviewSaleServices = (form) => (dispatch, getState) => {

    dispatch({
        type: BANK
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-sale-services?date_init=${form.date_init}&date_end=${form.date_end}&turn_id=${form.turn_id}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: BANK_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                // if (status === 401) {
                //     logOutAction()(dispatch, getState)
                // }

            });
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewParking = (form) => (dispatch, getState) => {

    dispatch({
        type: BANK
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/report-parking?date_init=${form.date_init}&date_end=${form.date_end}`,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: BANK_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(error => {
                console.log('error', error)
                let { status } = error.response;

                // if (status === 401) {
                //     logOutAction()(dispatch, getState)
                // }

            });
    } else {
        console.log("no Ingreso");
    }
}