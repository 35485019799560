import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createCategory } from '../../Redux/categoryDuck'


const initState = { price: '', is_minutes: true };

const ModalCategoryPrice = ({ show, onHide, errors, category, createUpdatePrice }) => {

    const [form, setForm] = useState(initState)

    useEffect(() => {
        if (category.category_price !== null && category.category_price !== undefined) {
            setForm(category.category_price)
        } else {
            setForm(initState)
        }
    }, [category])

    const updateInput = (e) => {
        console.log('e.target.type', e.target.type);

        if (e.target.type === 'checkbox') {

            console.log('e.target.checked', e.target.checked);

            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }

    }
    const onSubmit = () => {
        createUpdatePrice(form)
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Categoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Precio</label>
                                        <InputField
                                            type={"number"}
                                            placeholder="Precio"
                                            value={form.price || ''}
                                            name="price"
                                            onChange={updateInput}
                                            error={errors.price}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>{form.is_minutes === true ? 'Por Minutos' : 'Tarifa Plena'}</label>
                                        <input type={"checkbox"} className="form-control" name='is_minutes' checked={form.is_minutes} onChange={updateInput} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    return {
        errors: props.category.errors,
        selected: props.category.selected,
        fetching: props.category.fetching_create
    }
}

export default connect(mapsState, { createCategory })(ModalCategoryPrice);