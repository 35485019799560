import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getListBriefcaseById, onFirmBriefcase } from '../../Redux/briefcaseDuck'
import { connect } from 'react-redux'
import { Card, Col, Row, Table } from 'react-bootstrap'
import moment from 'moment'
import { formatNumber } from '../../utils'
import InputField from '../Components/Widgets/InputField'
import { toogleAlert } from '../../Redux/alertDuck'
import chooseimage from "../../Images/no_available.png";


const API_URL_SOCKET = process.env.REACT_APP_API_URL

const EditBriefcasePage = ({ getListBriefcaseById, fetching_by_id, row, onFirmBriefcase, fetching,
    toogleAlert
}) => {
    let { id } = useParams()

    const history = useHistory();

    const [data, setData] = useState({})
    const [form, setForm] = useState({
        concept: '',
        registration_date: moment().format("YYYY-MM-DD"),
        image: '', bank_account: ''
    })
    const [listOrder, setListOrder] = useState([])
    const [listProduct, setListProduct] = useState([])
    const [src, setSrc] = useState('');
    const [image, setImage] = useState('')


    useEffect(() => {
        toogleAlert(true)
        getListBriefcaseById(id)
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setSrc(`${API_URL_SOCKET}/api/briefcase-view-pdf/${id}?timestamp=${new Date().getTime()}`)
            setForm(row)
            setData(row)
            setListOrder(row.briefcase_detail)
            setListProduct(row.detail_products)
            toogleAlert(false)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching === 2) {
            getListBriefcaseById(id)
        }
    }, [fetching])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateImage = (e) => {
        setForm({ ...form, image: e.target.files[0] });

        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result)

            }
        };
        reader.readAsDataURL(file);
    }

    const onSave = () => {
        toogleAlert(true)
        let _form = form
        _form.id = id;
        onFirmBriefcase(form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col>
                    <button className='btn btn-sm btn-secondary' onClick={() => history.push("/cartera")}>Volver</button>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h4>Ordenes Realizadas, #{form.consecutive}</h4>
                                </Col>
                                <Col className='text-right'>
                                    <button className='btn btn-info btn-sm' onClick={onSave}>Guardar</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Fecha</label>
                                        <InputField
                                            type={"date"}
                                            placeholder="Fecha"
                                            value={form.registration_date}
                                            name="registration_date"
                                            onChange={updateField}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Concepto</label>
                                        <textarea
                                            placeholder="Concepto"
                                            className='form-control'
                                            value={form.concept}
                                            name="concept"
                                            onChange={updateField}
                                        ></textarea>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Cuenta de Banco</label>
                                        <InputField
                                            placeholder="# de Cuenta de Banco"
                                            value={form.bank_account}
                                            name="bank_account"
                                            onChange={updateField}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>Firma</Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <div className="form-group">
                                                <label htmlFor="chooseimage" className="uploadfile">
                                                    {form.image !== "" ? (
                                                        <img src={image} className="img-fluid" />
                                                    ) : (
                                                        <img src={chooseimage} className="img-fluid" />
                                                    )}
                                                </label>
                                                <input
                                                    type="file"
                                                    name="photo"
                                                    className="form-control"
                                                    onChange={updateImage}
                                                    id="chooseimage"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className='my-3'>
                                        <Col><h4>LISTADO DE SERVICIOS</h4></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>
                                                <thead>
                                                    <tr className='thead-dark'>
                                                        <th>Fecha</th>
                                                        <th>Orden</th>
                                                        <th>Placa</th>
                                                        <th>Categoria</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listOrder.map((b, k) => <tr key={k}>
                                                        <td>{moment(b.order.finish_at).format("YYYY-MM-DD")}</td>
                                                        <td>{b.order.id}</td>
                                                        <td>{b.order.plate}</td>
                                                        <td>{b.order.category.title}</td>
                                                        <td align='right'>{formatNumber(parseFloat(b.order.total) + parseFloat(b.order.total_product))}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={4}>TOTAL</th>
                                                        <th className='text-right'>{formatNumber(listOrder.reduce((a, b) => a + (parseFloat(b.order.total) + parseFloat(b.order.total_product)), 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className='my-3'>
                                        <Col><h4>LISTADO DE PRODUCTOS</h4></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>
                                                <thead>
                                                    <tr className='thead-dark'>
                                                        <th>Fecha</th>
                                                        <th>Orden</th>
                                                        <th>Producto</th>
                                                        <th>Cant</th>
                                                        <th>Precio</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listProduct.map((b, k) => <tr key={k}>
                                                        <td>{moment(b.registraton_date).format("YYYY-MM-DD")}</td>
                                                        <td>{b.order_id}</td>
                                                        <td>{b.product}</td>
                                                        <td align='right'>{b.amount}</td>
                                                        <td align='right'>{b.price_formated}</td>
                                                        <td align='right'>{formatNumber(parseFloat(b.total))}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={5}>TOTAL</th>
                                                        <th className='text-right'>{formatNumber(listProduct.reduce((a, b) => a + (parseFloat(b.total)), 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <iframe src={src} width="100%" height={700} title='PDF'></iframe>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { briefcase } = props

    return {
        fetching_by_id: briefcase.fetching_by_id,
        fetching: briefcase.fetching,
        row: briefcase.row,
    }
}
export default connect(mapsState, { getListBriefcaseById, onFirmBriefcase, toogleAlert })(EditBriefcasePage);