import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getInventoryCounter, updateInventoryCounter } from '../../Redux/inventoryDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import ItemTableCounter from './item.table.counter';

const InventoryCounterPage = ({ getInventoryCounter, fetching_list, data, updateInventoryCounter }) => {

    const [list, setList] = useState([]);
    const [form, setForm] = useState({
        product_id: 0,
        registration_date: moment().format("YYYY-MM-DD"),
        daily: false,
        week: false,
        month: false,
    });
    const [listFilter, setListFilter] = useState([]);
    const [listControl, setListControl] = useState([]);

    useEffect(() => {
        getInventoryCounter(form)
    }, [])


    useEffect(() => {
        if (fetching_list === 2) {
            setList(data);
            setListFilter(data);
        }
    }, [fetching_list]);

    const updateInput = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(_form);
        getInventoryCounter(_form);
    };

    const updateCheckBox = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.checked,
        };
        setForm(_form);
        getInventoryCounter(_form);
    };

    const onUpdateItem = (row, amount) => {
        let _form = row;

        if (amount !== "") {
            _form.amount = amount;
            _form.registration_date = form.registration_date;

            updateInventoryCounter(_form);
        }
    };

    const onSearch = (e) => {
        let _list = list;

        _list = list.filter((row) => {
            if (row.product.toLowerCase().includes(e.target.value)) {
                return true;
            }
            return false;
        });

        setListFilter(_list);
    };

    const onSearchFilter = () => {
        getInventoryCounter(form);
    };

    return (
        <TemplateMenu>
            <Row className="my-3">
                <Col>
                    <h2>Conteo de Inventario</h2>
                </Col>
            </Row>
            <Row className="my-2 d-flex justify-content-center">
                <Col lg={11}>
                    <Row>
                        <Col lg={3}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Producto</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={form.product}
                                    name="product"
                                    placeholder="Nombre"
                                    onChange={onSearch}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={form.registration_date}
                                    name="registration_date"
                                    onChange={updateInput}
                                />
                            </Form.Group>
                        </Col>
                        {/* {listControl.map((d,k)=><Col key={k} lg={2}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{d.description}</Form.Label>
                                <Form.Control type="checkbox" value={form.daily} checked={form.daily} name="daily" onChange={updateCheckBox}/>
                            </Form.Group>
                        </Col>)} */}

                        <Col lg={1}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Diario</Form.Label>
                                <Form.Control
                                    type="checkbox"
                                    value={form.daily}
                                    checked={form.daily}
                                    name="daily"
                                    onChange={updateCheckBox}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={1}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Semanal</Form.Label>
                                <Form.Control
                                    type="checkbox"
                                    value={form.week}
                                    checked={form.week}
                                    name="week"
                                    onChange={updateCheckBox}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={1}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Mensual</Form.Label>
                                <Form.Control
                                    type="checkbox"
                                    value={form.month}
                                    checked={form.month}
                                    name="month"
                                    onChange={updateCheckBox}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2} className="d-flex align-items-center">
                            <button className="btn btn-sm btn-info" onClick={onSearchFilter}>
                                Buscar
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={11}>
                    <Table className="table-bordered table-sm">
                        <thead className="thead-dark">
                            <tr>
                                <th rowSpan={2}>Producto</th>
                                <th rowSpan={2}>Medida</th>
                                <th rowSpan={2}>Tipo de Control</th>
                                <th rowSpan={2} className="text-center">
                                    Unidades
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listFilter.length > 0 ? (
                                listFilter.map((row, k) => (
                                    <ItemTableCounter row={row} key={k} onUpdate={onUpdateItem} />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={12} align="center">
                                        No se encontraron registros
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory } = props;

    return {
        fetching_list: inventory.fetching_list,
        data: inventory.data,
    }
}

export default connect(mapsState, { getInventoryCounter, updateInventoryCounter })(InventoryCounterPage);