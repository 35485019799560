import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createOrUpdateBusiness, updateBusiness } from '../../Redux/businessDuck'
import { getBranchOfficeByBusiness } from '../../Redux/branch.officeDuck'

const ModalBusiness = ({ showModal, onHide, errors, formEdit, fetching, createOrUpdateBusiness, updateBusiness, onSuccess }) => {
    const initState = { business: '', business_name: '', document: '', email: '', phone: '', start_day: '', end_day: '', start_night: '', end_night: '' };

    const [form, setForm] = useState(initState)


    useEffect(() => {

        if (fetching === 2) {
            onHide()
            onSuccess()
        }
    }, [fetching])

    useEffect(() => {

        if (formEdit !== undefined) {
            if (Object.keys(formEdit).length > 0) {
                setForm(formEdit);
            } else {
                if (fetching !== 3 && fetching !== 2) {
                    setForm(initState)
                }
            }
        }
    }, [formEdit])

    const closeModalTmp = () => {
        setForm(initState)
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {

        if (form.id !== undefined) {
            updateBusiness(form)
        } else {
            createOrUpdateBusiness(form)
        }
    }

    return (
        <Modal show={showModal} onHide={closeModalTmp} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Empresas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Alias</label>
                                <InputField
                                    placeholder="Agrega el Alias"
                                    value={form.business || ''}
                                    name="business"
                                    onChange={updateInput}
                                    error={errors.business}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Razón Social</label>
                                <InputField
                                    placeholder="Agrega la Razon social"
                                    value={form.business_name || ''}
                                    name="business_name"
                                    onChange={updateInput}
                                    error={errors.business_name}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Documento</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega el documento"
                                    value={form.document || ''}
                                    name="document"
                                    onChange={updateInput}
                                    error={errors.document}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Telefono</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega el telefono"
                                    value={form.phone || ''}
                                    name="phone"
                                    onChange={updateInput}
                                    error={errors.phone}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Email</label>
                                <InputField
                                    placeholder="Agrega el Email"
                                    value={form.email || ''}
                                    name="email"
                                    onChange={updateInput}
                                    error={errors.email}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status || false}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Fin Día</label>
                                <InputField
                                    type={"time"}
                                    placeholder="Inicio dia"
                                    value={form.end_day || ''}
                                    name="end_day"
                                    onChange={updateInput}
                                    error={errors.end_day}
                                />
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Fin Noche</label>
                                <InputField
                                    type={"time"}
                                    placeholder="Inicio dia"
                                    value={form.end_night || ''}
                                    name="end_night"
                                    onChange={updateInput}
                                    error={errors.end_night}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>

                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={closeModalTmp}>Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { business } = props
    return {
        errors: business.errors,
        selected: business.selected,
        fetching: business.fetching_create,
        business: business.data,
    }
}

export default connect(mapsState, { createOrUpdateBusiness, updateBusiness, getBranchOfficeByBusiness })(ModalBusiness);