import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createBrandModel } from '../../Redux/brandModelDuck'

const initState = { description: '' };

const ModalCreateModel = ({ show, brandModel, onHide, createBrandModel, fetching_create, onSuccess }) => {
    const [form, setForm] = useState(initState)

    useEffect(() => {
        if (fetching_create === 2) {
            onSuccess();
            setForm(initState)
        }
    }, [fetching_create])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        let _form = form
        _form.model_year_id = brandModel.id

        createBrandModel(_form)
    }


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Modelo a ({brandModel.year})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Descripción Modelo</label>
                            <input className='form-control' name='description' onChange={updateInput} value={form.description} placeholder="Descripción" />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Agregar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { brandModel } = props

    return {
        fetching_create: brandModel.fetching_create
    }
}

export default connect(mapsState, { createBrandModel })(ModalCreateModel);