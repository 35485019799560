import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logOutAction } from '../Redux/userDuck'
import logo_madera from '../Images/logo.png'
import { onGetOrSetFilter } from '../Redux/menuDuck'
import PaymentBar from './PaymentBar';



const MenuTemplate = ({ logOutAction, fetching_menu, menu, data, children, fetching_branch, branch_offices, branch_selected, fetching_dates, data_dates,
    onGetOrSetFilter, fetching_me }) => {

    const history = useHistory();
    const [listMenu, setListMenu] = useState([])
    const [listBranch, setListBranch] = useState([])
    const [branch, setBranch] = useState(0);
    const [listDates, setListDates] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(0)
    const [isPayment, setIsPayment] = useState(true)

    useEffect(() => {
        if (fetching_menu === 2) {
            setListMenu(menu)
        }
    }, [fetching_menu])

    useEffect(() => {
        if (fetching_me === 2) {
            let { plan_business } = data;
            let { payment } = plan_business;

            if (payment === null || payment.status_id === 2) {
                history.push("/expired-plan")
            }
        }
    }, [fetching_me])

    useEffect(() => {

        if (fetching_branch === 2) {

            let _bra = branch_offices.filter(r => r.selected === true)[0]
            let branch = '';

            if (branch_selected !== null) {
                setBranch(branch_selected.id)
                branch = branch_selected
                // onGetOrSetFilter(branch)

            } else {
                setBranch(_bra.id)
                branch = _bra
                // onGetOrSetFilter(branch)
            }

            setListBranch(branch_offices)
        }


    }, [fetching_branch])


    useEffect(() => {
        if (fetching_dates === 2) {

            let _date = data_dates.filter(r => r.selected === true)[0];

            if (localStorage.date === undefined) {
                localStorage.date = JSON.stringify(_date)
                setSelectedMonth(_date.id)
            } else {
                let _select = JSON.parse(localStorage.date);

                data_dates = data_dates.map(d => {
                    d.selected = false

                    if (_select.id === d.id) {
                        d.selected = true;
                    }

                    return d;
                });


                setSelectedMonth(_select.id)
            }
            setListDates(data_dates);
        }
    }, [fetching_dates])


    const logOut = () => {
        logOutAction();
        history.push("/login")
    }

    const onFilterBranch = (e) => {
        if (e.target.value !== 0) {

            setBranch(e.target.value)
            let _bra = branch_offices.filter(r => parseInt(r.id) === parseInt(e.target.value))[0]
            onGetOrSetFilter(_bra)
        } else {
            onGetOrSetFilter()
        }
    }

    const onChangeDate = (e) => {
        let _date = data_dates.filter(r => r.id === parseInt(e.target.value))[0];
        setSelectedMonth(_date.id)

        localStorage.date = JSON.stringify(_date)

        window.location.reload()
    }

    const printSubmenu = (item, i) => {
        return <Link className='dropdown-item' to={item.link} key={i}>{item.title}</Link>

    }

    const printMenu = (item, i) => {

        let { children } = item

        return (children.length > 0) ?
            <NavDropdown title={item.title} key={i} id="basic-nav-dropdown">
                {children.map(printSubmenu)}
            </NavDropdown>
            : <Nav.Link href="#" key={i}>{item.title}</Nav.Link>

    }


    return (
        <>
            {/* <PaymentBar user={data} isPayment={isPayment} onCloseSession={logOutAction} /> */}

            {/* <Navbar bg="light" variant="light" expand="lg" fixed='top' className={`${isPayment ? '' : 'mt-5'}`} > */}
            <Navbar bg="light" variant="light" expand="lg" className={`${isPayment ? '' : ''}`} >
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img src={logo_madera} className="img-fluid img-menu pr-2" alt="mana_logo" onClick={() => history.push("/")} />
                        {(data.branch_office !== undefined) && data.branch_office.title}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {listMenu.map(printMenu)}
                        </Nav>
                        <Form className="form-input">
                            <Row>
                                <Col xs={12} lg={6} className='mt-1'>
                                    {(data.role_id === 1 || data.role_id === 2 || data.role_id === 5) && <select className='form-control' name="branch_filter" value={branch} onChange={onFilterBranch}>
                                        <option value={0}>VER TODOS</option>
                                        {branch_offices.map(r => <option key={r.id} value={r.id}>{r.description}</option>)}
                                    </select>}
                                </Col>
                                <Col className='mt-1'>
                                    <select className='form-control' value={selectedMonth} onChange={onChangeDate}>
                                        {listDates.map(r => <option key={r.id} value={r.id}>{r.month_text}</option>)}
                                    </select>
                                </Col>
                            </Row>
                        </Form>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            Signed in as:
                        </Navbar.Text>
                        <NavDropdown title={data.name} id="basic-nav-dropdown">
                            <NavDropdown.Item href="/perfil">Perfil</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={logOut}>
                                Cerrar Sesión
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* <Container fluid className={`${isPayment ? 'mt-5 pt-4' : 'mt-5 pt-6'}`}> */}
            <Container fluid >
                {children}
            </Container>
        </>
    )
}

const mapsState = (props) => {
    let { user, menu, branch_office } = props

    return {
        data: user.data,
        menu: menu.data,
        fetching_menu: menu.fetching,
        fetching_branch: branch_office.fetching_list,
        branch_selected: branch_office.branch,
        branch_offices: branch_office.data,
        fetching_dates: user.fetching_dates,
        data_dates: user.data_dates,
        fetching_me: user.fetching_me,

    }
}

export default connect(mapsState, { logOutAction, onGetOrSetFilter })(MenuTemplate);