import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../Images/logo.png'


const LoaderPage = ({ show }) => {
    return (
        <Container fluid className={`loader ${show === true ? '' : 'd-none'}`}>
            <Row className='d-flex justify-content-center align-items-center w-100'>

                <Col lg={2} className="text-center">
                    <img src={logo} className="img-fluid" />
                    <h3 className='text-center text-white mt-3'>Espere por Favor ...</h3>
                </Col>
            </Row>
        </Container>
    )
}

export default LoaderPage;