import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import RowTableClient from './row.table.client';
import { connect } from 'react-redux';

import { getAllClients } from '../../Redux/clientDuck'

import { toogleAlert } from '../../Redux/alertDuck'
import { Row, Col, Container } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { Link } from 'react-router-dom';

const PublicityPage = ({ getAllClients, data, fetching, toogleAlert, current_page, last_page, fetching_search }) => {

    const [list, setList] = useState([])
    const observer = useRef()
    const [status, setStatus] = useState(0)
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState({ name: '', phone: '' })


    useEffect(() => {
        getAllClients()
        toogleAlert(true)
    }, [])


    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)

            setList(data)
        }
    }, [fetching])


    const onEdit = (row) => {
        setModal(true)
        setForm(row)
    }

    const onSuccess = () => {
        setModal(false)
        getAllClients()

    }

    return (
        <TemplateMenu>
            <Row className="my-3">
                <Col lg={6} className="pl-2">
                    <Link className='btn btn-sm btn-success' to={`/publicidad/new`}>Crear Campaña</Link>
                </Col>
                <Col lg={6} className="pr-4 justify-content-end">
                </Col>
            </Row>

            <Row>
                <Col>
                    <table className="table table-sm table-hover table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Titulo</th>
                                <th>Correo Electrónico</th>
                                <th>No. Celular</th>
                                <th>Documento</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>

                        </tbody>


                    </table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { clients, fetching_client, current_page, last_page, fetching_search } = props.client

    return {
        data: clients,
        fetching: fetching_client,
        current_page,
        last_page,
        fetching_search
    }
}

export default connect(mapsState, { getAllClients, toogleAlert })(PublicityPage);