import axios from 'axios'
import { getHeaders, removeSession, validateProduct } from '../utils';
const API_URL = process.env.REACT_APP_API_URL
const API_URL_SOCKET = process.env.REACT_APP_SOCKET_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_edit: 0,
    fetching_by_id: 0,
    fetching_delete: 0,
    data: [],
    selected: {},
    errors: {}
}

let SERVICE = "SERVICE";
let SERVICE_SUCCESS = "SERVICE_SUCCESS";
let SERVICE_ERROR = "SERVICE_ERROR";
let SERVICE_LIST = "SERVICE_LIST";
let SERVICE_LIST_SUCCESS = "SERVICE_LIST_SUCCESS";
let SERVICE_ID = "SERVICE_ID"
let SERVICE_ID_SUCCESS = "SERVICE_ID_SUCCESS"
let INIT_SERVICE = "INIT_SERVICE"
let LOSE_SESSION = "LOSE_SESSION"
let DELETE_SERVICE = "DELETE_SERVICE"
let DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
let DELETE_SERVICE_ERROR = "DELETE_SERVICE_ERROR"

let SERVICE_ALDELO = "SERVICE_ALDELO"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case INIT_SERVICE:
            return { ...state, fetching: 0, selected: {}, fetching_list: 0, data: [] }
        case SERVICE:
            return { ...state, fetching_edit: 1 }
        case SERVICE_SUCCESS:
            return { ...state, fetching_edit: 2, selected: action.payload }
        case SERVICE_ERROR:
            return { ...state, fetching_edit: 3, errors: action.payload, selected: action.form }
        case SERVICE_LIST:
            return { ...state, fetching_list: 1 }
        case SERVICE_LIST_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_edit: 0 }
        case SERVICE_ID:
            return { ...state, fetching_by_id: 1 }
        case SERVICE_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, selected: action.payload }
        case LOSE_SESSION:
            return { ...state, fetching: 4 }
        case DELETE_SERVICE:
            return { ...state, fetching_delete: 1 }
        case DELETE_SERVICE_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case DELETE_SERVICE_ERROR:
            return { ...state, fetching_delete: 3, msg: action.payload }
        default:
            return state;
    }
}

export let initService = () => (dispatch, getState) => {
    dispatch({
        type: INIT_SERVICE,
    })
}

export let createService = (form) => (dispatch, getState) => {

    dispatch({
        type: SERVICE
    })


    let val = validateProduct(form);


    if (Object.keys(val).length > 0) {
        dispatch({
            type: SERVICE_ERROR,
            payload: val
        })
        return;
    }


    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))
        form.branch_id = branch.id

        axios.post(`${API_URL}/api/services`, form, {
            headers: getHeaders()
        }).then(resp => {

            dispatch({
                type: SERVICE_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
                if ('priority' === key) {
                    err.priority = value[0]
                }
                if ('price' === key) {
                    err.price = value[0]
                }
                if ('category_id' === key) {
                    err.category_id = value[0]
                }

            }

            dispatch({
                type: SERVICE_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let updateService = (form) => (dispatch, getState) => {

    dispatch({
        type: SERVICE
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/services/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {

            dispatch({
                type: SERVICE_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
                if ('priority' === key) {
                    err.priority = value[0]
                }
                if ('price' === key) {
                    err.price = value[0]
                }
                if ('category_id' === key) {
                    err.category_id = value[0]
                }

            }

            dispatch({
                type: SERVICE_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}



export let getServiceProductById = (id) => (dispatch, getState) => {
    dispatch({
        type: SERVICE_ID,
        payload: []
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}api/products/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: SERVICE_ID_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}


export let getServices = (form) => (dispatch, getState) => {
    dispatch({
        type: SERVICE_LIST
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/services?category_id=${form.category_id}&allow=${form.allow}&branch=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: SERVICE_LIST_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getServicesByCategory = (category_id, client_id) => (dispatch, getState) => {
    dispatch({
        type: SERVICE_LIST
    })

    if (getState().user.loggedIn) {

        let { data } = getState().user
        let branch = JSON.parse(localStorage.getItem("branch"))


        axios.get(`${API_URL}/api/services/${category_id}/category-lite?client_id=${client_id}&branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: SERVICE_LIST_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteService = (product) => (dispatch, getState) => {
    dispatch({
        type: DELETE_SERVICE
    })

    if (getState().user.loggedIn) {
        axios.delete(`${API_URL}/api/services/${product.id}`, {
            headers: getHeaders()
        }).then(resp => {
            if (resp.data.ok === true) {
                dispatch({
                    type: DELETE_SERVICE_SUCCESS,
                    payload: resp.data
                });
            } else {
                dispatch({
                    type: DELETE_SERVICE_ERROR,
                    payload: resp.data.msg
                });
            }


        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}
