import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getEndInventory } from '../../Redux/inventoryDuck'

const EndMonthPage = ({ getEndInventory, fetching_end, data }) => {
    const [close, setClose] = useState({ days_purchases: '' });
    const [password, setPassword] = useState('')

    useEffect(() => {
        if (fetching_end === 2) {
            setClose(data)
        }
    }, [fetching_end])
    useEffect(() => {
        getEndInventory()
    }, [])

    const onCloseMonth = () => {

    }

    return (
        <TemplateMenu>
            <Row className='mt-3 d-flex justify-content-center'>
                <Col lg={4}><h3>Cierre de Mes</h3></Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={4}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>Días que compraste</Col>
                                <Col>{close.days_purchases}</Col>
                            </Row>
                            <Row>
                                <Col>Días de conteo de Inventorio</Col>
                                <Col>{close.days_counter}</Col>
                            </Row>
                            <Row>
                                <Col>Días de Bajas</Col>
                                <Col>{close.days_lowdown}</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2 d-flex justify-content-center'>
                <Col lg={4}>
                    <Row>
                        <Col><input placeholder='Clave' className='form-control' type={"password"} onChange={(e) => setPassword(e.target.value)} /></Col>
                        <Col>
                            <button className='btn btn-sm btn-success' onClick={onCloseMonth}>Cerrar Mes</button>
                        </Col>
                    </Row>

                </Col>

            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory } = props
    console.log('inventory', inventory);

    return {
        fetching_end: inventory.fetching_end,
        data: inventory.data,
    }
}

export default connect(mapsState, { getEndInventory })(EndMonthPage);