import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { onCreateTurn } from '../../Redux/turnDuck'
import { connect } from 'react-redux';

const ModalCreateTurn = ({ show, onHide, onCreateTurn, fetching_create, onSucces }) => {
    const [form, setForm] = useState({ open_cash: 0 })

    useEffect(() => {
        if (fetching_create === 2) {
            onSucces()
        }
    }, [fetching_create])

    const onSubmit = () => {
        onCreateTurn(form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Turno</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Monto</label>
                            <InputField
                                placeholder="Agrega un monto Inicial"
                                value={form.open_cash}
                                name="open_cash"
                                onChange={updateInput}

                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button onClick={onSubmit} className="btn btn-sm btn-success">Guardar</button>

                    </Col>
                    <Col>
                        <button onClick={onHide} className="btn btn-sm btn-danger">Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    )
}

const mapsState = (props) => {
    let { turn } = props
    return {
        fetching_create: turn.fetching_create,
    }
}

export default connect(mapsState, { onCreateTurn })(ModalCreateTurn);