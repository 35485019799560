import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemtablePrescription = ({ item }) => {

    return (
        <tr>
            <td>{''}</td>
            <td>{item.product}</td>
            <td align='right'>{formatNumber(item.quantity)}</td>
            <td align='right'>{formatNumber(item.total)}</td>
            <td><Link to={`/recetas/${item.id}`} className="btn btn-info btn-sm">Editar</Link></td>
        </tr>
    )
}

export default ItemtablePrescription;