import React, { useState } from 'react'
import { formatNumber } from '../../utils';
import { MdAddCircle, MdOutlineAddCircle, MdClose } from "react-icons/md";
import { Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SubItemTableOrder from './subitem.table.order';

const ItemTableOrderWorker = ({ item, onPaidEvent, form, onEditPercent, onOpenModalExpense, onDeleteRow, onChangePercent, turn_id }) => {
    let { detail, expense } = item
    const [show, setShow] = useState(false);

    const onShowDetail = () => {
        setShow(!show)
    }

    return (
        <>
            <tr>
                <td>{show === false ? <MdOutlineAddCircle className='cursor-pointer' onClick={onShowDetail} /> : <MdClose className='cursor-pointer' onClick={onShowDetail} />}</td>
                <td>{item.operator}</td>
                <td align='right'>{item.days_worked}</td>
                <td align='right'>{item.quantity}</td>
                <td align='right'>{formatNumber(item.subtotal)}</td>
                <td align='right'>{formatNumber(item.payment_worker)}</td>

                <td align='right'>{formatNumber(item.discount)}</td>
                <td align='right'>{formatNumber(item.tip)}</td>
                <td align='right'>{formatNumber(item.total)}</td>
                <td>
                    {form.status_id == 3 && <>
                        <button className='btn btn-sm btn-info mx-1' onClick={() => onPaidEvent(item)}>Pagar</button>
                        <button className='btn btn-sm btn-warning' onClick={() => onChangePercent(item)}>Cambio de %</button>
                    </>}
                    {form.status_id == 4 && <a className='btn btn-sm btn-danger'
                        target="_blank" href={`${process.env.REACT_APP_API_URL}/api/orders-operator-view/${item.id}/${form.date_init}/${form.date_end}/${turn_id}`}>
                        PDF</a>}
                </td>

            </tr>
            {show && <tr>
                <td colSpan={4}>
                    <Row>
                        <Col className='text-center'><h5>Servicios Realizados</h5></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table>

                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Orden</th>
                                        <th>Categoria</th>
                                        <th>Producto</th>
                                        <th>Tipo</th>
                                        <th>Valor</th>
                                        <th>Prop</th>
                                        <th>Precio</th>
                                        <th>SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail.map((d, k) => <SubItemTableOrder key={k} item={d} onEditPercent={onEditPercent} />)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={7}>SubTotal</th>
                                        <td align='right'>
                                            <b>{formatNumber(item.subtotal_real)}</b>
                                        </td>
                                        <td align='right'><b>{formatNumber(item.payment_worker_real)}</b></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>Total Descuentos</th>
                                        <td align='right'><b>{formatNumber(item.discount)}</b></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>Total Propinas</th>
                                        <td align='right'><b>{formatNumber(item.tip)}</b></td>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}>TOTAL A PAGAR</th>
                                        <td align='right'><b>{formatNumber(item.total)}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </td>
                <td colSpan={3}>
                    <Row>
                        <Col className='text-center'><h5>Anticipos<MdAddCircle className='cursor-pointer' onClick={() => onOpenModalExpense(item, 1)} /></h5> </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expense.filter(d => d.type_move_id === 1).length > 0 ? expense.filter(d => d.type_move_id === 1).map((d, k) => <tr key={k}>
                                        <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                        <td >{d.description}</td>
                                        <td align='right'>{formatNumber(d.price)}</td>
                                        <td align='right'><MdClose className='cursor-pointer' onClick={() => onDeleteRow(d)} /></td>
                                    </tr>) : <tr><td colSpan={2}>No hay datos</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <td align='right'><b>{formatNumber(expense.filter(d => d.type_move_id === 1).reduce((a, b) => a + parseFloat(b.price), 0))}</b></td>

                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </td>

                <td colSpan={3}>
                    <Row>
                        <Col className='text-center'><h5>Insumos<MdAddCircle className='cursor-pointer' onClick={() => onOpenModalExpense(item, 2)} /></h5> </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {expense.filter(d => d.type_move_id === 2).length > 0 ? expense.filter(d => d.type_move_id === 2).map((d, k) => <tr key={k}>
                                        <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                        <td >{d.description}</td>
                                        <td align='right'>{formatNumber(d.price)}</td>
                                        <td align='right'><MdClose className='cursor-pointer' onClick={() => onDeleteRow(d)} /></td>
                                    </tr>) : <tr><td colSpan={2}>No hay datos</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <td align='right'><b>{formatNumber(expense.filter(d => d.type_move_id === 2).reduce((a, b) => a + parseFloat(b.price), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </td>


            </tr>}
        </>
    )
}

export default ItemTableOrderWorker;