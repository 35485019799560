import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import TemplateMenu from '../../template/menu.template'
import { getListOrderById } from '../../Redux/orderDuck'
import ItemDetailService from './item.detail.service'
import { formatNumber } from '../../utils'
const API_URL = process.env.REACT_APP_API_URL


const EditOrderPage = ({ getListOrderById, fetching_by_id, row }) => {

    let { id } = useParams();
    const [data, setData] = useState({ client: { name: '' }, operator: { name: '' }, turn: { id: 0, user: { name: '' } } })
    const [detail, setDetail] = useState([])
    const [operadores, setOperadores] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [image, setImage] = useState('')

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            if (row.photos.length > 0) {
                setImage(row.photos[0].full_path)
            }

            setDetail(row.detail)
            setOperadores(row.operators)
            setListPayment(row.payments)
        }
    }, [fetching_by_id])

    useEffect(() => {
        getListOrderById(id)
    }, [id])

    console.log('data', data);


    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>Orden</Col>
                                <Col>{data.id}</Col>
                            </Row>
                            <Row>
                                <Col>Cliente</Col>
                                <Col>{data.client.name}</Col>
                            </Row>
                            <Row>
                                <Col>Fecha</Col>
                                <Col>{data.updated_at_text}</Col>
                            </Row>
                            <Row>
                                <Col>Observaciones</Col>
                                <Col>{data.observations}</Col>
                            </Row>
                            {operadores.map((d, k) => <Row key={k}>
                                <Col>Operador</Col>
                                <Col>{d.user.name}</Col>
                            </Row>)}

                            {data.status_id === 3 && <>
                                <Row>
                                    <Col>Fecha de Turno</Col>
                                    <Col>{data.turn.date_start}</Col>
                                </Row>

                                <Row>
                                    <Col>Usuario de Turno</Col>
                                    <Col>{data.turn.user.name} - ({data.turn.id})</Col>
                                </Row>
                            </>}

                            <Row>
                                <Col>Imagen</Col>
                                <Col><img src={image} className="img-order " /></Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col><h3>Servicios</h3></Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Table className='table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Servicio</th>
                                                <th>Costo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detail.map((d, k) => <ItemDetailService key={k} item={d} />)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>TOTAL</td>
                                                <td align='right'>{formatNumber(detail.reduce((a, b) => a = a + parseFloat(b.price), 0))}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col><h3>Pagos</h3></Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Table className='table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Método de Pago</th>
                                                <th>Propina</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listPayment.map((d, k) => <tr key={k}>
                                                <td>{d.payment.description}</td>
                                                <td align='right'>{d.tip}</td>
                                                <td align='right'>{d.total}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={2}>TOTAL</td>
                                                <td align='right'>{formatNumber(listPayment.reduce((a, b) => a = a + parseFloat(b.amount), 0))}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { order } = props;
    return {
        fetching_by_id: order.fetching_by_id,
        row: order.row,
    }
}

export default connect(mapsState, { getListOrderById })(EditOrderPage);


