import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import ItemCardService from '../Services/item.card.service';
import ModalService from '../Services/modal.service';
import { getServices, createService, updateService } from '../../Redux/serviceDuck'
import { connect } from 'react-redux';
import ModalServices from './modal.services';
import ModalDetail from './modal.detail';
import ItemCardServiceSpecial from './item.card.service.special';


const SpecialPrice = ({ getServices, client_id, fetching_list, data, createService, listServices, fetching_edit, updateService, listCategory }) => {


    const [modal, setModal] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [form, setForm] = useState({});
    const [list, setList] = useState([]);
    const [type, setType] = useState(1);

    useEffect(() => {
        getServices({ category_id: 0, allow: 0 })
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_edit === 2) {
            setModalDetail(false)
            setType(1)
        }
    }, [fetching_edit])


    const onEdit = (row) => {
        setType(2)
        setModalDetail(true);
        setForm(row.service);
    }

    const showModalTmp = () => {
        setModal(true);
        setForm({})
    }


    const onDelete = (item) => {
        if (window.confirm('¿Realmente deseas Eliminar Este Servicio?')) {
            // deleteService(item)
        }
    }

    const onCreate = (form) => {
        form.client_id = client_id

        if (type === 1) {
            createService(form)
        } else {
            updateService(form)
        }
    }

    const onAddService = (item) => {
        setType(1)
        setModal(false)
        setModalDetail(true)
        setForm(item)
    }


    return (
        <>
            <Row>
                <Col lg={2} md={4}>
                    <h4 className="font-title pt-3">SERVICIOS</h4>
                </Col>
                <Col lg={2} md={4}>
                    <button className='btn btn-sm btn-success' onClick={showModalTmp}>Crear</button>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Row>
                        {(listServices.length === 0)
                            ? <Col>No Tiene Servicios Preferenciales</Col>
                            : listServices.map((d, k) => <ItemCardServiceSpecial item={d} key={k} onEdit={onEdit} onDelete={onDelete} />)}
                    </Row>
                </Col>
            </Row>
            <ModalServices show={modal} listService={list} onHide={() => setModal(false)} onAddService={onAddService} />
            <ModalDetail show={modalDetail} formEdit={form} onHide={() => setModalDetail(false)} onCreate={onCreate} categories={listCategory} />
        </>
    )
}

const mapsState = (props) => {
    let { service } = props;

    return {
        fetching_list: service.fetching_list,
        fetching_edit: service.fetching_edit,
        data: service.data
    }
}

export default connect(mapsState, { getServices, createService, updateService })(SpecialPrice);