import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getAllClients } from '../../Redux/clientDuck'
import ModalOrderManual from './modal.manual';
import { getOperators } from '../../Redux/userDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { uploadDocument } from '../../Redux/orderDuck'
import { getCategories } from '../../Redux/categoryDuck'
import { formatNumber } from '../../utils';

const OrderManualPage = ({ getAllClients, fetching_client, data, getOperators, fetching_operator, data_operator, getParameterBySegment,
    fetching_segment, data_segment, getCategories, fetching_category, data_category, uploadDocument, fetching_document, documents }) => {
    const [list, setList] = useState([])
    const [form, setForm] = useState({ file: '' })
    const [listClient, setListClient] = useState([])
    const [listOperator, setListOperator] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [listCategory, setListCategory] = useState([])

    const [modal, setModal] = useState(false)

    useEffect(() => {
        getAllClients();
        getOperators();
        getParameterBySegment("payment")
        getCategories()
    }, [])

    useEffect(() => {
        if (fetching_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_category])

    useEffect(() => {
        if (fetching_document === 2) {
            setList(documents)
        }
    }, [fetching_document])

    useEffect(() => {
        if (fetching_segment === 2) {
            setListPayment(data_segment)
        }
    }, [fetching_segment])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator)
        }
    }, [fetching_operator])

    useEffect(() => {
        if (fetching_client === 2) {
            setListClient(data)
        }
    }, [fetching_client])

    const onSuccess = (item) => {
        setList([
            ...list,
            item
        ])
        setModal(false)
    }

    const updateFile = (e) => {
        // setForm({
        //     ...form,
        //     ["file"]: e.target.files[0]
        // })

        uploadDocument(e.target.files[0])
    }

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col lg={2}>Carga de Ordenes</Col>
                <Col lg={1}>
                    <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Subir uno a Uno</button>
                </Col>
                <Col lg={3}>
                    <input type={"file"} className='form-control' onChange={updateFile} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered table-hover'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha</th>
                                <th>Cliente</th>
                                <th>Placa</th>
                                <th>Operador</th>
                                <th>Categoria</th>
                                <th>Servicio</th>
                                <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map((d, k) => <tr key={k}>
                                <td>{d.registration_date}</td>
                                <td>{d.client.name}</td>
                                <td>{d.plate}</td>
                                <td>{d.operator.name}</td>
                                <td>{d.category.description}</td>
                                <td>{d.service.description}</td>
                                <td>{formatNumber(d.service.price)}</td>
                            </tr>) : <tr><td colSpan={6}>No tienes Datos</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <ModalOrderManual show={modal}
                payments={[]}
                onHide={() => setModal(false)} listClient={listClient}
                listOperator={listOperator}
                listPayment={listPayment}
                listCategory={listCategory}
                onSuccess={onSuccess}
            />

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { client, user, parameter, category, order } = props

    console.log('order', order);


    return {
        data: client.clients,
        fetching_client: client.fetching_client,
        data_operator: user.data_operator,
        fetching_operator: user.fetching_operator,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_category: category.fetching,
        data_category: category.data,
        fetching_document: order.fetching_document,
        documents: order.documents,
    }
}

export default connect(mapsState, { getAllClients, getOperators, getParameterBySegment, getCategories, uploadDocument })(OrderManualPage);