import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import TemplateMenu from '../../template/menu.template'

import { getRecurrentCost } from '../../Redux/expenseDuck'
import moment from 'moment';
import ModalExpenseRecurrent from './modal.expense.recurrent';
import { getParameterBySegment } from '../../Redux/parameterDuck';
import { formatNumber } from '../../utils';


const ExpenseRecurrentPage = ({ getRecurrentCost, fetching_list, data, fetching_segment, data_segment, getParameterBySegment, segment }) => {

    const [form, setForm] = useState({ date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 3 })
    const [list, setList] = useState([])
    const [modal, setModal] = useState(false)
    const [listExpense, setListExpense] = useState([])
    const [listType, setListType] = useState([])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_segment == 2) {
            if (segment === 'box') {
                setListExpense(data_segment);
                getParameterBySegment("recurrent")
            } else {
                setListType(data_segment)
            }

        }
    }, [fetching_segment])



    useEffect(() => {
        getRecurrentCost(form)
        getParameterBySegment("box")

    }, [])

    const onSuccess = () => {
        setModal(false)
        getRecurrentCost(form)
    }

    const onEdit = (row) => {
        setModal(true)

    }

    return (
        <TemplateMenu>
            <Row className='mt-4'>
                <Col lg={3}><h3>Gastos Recurrentes</h3></Col>
                <Col lg={2}>
                    <button className='btn btn-sm btn-success' onClick={() => setModal(true)}>Agregar</button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-3'>
                <Col lg={8}>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Descripción</th>
                                <th>Concepto</th>
                                <th>Valor</th>
                                <th>Cada Cuanto</th>
                                <th>Fecha Ultima Ejecución</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map(d =>
                                <tr key={d.id}>
                                    <td>{d.description}</td>
                                    <td>{d.concept.description}</td>
                                    <td>{formatNumber(d.cost)}</td>
                                    <td>{d.execution.description}</td>
                                    <td>{d.last_execution}</td>
                                    <td>{d.status}</td>
                                    <td>
                                        <button className="btn btn-sm btn-info" onClick={() => onEdit(d)}>Editar</button>
                                    </td>
                                </tr>
                            ) : <tr><td colSpan={5}>No hay Gastos</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <ModalExpenseRecurrent show={modal} listExpense={listExpense} listType={listType} onSuccess={onSuccess} onHide={() => setModal(false)} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {

    let { expense, parameter } = props

    return {
        fetching_list: expense.fetching_list,
        data: expense.data,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        segment: parameter.segment,
    }
}

export default connect(mapsState, { getRecurrentCost, getParameterBySegment })(ExpenseRecurrentPage);