import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import AsyncSelect from "react-select/async";
import { formatNumber, getHeaders } from '../../utils';
import RowAddProduct from './row.add.product';
import ItemtablePrescriptionRow from './item.table.prescription.row';
import uuid from 'react-uuid';
import { onEditPrescription, getPrescriptionById } from '../../Redux/prescriptionDuck'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FormPrescription from './form.prescription';

const API_URL = process.env.REACT_APP_API_URL;


const EditPrescriptionPage = ({ fetching_create, getPrescriptionById, fetching_by_id, row, onEditPrescription }) => {
    const [form, setForm] = useState({})
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        getPrescriptionById(id)
    }, [id])

    useEffect(() => {

        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    useState(() => {
        if (fetching_create === 2) {
            history.goBack()
        }
    }, [fetching_create])



    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={8}>
                    <FormPrescription formEdit={form} />
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { prescription } = props

    return {
        fetching_create: prescription.fetching_create,
        fetching_by_id: prescription.fetching_by_id,
        row: prescription.row,
    }
}

export default connect(mapsState, { onEditPrescription, getPrescriptionById })(EditPrescriptionPage);