
import moment from 'moment';
import { getHeaders } from '../utils'
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_active: 0,
    fetching_close: 0,
    fetching_reopen: 0,
    fetching_create: 0,
    data: [],
    row: {},
    errors: {}
}


const TURNS = "TURNS"
const TURNS_SUCCESS = "TURNS_SUCCESS"
const TURN_ACTIVE = "TURN_ACTIVE"
const TURN_ACTIVE_SUCCESS = "TURN_ACTIVE_SUCCESS"
const CLOSE_TURN = "CLOSE_TURN"
const CLOSE_TURN_SUCCESS = "CLOSE_TURN_SUCCESS"
const REOPEN_TURN = "REOPEN_TURN"
const REOPEN_TURN_SUCCESS = "REOPEN_TURN_SUCCESS"
const CREATE_TURN = "CREATE_TURN"
const CREATE_TURN_SUCCESS = "CREATE_TURN_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TURNS:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case TURNS_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case TURN_ACTIVE:
            return { ...state, fetching_active: 1 }
        case TURN_ACTIVE_SUCCESS:
            return { ...state, fetching_active: 2, row: action.payload }
        case CLOSE_TURN:
            return { ...state, fetching_close: 1 }
        case CLOSE_TURN_SUCCESS:
            return { ...state, fetching_close: 2 }
        case REOPEN_TURN:
            return { ...state, fetching_close: 1 }
        case REOPEN_TURN_SUCCESS:
            return { ...state, fetching_close: 2 }
        case CREATE_TURN:
            return { ...state, fetching_create: 1 }
        case CREATE_TURN_SUCCESS:
            return { ...state, fetching_create: 2 }
        default:
            return state;
    }
}

export let getTurns = () => (dispatch, getState) => {
    dispatch({
        type: TURNS
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        axios.get(`${API_URL}/api/turn?branch_id=${branch.id}&date_init=${date_init}&date_end=${date_end}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: TURNS_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getTurnActive = () => (dispatch, getState) => {
    dispatch({
        type: CLOSE_TURN
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        axios.get(`${API_URL}/api/turn-active?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: TURN_ACTIVE_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCloseTurn = (id) => (dispatch, getState) => {
    dispatch({
        type: CLOSE_TURN
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let form = { branch_id: branch.id };

        axios.put(`${API_URL}/api/turn/${id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CLOSE_TURN_SUCCESS,
            });
        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onReOpenTurn = (id) => (dispatch, getState) => {
    dispatch({
        type: REOPEN_TURN
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let form = { branch_id: branch.id };

        axios.put(`${API_URL}/api/turn-reopen/${id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: REOPEN_TURN_SUCCESS,
            });
        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateTurn = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_TURN
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        form.branch_id = branch.id
        form.date = moment().format("YYYY-MM-DD")

        axios.post(`${API_URL}/api/turn`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_TURN_SUCCESS,
            });
        }).catch(function (error) {

            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}