import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalPhotoTurnPage = ({ show, image, onHide }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Turno</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <img src={image} className="img-fluid" />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPhotoTurnPage;