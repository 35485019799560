import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onCreateClient } from '../../Redux/clientDuck'

const ModalClient = ({ show, onHide, onCreateClient, fetching_create, onSuccess, row }) => {
    const [form, setForm] = useState({ business: '', phone: '', email: '' })


    useEffect(() => {
        if (fetching_create === 2) {
            onSuccess(row)
        }
    }, [fetching_create])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onClick = () => {
        onCreateClient(form)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                Creación de Cliente
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Nombres y Apellidos</label>
                            <input className='form-control' onChange={updateField} name="business" placeholder='Nombres y Apellidos' />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Celular</label>
                            <input type={"number"} className='form-control' onChange={updateField} name="phone" placeholder='Celular' />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Email</label>
                            <input className='form-control' onChange={updateField} name="email" type={"email"} placeholder="Email" />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onClick}>Crear</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { client } = props
    return {
        fetching_create: client.fetching_create,
        row: client.row
    }
}

export default connect(mapsState, { onCreateClient })(ModalClient);