import React, { useState } from 'react'
import { formatNumber } from '../../utils';
import { AiFillCloseSquare, AiFillEdit } from "react-icons/ai";
import Select from 'react-select'


const ItemDetailPurchase = ({ item, onDelete, listProduct }) => {

    let { product } = item
    let cost = item.cost !== '' ? item.cost : product.cost
    const [form, setForm] = useState({ amount: '', cost: '', subtotal: '' })
    const [show, setShow] = useState(false)

    const onEdit = (row) => {
        setShow(true);
        setForm({
            ...form,
            ["amount"]: row.amount,
            ["cost"]: row.cost,
            ["subtotal"]: row.subtotal,
        })
    }
    const updateInput = (e) => {
        if (e.target.name === 'amount') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                ["cost"]: form.subtotal / e.target.value
            })
        }

        if (e.target.name === 'subtotal') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                ["cost"]: e.target.value / form.amount
            })
        }
    }

    const onSaveRow = () => {
        setShow(false);
        item.amount = form.amount
        item.cost = form.cost
        item.subtotal = form.cost * form.amount
    }

    return (
        <tr>
            <td>
                <Select
                    value={{ label: item.product.name, value: item.product.id }}
                    onChange={(e) => {

                    }}
                    options={listProduct}
                    placeholder="Escribe el nombre" />
            </td>
            <td align='right'>
                {!show ? item.amount : <input className='form-control' value={form.amount} name="amount" onChange={updateInput} />}

            </td>
            <td align='right'>
                {formatNumber(cost)}

            </td>
            <td align='right'>{product.tax.description}</td>
            <td align='right'>

                {!show ? formatNumber(item.subtotal) : <input className='form-control' value={form.subtotal} name="subtotal" onChange={updateInput} />}
            </td>
            <td>
                {!show ? <>
                    <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button>
                    <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(item)}>Borrar</button>
                </> :
                    <>
                        <button className='btn btn-success btn-info btn-sm' onClick={onSaveRow}>Guardar</button>
                        <button className='btn btn-danger btn-info btn-sm mx-2' onClick={() => setShow(false)}>Cancelar</button>
                    </>}

            </td>
        </tr>
    )
}

export default ItemDetailPurchase;