
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list_department: 0,
    fetching_list_city: 0,
    data: [],
    data_city: [],
    row: {},
    errors: {},
}


const LIST_DEPARTMENT = "LIST_DEPARTMENT"
const LIST_DEPARTMENT_SUCCESS = "LIST_DEPARTMENT_SUCCESS"
const LIST_CITY = "LIST_CITY"
const LIST_CITY_SUCCESS = "LIST_CITY_SUCCESS"



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_DEPARTMENT:
            return { ...state, fetching_list_department: 1 }
        case LIST_DEPARTMENT_SUCCESS:
            return { ...state, fetching_list_department: 2, data: action.payload }
        case LIST_CITY:
            return { ...state, fetching_list_city: 1 }
        case LIST_CITY_SUCCESS:
            return { ...state, fetching_list_city: 2, data_city: action.payload }
        default:
            return state;
    }
}

export let getListDepartment = () => (dispatch, getState) => {
    dispatch({
        type: LIST_DEPARTMENT
    })

    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/department`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {


            dispatch({
                type: LIST_DEPARTMENT_SUCCESS,
                payload: resp.results
            });

        })
    } else {
        console.log("no Ingreso");
    }

}

export let getListCity = (department_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_CITY
    })

    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/city-by_department/${department_id}`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {


            dispatch({
                type: LIST_CITY_SUCCESS,
                payload: resp.results
            });

        })
    } else {
        console.log("no Ingreso");
    }

}