// src/utils/indexedDB.js
import { openDB } from 'idb';

const DATABASE_NAME = 'x2';
const DATABASE_VERSION = 1;
const STORE_NAME = 'categories';

const initDB = async () => {
    return openDB(DATABASE_NAME, DATABASE_VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
            }
        }
    });
};

const addItem = async (table, item) => {
    const db = await initDB();
    const tx = db.transaction(table, 'readwrite');
    const store = tx.objectStore(table);
    await store.add(item);
    await tx.done;
};

const getItem = async (table, id) => {
    const db = await initDB();
    return db.get(table, id);
};

const getLocalItems = async (table) => {
    const db = await initDB();
    return db.getAll(table);
};

const updateItem = async (table, item) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.put(item);
    await tx.done;
};

const deleteItem = async (table, id) => {
    const db = await initDB();
    const tx = db.transaction(table, 'readwrite');
    const store = tx.objectStore(table);
    await store.delete(id);
    await tx.done;
};

export { addItem, getItem, getLocalItems, updateItem, deleteItem };
