import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalPaymentBriefcase = ({ show, onHide, onSubmit, listPayment, total }) => {

    const [method, setMethod] = useState(0)

    const onSubmitPre = () => {
        if (method === 0) {
            alert("Agrega un Pago")
        }

        onSubmit(method)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Total Pagado {total}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Método de Pago</label>
                            <select className='form-control' name="method_payment_id"
                                onChange={(e) => setMethod(e.target.value)}>
                                <option value={0}>Seleccione</option>
                                {listPayment.filter(d => d.id !== 40 && d.id !== 8).map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button onClick={onSubmitPre} className="btn btn-sm btn-success">Guardar</button>

                    </Col>
                    <Col>
                        <button onClick={onHide} className="btn btn-sm btn-danger">Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPaymentBriefcase;