import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import ItemTableSupplier from './item.table.supplier';
import ModalSupplier from './modal.supplier';
import { getListSuppplier } from '../../Redux/supplierDuck'


const SupplierPage = ({ getListSuppplier, fetching_list, data }) => {
    const [list, setList] = useState([])
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState({})

    useEffect(() => {
        getListSuppplier()
    }, [])

    useEffect(() => {
        if (fetching_list) {
            setList(data)
        }
    }, [fetching_list])

    const onSuccess = () => {
        setModal(false)
        getListSuppplier()
    }

    const onEdit = (item) => {
        setForm(item)
        setModal(true)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={8}>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={2} className='text-center'><h3>Proveedor</h3></Col>
                        <Col lg={1}><button className='btn btn-sm btn-success' onClick={() => setModal(true)}>Crear</button></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Alias</th>
                                        <th>Razón Social</th>
                                        <th>Nit</th>
                                        <th>Dirección</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{list.length > 0 ? list.map(d => <ItemTableSupplier key={d.id} item={d} onEdit={() => onEdit(d)} />)
                                    : <tr><td colSpan={5} align="center">No existen proveedores</td></tr>}</tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalSupplier show={modal} onHide={() => setModal(false)} onSuccess={onSuccess} formEdit={form} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { supplier } = props
    return {
        fetching_list: supplier.fetching_list,
        data: supplier.data
    }
}

export default connect(mapsState, { getListSuppplier })(SupplierPage);