import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getListOrderById } from '../../Redux/orderDuck'
import moment from 'moment';
import { Container } from 'react-bootstrap';

const OrderDetailPdf = ({ getListOrderById, fetching_by_id, row, fetching_me, data_me }) => {
    let { id } = useParams()
    const [data, setData] = useState({ client: { name: '' } })
    const [business, setBusiness] = useState({ business_name: '' })
    const [detail, setDetail] = useState([])
    const [photos, setPhotos] = useState([])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setDetail(row.detail)
            setPhotos(row.photos)
        }
    }, [fetching_by_id])
    useEffect(() => {
        if (fetching_me === 2) {
            setBusiness(data_me.business)
        }
    }, [fetching_me])

    useEffect(() => {
        getListOrderById(id)
    }, [id])



    return (
        <Container>
            <p>asdasd</p>

        </Container>
        // <PDFViewer style={{ width: "100%", height: '100vh' }}>
        //     <Document>
        //         <Page size="A4" style={styles.page}>
        //             <View style={styles.title}>
        //                 <Text>{business.business_name.toUpperCase()}</Text>
        //             </View>
        //             <View style={styles.subtitle}>
        //                 <Text>NIT: {business.document}</Text>
        //             </View>
        //             <View style={styles.subtitle}>
        //                 <Text>TELEONO: {business.phone}</Text>
        //             </View>
        //             <View style={styles.title}>
        //                 <Text>ORDER DE SERVICIO #{data.id}</Text>
        //             </View>
        //             <View style={styles.subtitle}>
        //                 <Text>CLIENTE:{data.client.name}</Text>
        //             </View>
        //             {data.observations !== '' && <View style={styles.subtitle}>
        //                 <Text>OBSERVACIONES:{data.observations}</Text>
        //             </View>}

        //             <View style={styles.subtitle}>
        //                 <Text>FECHA:{moment(data.finish_at).format("YYYY-MM-DD")}</Text>
        //             </View>
        //             <View style={styles.table}>
        //                 <Text>SERVICIO</Text>
        //                 <Text>COSTO</Text>
        //             </View>
        //             {detail.map((d, k) =>
        //                 <View style={styles.table_row} key={k}>
        //                     <Text style={styles.table_td}>{d.service.name}</Text>
        //                     <Text style={[styles.table_td, { textAlign: "right" }]}>{d.price_formated}</Text>

        //                 </View>
        //             )}
        //             {photos.map((d, k) =>
        //                 <View style={styles.section} key={k}>
        //                     <Image src={d.full_path} style={styles.image} />
        //                 </View>
        //             )}
        //         </Page>
        //     </Document>
        // </PDFViewer>
    )
}

const mapsState = (props) => {
    let { order, user } = props

    return {
        fetching_by_id: order.fetching_by_id,
        row: order.row,
        fetching_me: user.fetching_me,
        data_me: user.user,
    }
}

export default connect(mapsState, { getListOrderById })(OrderDetailPdf);