import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import logo_madera from '../../Images/logo.png'
import { connect } from 'react-redux';
import { getLiquidatePDF } from '../../Redux/orderDuck'
import moment from 'moment';
import { formatNumber } from '../../utils';
import { Container } from 'react-bootstrap';


const OrderDPFPage = ({ getLiquidatePDF, fetching_pdf, data_pdf }) => {


    const [list, setList] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [total, setTotal] = useState(0)
    const [subtotal, setSubTotal] = useState(0)
    const [worker, setWorker] = useState({})
    const [totalExpense, setTotalExpense] = useState(0)
    const [range, setRange] = useState({})

    let { id, date_init, date_end, cashier_id } = useParams()


    useEffect(() => {
        if (fetching_pdf === 2) {
            let { detail, total, subtotal, worker, range, expense, total_expense } = data_pdf
            setList(detail)
            setSubTotal(subtotal)
            setTotal(total)
            setWorker(worker)
            setRange(range)
            setListExpense(expense)
            setTotalExpense(total_expense)
        }
    }, [fetching_pdf])

    useEffect(() => {
        getLiquidatePDF(id, date_init, date_end, cashier_id)
    }, [id])


    return (
        <Container>
            pasdasd
        </Container>
        //     <PDFViewer style={{ width: "100%", height: '100vh' }}>
        //         <Document>
        //             <Page size="A4" style={styles.page}>
        //                 <View style={styles.title}>
        //                     <Text>LIQUIDACIÓN DE SERVICIOS</Text>
        //                 </View>
        //                 <View style={styles.title}>
        //                     <Image src={logo_madera} style={styles.image_logo} />
        //                 </View>
        //                 <View style={styles.section_worker}>
        //                     <Text style={styles.title_worker}>Operador:</Text>
        //                     <Text style={styles.subtitle_worker}>{worker.name}</Text>
        //                 </View>
        //                 <View style={[styles.section_worker, { paddingTop: 0 }]}>
        //                     <Text style={styles.title_worker}>Fecha:</Text>
        //                     <Text style={styles.subtitle_worker}>{range.date_init} a {range.date_end}</Text>
        //                 </View>
        //                 <View style={styles.section}>
        //                     <Text style={styles.subtitle}>SERVICIOS</Text>
        //                 </View>
        //                 <View style={styles.table}>
        //                     <Text>FECHA</Text>
        //                     <Text>CATEGORIA</Text>
        //                     <Text>SERVICIOS</Text>
        //                     <Text>PRECIO</Text>
        //                     <Text>SUBTOTAL</Text>
        //                 </View>
        //                 {list.map((d, k) =>
        //                     <View style={styles.table_row} key={k}>
        //                         <Text style={styles.table_td}>{moment(d.finish_at).format("YYYY-MM-DD")}</Text>
        //                         <Text style={styles.table_td}>{d.category}</Text>
        //                         <Text style={styles.table_td}>{d.service}</Text>
        //                         <Text style={[styles.table_td, { textAlign: "right" }]}>{formatNumber(d.price)}</Text>
        //                         <Text style={[styles.table_td, { textAlign: "right" }]}>{formatNumber(d.price_to_paid)}</Text>
        //                     </View>
        //                 )}
        //                 <View style={styles.table_footer}>
        //                     <Text style={styles.table_td}>TOTAL</Text>
        //                     <Text style={styles.table_td}></Text>
        //                     <Text style={styles.table_td}></Text>
        //                     <Text style={[styles.table_td, { textAlign: "right", fontWeight: 'bold' }]}>{formatNumber(subtotal)}</Text>
        //                     <Text style={[styles.table_td, { textAlign: "right", fontWeight: 'bold' }]}>{formatNumber(total)}</Text>
        //                 </View>

        //                 <View style={styles.section}>
        //                     <Text style={styles.subtitle}>DESCUENTOS</Text>
        //                 </View>
        //                 <View style={styles.table}>
        //                     <Text>FECHA</Text>
        //                     <Text>DESCRIPCIÓN</Text>
        //                     <Text>PRECIO</Text>
        //                 </View>
        //                 {listExpense.map((d, k) =>
        //                     <View style={styles.table_row} key={k}>
        //                         <Text style={styles.table_td}>{moment(d.created_at).format("YYYY-MM-DD")}</Text>
        //                         <Text style={styles.table_td}>{d.description}</Text>
        //                         <Text style={[styles.table_td, { textAlign: "right" }]}>{formatNumber(d.price)}</Text>
        //                     </View>
        //                 )}
        //                 <View style={styles.table_footer}>
        //                     <Text style={styles.table_td}>TOTAL</Text>
        //                     <Text style={styles.table_td}></Text>
        //                     <Text style={[styles.table_td, { textAlign: "right", fontWeight: 'bold' }]}>{formatNumber(totalExpense)}</Text>
        //                 </View>

        //                 <View style={styles.section}>
        //                     <Text style={styles.subtitle}>TOTAL A PAGAR</Text>
        //                     <Text style={styles.subtitle}>{formatNumber(total - totalExpense)}</Text>
        //                 </View>

        //             </Page>
        //         </Document>
        //     </PDFViewer >
    )
}

const mapsState = (props) => {
    let { order } = props
    return {
        fetching_pdf: order.fetching_pdf,
        data_pdf: order.data_pdf
    }
}

export default connect(mapsState, { getLiquidatePDF })(OrderDPFPage);