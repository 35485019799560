import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import ItemTablePayment from './item.table.payment';

const ModalPaymentCredit = ({ show, data, listMethods, onHide, onSuccess }) => {

    const [listPayment, setListPayment] = useState([])


    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setListPayment(data.payments)
        }

    }, [data])

    const [form, setForm] = useState({ payment_id: 0 })

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSuccessPre = () => {
        let validate = listPayment.filter(d => d.delete === undefined);

        if (validate.length === 0) {
            alert("No Tienes Pagos Asociados")
            return;
        }

        form.order_id = data.id
        form.payments = listPayment
        onSuccess(form)

    }


    const onDeleteRow = (row) => {
        let _list = listPayment.map(d => {
            if (row.id === d.id) {
                d.delete = true
            }

            return d;
        })

        setListPayment(_list)
    }



    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Cambio de Método de Pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Orden: #{data.id}</Col>
                </Row>
                <Row className='my-2'>
                    <Col>TOTAL PAGADO:</Col>
                    <Col className='text-right'>{formatNumber(data.total)}</Col>
                </Row>
                <Row>
                    <Col>
                        <Table className='table table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Método de pago</th>
                                    <th>Valor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>{listPayment.filter(d => d.delete === undefined).map((d, k) => <ItemTablePayment item={d} key={k}
                                onDeleteRow={onDeleteRow}
                                listMethods={listMethods} />)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>TOTAL</td>
                                    <td align='right'>{formatNumber(listPayment.filter(d => d.delete === undefined).reduce((a, b) => a + parseFloat(b.amount), 0))}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className='d-flex justify-content-end'>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSuccessPre}>Pagar</button>
                    </Col>
                </Row>
            </Modal.Footer>

        </Modal>
    )
}

export default ModalPaymentCredit;