import React from 'react';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL


const ItemTableCategory = ({ item, onDelete, onEdit }) => {
    let { category_price } = item
    let photo = API_URL + "/" + item.image;

    let price = category_price === null || category_price === undefined ? 0 : category_price.price;
    let type = category_price === null || category_price === undefined ? 'Sin Asignar' : category_price.is_minutes_text;


    return (
        <tr>
            <td>{item.title}</td>
            <td>{item.description}</td>
            <td>{item.priority}</td>
            <td>
                <img src={photo} className="img-icon" alt="imgen" />
            </td>
            <td align='right'>${formatNumber(price)}</td>
            <td>{item.status_text}</td>
            <td>{type}</td>
            <td>
                {/* <button className="btn btn-info btn-sm mx-1" onClick={onEdit}>Editar</button> */}
                <button className="btn btn-danger btn-sm" onClick={() => onDelete(item.id)}>Quitar</button>
            </td>

        </tr>
    )
}
export default ItemTableCategory; 