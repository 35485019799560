import React, { useState } from 'react'
import AsyncSelect from "react-select/async";
import { formatNumber, getHeaders } from '../../utils';

const API_URL = process.env.REACT_APP_API_URL;

const initState = { measure_unit: { description: '' }, cost_average: 0, quantity: '' }

const RowAddProduct = ({ onCreateRow, onCancel }) => {
    const [product, setProduct] = useState('')
    const [form, setForm] = useState(initState)

    const loadOptions = async (inputValue) => {
        let branch = JSON.parse(localStorage.getItem("branch"));
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

        let url = `${API_URL}/api/product-inventory-search/${inputValue}?branch=${branch.id}`;

        let _search = await fetch(url, {
            headers: getHeaders(),
        }).then((resp) => resp.json());

        return _search;
    };

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onCreateRowPre = () => {
        if (form.quantity === '') {
            alert("Cantidad es requerida")
            return;
        }

        if (form.quantity >= 0) {
            onCreateRow(form)
        } else {
            alert("Cantidad debe ser mayor a cero")
        }

    }

    const onCancelRow = () => {
        setForm(initState)
        onCancel()
    }

    return (
        <tr>
            <td width={"35%"}>
                <AsyncSelect
                    // isDisabled={isEdit}
                    value={product}
                    placeholder={`Busca el Producto`}
                    loadOptions={loadOptions}
                    onChange={(e) => {
                        setProduct(e)
                        e.quantity = '';
                        setForm(e)
                        // getPrescriptionByProductId(parseInt(e.value), e.product_type)

                    }}
                />
            </td>
            <td>{form.measure_unit.description}</td>
            <td align='right'>{formatNumber(form.cost_average)}</td>
            <td><input className="form-control text-right" name='quantity' value={form.quantity} onChange={updateField} placeholder="Unidades" /></td>
            <td align='right'>{formatNumber(form.cost_average * (form.quantity || 0))}</td>
            <td>
                <button className='btn btn-sm btn-success mx-1' onClick={onCreateRowPre}>Guardar</button>
                <button className='btn btn-sm btn-danger' onClick={onCancelRow}>Cancelar</button>
            </td>
        </tr>
    )
}

export default RowAddProduct;