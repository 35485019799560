import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Table } from 'react-bootstrap';
import { getAllBranchOffice } from '../../Redux/branch.officeDuck'
import { getAllBusiness } from '../../Redux/businessDuck'
import { getListDepartment } from '../../Redux/departmentCityDuck'
import ModalBranch from './ModalBranch'
import RowTableBranch from './row.table.branch';
import TemplateMenu from '../../template/menu.template'


const BranchOfficePage = ({ getAllBranchOffice, data, clearSelected, getAllBusiness, fetching_list, fetching_list_business, data_business,
    getListDepartment, fetching_list_department, data_department }) => {

    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([]);
    const [listBusiness, setListBusiness] = useState([]);
    const [listDeparment, setListDepartment] = useState([]);
    const [listCity, setListCity] = useState([]);
    const [formEdit, setFormEdit] = useState({});

    useEffect(() => {
        getAllBranchOffice()
        getAllBusiness()
        getListDepartment()
    }, [])

    useEffect(() => {
        if (fetching_list_department === 2) {
            setListDepartment(data_department)
        }

    }, [fetching_list_department])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }

    }, [fetching_list])

    useEffect(() => {
        if (fetching_list_business === 2) {
            setListBusiness(data_business)
        }

    }, [fetching_list_business])


    const onEdit = (item) => {
        setShowModal(true)
        setFormEdit(item)
    }

    const onHide = () => {
        setShowModal(false);
    }

    const showModalTmp = () => {
        setShowModal(true);
    }


    const onSuccess = () => {
        localStorage.removeItem("list_branch")
        setShowModal(false);
        getAllBranchOffice();
    }

    const filterContent = (txt) => {
        let _list = list

        _list = list.filter(row => row.name.includes(txt) || row.email.includes(txt))

        setList(_list)

    }
    const onCleanFilter = () => {
        setList(data)
    }

    return (
        <TemplateMenu>
            <Row className=" p-2 w-100 mx-0 mt-2">
                <Col lg={6} className="pl-2">
                    <button className='btn btn-success bts-sm' onClick={showModalTmp}>Crear Sucursal</button>
                </Col>
            </Row>

            <Row className="py-2">
                <Col>
                    <Table className="table-sm table-bordered table-hover">
                        <thead>
                            <tr className='thead-dark'>
                                <th>Nombre</th>
                                <th>Direccion</th>
                                <th>No. Celular</th>
                                <th>Departamento</th>
                                <th>Ciudad</th>
                                <th>Estado</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list !== undefined) ? list.map(row => <RowTableBranch key={row.id} item={row} onEdit={onEdit} />) : null}
                        </tbody>


                    </Table>

                    {/* <PaginationTable /> */}
                </Col>

            </Row>

            <ModalBranch showModal={showModal} onHide={() => onHide()} onSuccess={() => onSuccess()} formEdit={formEdit}
                list_business={listBusiness}
                list_department={listDeparment}
            />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { branch_office, business, department_city } = props

    return {
        data: branch_office.data,
        fetching_list: branch_office.fetching_list,
        fetching_list_business: business.fetching_list,
        data_business: business.data,
        fetching_list_department: department_city.fetching_list_department,
        data_department: department_city.data,
    }
}

export default connect(mapsState, { getAllBranchOffice, getAllBusiness, getListDepartment })(BranchOfficePage);