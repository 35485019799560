import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalConfirmBriefcase = ({ show, onHide, total, quantity, onConfirm }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>¿Deseas Crear una Cuenta de Cobro?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Cantidad de Ordenes</Col>
                    <Col className='text-right'>{quantity}</Col>
                </Row>
                <Row>
                    <Col>Total a Cobrar</Col>
                    <Col className='text-right'>{total}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button onClick={onConfirm} className='btn btn-sm btn-success'>Confirmar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmBriefcase;