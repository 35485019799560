import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemCategoryPriceModal = ({ row, onEditRow, selected, onDeleteRow, onDeleteServerRow }) => {
    let { category } = row;


    let percent = (row.type_value === true) ? row.percent : "$" + formatNumber(Math.round((row.percent / 100) * row.price));
    let percent_night = (row.type_value_night === true) ? row.percent_night : "$" + formatNumber(Math.round((row.percent_night / 100) * row.price_night));

    return (
        <tr className={`${selected === true ? 'table-primary' : ''} ${row._id !== undefined ? 'table-secondary' : ''}`}>
            <td>{category.description}</td>
            <td>
                {formatNumber(row.price)}
            </td>
            <td align='right'>
                {percent}
            </td>
            <td>
                {row.type_value_text}
            </td>
            <td align='right'>
                {formatNumber(row.price_night)}
            </td>
            <td align='right'>
                {percent_night}
            </td>
            <td>
                {row.type_value_night_text}
            </td>
            <td>
                <button className='btn btn-sm btn-info' onClick={() => onEditRow(row)}>Editar</button>
                <button className='btn btn-sm btn-danger ml-2' onClick={() => onDeleteRow(row)}>Borrar</button>
            </td>
        </tr>
    )
}
export default ItemCategoryPriceModal;