import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { io } from 'socket.io-client';
import { formatNumber } from '../../utils';

const ItemPurchaseEdit = ({ item, onUpdateNotify }) => {
    let { product } = item
    const [show, setShow] = useState(false)
    const [form, setForm] = useState({ amount: 0, cost: 0, total: 0 })

    const onEditAmount = () => {

        setShow(true)
        setForm({
            ...form,
            "amount": item.amount,
            "total": item.total
        })

    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveForm = () => {
        setShow(false)
        setForm({
            ...form,
            "amount": 0
        })
        item.amount = form.amount;
        item.cost = form.total / form.amount;
        item.total = form.total;
        onUpdateNotify()
    }

    return (
        <tr>
            <td>{product.name}</td>
            <td>{product.name}</td>
            {/* <td align="right">{formatNumber(product.cost)}</td> */}
            <td align="center">{item.request_amount}</td>
            <td>
                {show ?
                    <Row>
                        <Col>
                            <input className='form-control' name="amount" value={form.amount} onChange={updateInput} />
                        </Col>
                    </Row>
                    : <Row>
                        <Col className='d-flex justify-content-center align-items-center'>{item.amount}</Col>
                    </Row>}
            </td>
            <td align="right">{formatNumber(item.cost)}</td>
            <td align="right">
                {show ?
                    <Row>
                        <Col>
                            <input className='form-control' name="total" value={form.total} onChange={updateInput} />
                        </Col>
                    </Row>
                    : <Row>
                        <Col className='d-flex justify-content-center align-items-center'>{formatNumber(item.amount * item.cost)}</Col>
                    </Row>}

            </td>
            <td>{show ?
                <button className='btn btn-sm btn-primary' onClick={onSaveForm}>Guardar</button>
                : <AiFillEdit onClick={onEditAmount} className="cursor-pointer" />}

            </td>
        </tr >
    )
}

export default ItemPurchaseEdit;