import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';

const ItemTableLowDown = ({ row, onEdit, onDelete }) => {

    return (
        <tr>
            <td>{moment(row.registration_date).format("YYYY-MM-DD hh:mm")}</td>
            <td>{row.product}</td>
            <td>{row.reason}</td>
            <td>{row.amount}</td>
            <td>{formatNumber(parseFloat(row.cost).toFixed(0))}</td>
            <td>
                <button className='btn btn-info btn-sm' onClick={() => onEdit(row)}>Editar</button>
                <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(row)}>Borrar</button>
            </td>
        </tr>
    )
}

export default ItemTableLowDown;