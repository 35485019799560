
import Axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    data: [],
    errors: {}
}


const COTIZATION = "COTIZATION"
const COTIZATION_SUCCESS = "COTIZATION_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COTIZATION:
            return { ...state, fetching_list: 1 }
        case COTIZATION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        default:
            return state;
    }
}

export let getListCotization = () => (dispatch, getState) => {
    dispatch({
        type: COTIZATION
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        Axios.get(`${API_URL}/api/cotization?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: COTIZATION_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}