import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import ItemCotization from './item.cotization';
import { getListCotization } from '../../Redux/cotizationDuck';
import { connect } from 'react-redux';


const CotizationPage = ({ getListCotization, fetching_list, data }) => {
    const [list, setList] = useState([])

    useEffect(() => {
        getListCotization()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])
    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col>Listado de Cotizaciones</Col>
            </Row>
            <Row>
                <Col>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Cotización</th>
                                <th>Cliente</th>
                                <th>Tipo</th>
                                <th>Placa</th>
                                <th>Dias Validos</th>
                                <th className='text-center'>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <ItemCotization row={row} key={row.id} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>


                    </table>

                </Col>
            </Row>
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { cotization } = props

    return {
        fetching_list: cotization.fetching_list,
        data: cotization.data,
    }
}

export default connect(mapsState, { getListCotization })(CotizationPage);