import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { MdModeEditOutline } from 'react-icons/md';
import { formatNumber } from '../../utils';

const SubItemTableOrder = ({ item, onEditPercent }) => {
    const [show, setShow] = useState(false)
    const [type, setType] = useState("%");

    let type_value = item.type_value === true ? 'Porcentaje(%)' : 'Precio($)';


    let _percent = item.type_value === false ? parseInt((item.percent / 100) * item.price) : item.percent
    const [percent, setPercent] = useState(_percent)
    const [price, setPrice] = useState(0)

    const showPercent = () => {
        setShow(true)
        let _symbol = (item.type_value === true) ? '%' : '$'
        setType(_symbol);

        let _percent = percent
        if (item.type_value === false) {
            _percent = parseInt((item.percent / 100) * item.price);
        } else {
            _percent = percent;
        }

        setPercent(_percent);
        setPrice(item.price)
    }

    const updateInput = (e) => {
        if (e.target.name === 'percent') {
            setPercent(e.target.value)
        } else {
            setType(e.target.value);
        }

    }

    const onEdit = () => {
        setShow(false)

        let _percent = percent

        item.type_value = type === '%' ? true : false;

        if (item.type_value === false) {
            _percent = ((percent * 100) / item.price)
        }


        item.percent = _percent;
        item.price = price;


        onEditPercent(item)
    }

    return (
        <tr>
            <td>{moment(item.finish_at).format("YYYY-MM-DD")}</td>
            <td>{item.order_id}</td>
            <td>{item.category}</td>
            <td>{item.service}</td>
            <td>
                {show === false ? type_value :
                    <select className='form-control' value={type} onChange={updateInput}>
                        <option value={"$"}>Valor</option>
                        <option value={"%"}>Porcentaje</option>
                    </select>}
            </td>

            <td align='right'>
                {show === false ? <>
                    {item.type_value === false ? formatNumber(percent) : `${percent}`} <MdModeEditOutline className='cursor-pointer' onClick={showPercent} />
                </> :
                    <input name="percent" className='form-control' value={percent} onChange={updateInput} />

                }

            </td>
            <td>{formatNumber(item.tip)}</td>
            <td align='right'>{item.percent_worker === '100' ?
                <>
                    {show === false ? formatNumber(item.price) :
                        <Row>
                            <Col lg={7}>
                                <input className='form-control' value={price} onChange={(e) => setPrice(e.target.value)} />
                            </Col>
                            <Col>
                                <button className='btn btn-info btn-sm' onClick={onEdit}>Editar</button>
                                <button className='btn btn-warning btn-sm mx-1' onClick={() => setShow(false)}>X</button>
                            </Col>
                        </Row>
                    }
                </>

                : <>
                    <Row>
                        <Col className='text-muted'><del>{formatNumber(item.price)}</del></Col>
                    </Row>
                    <Row>
                        <Col className=''>{formatNumber(item.price_real)}</Col>
                    </Row>
                </>}</td>
            <td align='right'>
                {item.percent_worker === '100' ? formatNumber(item.price_to_paid) : <>
                    <Row>
                        <Col className='text-muted'><del>{formatNumber(item.price_to_paid)}</del></Col>
                    </Row>
                    <Row>
                        <Col className=''>{formatNumber(item.price_to_paid_real)}</Col>
                    </Row>
                </>}
            </td>
        </tr>
    )
}

export default SubItemTableOrder;
